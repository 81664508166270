import styled from "styled-components";
import { grayscale, fontFamily } from ".";

// Dependencies
import { Link } from "react-router-dom";

export const SoundDetailsContainer = styled.div`
  color: ${grayscale.white};
  font-family: ${fontFamily.sansSerif};
  h1 {
    font-size: 35px;
    margin-top: 0;
    margin-bottom: 25px;
  }
  h2 {
    font-size: 26px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  h3 {
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  p {
    font-size: 22px;
    font-family: futura-pt, sans-serif;
    line-height: 1.5;
    margin: 20px 0;
  }
  ul,
  li {
    font-size: 22px;
    font-family: futura-pt, sans-serif;
    line-height: 1.5;
  }
  @media (max-width: 1024px) {
    h1 {
      font-size: 26px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    p,
    ul,
    li {
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    padding: 0 10px 60px 10px;
  }
`;
export const SoundContainer = styled.div`
  padding: 50px 50px 150px 50px;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin: 0;
    padding: 85px 10px 120px 10px;
  }
`;

export const SoundDetailsBlock = styled.div`
  margin: 5px 0;
`;

export const StyledLink = styled(Link)`
  ${props =>
    props.color ? `color:${props.color}` : `color:${grayscale.white}`}
  ${props => props.textDecoration == "none" && `text-decoration: none`}
`;

export const Line = styled.hr`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 50px 0;
`;

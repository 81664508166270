import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";
import { SmallText } from "../Utilities";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

// Data
import { soundData } from "../Static/SoundData";

export default function PericardialFrictionRub(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Tricuspid");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Pericardial Friction Rub</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>high-pitched</li>
          <li>Rubbing/Scratching quality</li>
          <li>Rub has three phases: ventricular systole, ventricular diastole & atrial systole </li>
        </ul>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pericardial-rub-graphic.jpg"
            alt="pericardial friction rub"
            className="second"
          />
        </ImgContainer>
        <p>
          The sound of a pericardial rub is a specific examination finding for acute pericarditis.
        </p>
        <p>
          The sounds of S1 and S2 are described as scratchy or to have a rubbing quality. The rub is described as high-pitched, scratchy or grating, with three-components (ventricular systole, ventricular diastole and atrial systole).
        </p>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/thoraxis-tricuspid.jpg"
            alt="Pericardial Friction Rub"
            className="first thoraxis"
            onClick={thoraxis}
          />
          <div className="expand-icon" onClick={thoraxis}>
            <span>Expand</span>
            <FontAwesomeIcon icon={faExpand} className="icon" />
          </div>
        </ImgContainer>
        <p>It is best heard at the left lower sternal border with diaphragm of stethoscope. It is loudest when the person leans forward. There are also appreciable variations in the rub with respirations, being louder during inspiration.</p>
      </Intro>
      <P1>
        <div>
          <h2>Causes</h2>
          <p>
            The sound of a pericardial friction rub is thought to be caused by inflammation of the pericardium, casuing its inner and outer layer to rub together. Most commonly this is caused by a viral infection, however can be cause by autoimmune disorders such as lupus or scleroderma.
          </p>
        </div>
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "pericardial-friction-rub"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Pericardial Friction Rub"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Pericardial+Friction+Rub+(Leaning+Forward).mp3"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  width: max-content;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    cursor: pointer;
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .expand-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    color: white;
    opacity: 0.5;
    .icon {
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  font-family: futura-pt, sans-serif;
  ul {
  }

  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;

import React, { useState } from "react";

// Components
import SearchBar from "../Search/SearchBar";
import DrawerRight from "./DrawerRight";

// Styles
import styled from "styled-components";
import { grayscale } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";

export default function Header(props) {
  const [drawerRight, setDrawerRight] = useState(false);
  function clickAvatar() {
    setDrawerRight(true);
  }
  function closeDrawer() {
    setDrawerRight(false);
  }

  return (
    <HeaderContainer>
      <HeaderInnerContainer>
        <LeftHeader>
          <SearchBar
            changeSearchWord={props.changeSearchWord}
            searchWord={props.searchWord}
          />
        </LeftHeader>
        <RightHeader>
          <StyledFontAwesomeIcon
            icon={faUser}
            size="lg"
            onClick={clickAvatar}
          />
          <DrawerRight drawerRight={drawerRight} closeDrawer={closeDrawer} />
        </RightHeader>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
}
const HeaderContainer = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: None;
  }
`;
const HeaderInnerContainer = styled.div`
  display: grid;
  background: ${grayscale.darkGray};
  align-items: center;
  height: 50px;
  padding: 5px 0;
  position: fixed;
  width: calc(100% - 250px);
  right: 0;
  top: 0;
  grid-template-columns: 50% 50%;
  z-index: 1;
`;
const LeftHeader = styled.div`
  display: grid;
  justify-content: left;
  margin-left: 50px;
`;
const RightHeader = styled.div`
  display: grid;
  justify-content: end;
  margin-right: 20px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${grayscale.white};
  padding: 15px;
  cursor: pointer;
`;

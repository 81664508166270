import React, { Component } from "react";

// Styles
import styled from "styled-components";

export default class ImageAdd extends Component {
  // Start the popover closed
  state = {
    url: "",
    open: false
  };

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener("click", this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closePopover);
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true;
  };

  openPopover = () => {
    if (!this.state.open) {
      this.preventNextClose = true;
      this.setState({
        open: true
      });
    }
  };

  closePopover = () => {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false
      });
    }

    this.preventNextClose = false;
  };

  addImage = () => {
    const { editorState, onChange } = this.props;
    onChange(this.props.modifier(editorState, this.state.url));
  };

  changeUrl = evt => {
    this.setState({ url: evt.target.value });
  };

  render() {
    const popoverClassName = this.state.open
      ? "addImagePopover"
      : "addImageClosedPopover";
    const buttonClassName = this.state.open
      ? "addImagePressedButton"
      : "addImageButton";

    return (
      <ImageAddContainer>
        <button
          className={buttonClassName}
          onMouseUp={this.openPopover}
          type="button"
        >
          +
        </button>
        <div className={popoverClassName} onClick={this.onPopoverClick}>
          <input
            type="text"
            placeholder="Paste the image url …"
            className="addImageInput"
            onChange={this.changeUrl}
            value={this.state.url}
          />
          <button
            className="addImageConfirmButton"
            type="button"
            onClick={this.addImage}
          >
            Add
          </button>
        </div>
      </ImageAddContainer>
    );
  }
}

const ImageAddContainer = styled.div`
  background: #fff;
  display: inline-block;

  .addImagePopover {
    margin-top: 10px;
    background: #fff;
    position: absolute;
    height: 54px;
    width: 300px;
    border-radius: 2px;
    padding: 10px;
    box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
    z-index: 1000;
  }

  .addImageClosedPopover {
    display: none;
  }

  .addImageButton,
  .addImagePressedButton {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0;
    color: #888;
    margin: 0;
    border-radius: 1.5em;
    cursor: pointer;
    height: 1.5em;
    width: 2.5em;
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 0;
  }

  .addImageButton:focus {
    outline: 0; /* reset for :focus */
  }

  .addImageButton:hover {
    background: #f3f3f3;
  }

  .addImageButton:active {
    background: #e6e6e6;
  }

  .addImagePressedButton {
    background: #ededed;
  }

  .addImageBottomGradient {
    width: 100%;
    height: 1em;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    pointer-events: none;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .addImageInput {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 4px;
    width: 78%;
    border-radius: 2px;
    margin-bottom: 1em;
    box-shadow: inset 0px 1px 8px -3px #ababab;
    background: #fefefe;
  }

  .addImageConfirmButton {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0;
    color: #888;
    margin: 0;
    border-radius: 2.1em;
    cursor: pointer;
    height: 2.1em;
    width: 18%;
    font-size: 1em;
    line-height: 2.1em;
    margin: 0;
    margin-left: 4%;
  }

  .addImageConfirmButton:focus {
    outline: 0; /* reset for :focus */
  }

  .addImageConfirmButton:hover {
    background: #f3f3f3;
  }

  .addImageConfirmButton:active {
    background: #e6e6e6;
  }
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
} from "../../Utilities";
import Spinner from "../../Utilities/Spinner";
import MediaPlayerStatic from "../../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../../Header/HeaderMobile";
import CaseCard from "../CaseCard";
import { SmallText } from "../../Utilities";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

// Data
import { soundData } from "../../Static/SoundData";

export default function TransmittedVoiceSounds(props) {
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Transmitted Voice Sounds</h1>
      </TitleBlock>
      <Intro>
        <p>
          Normally, tracheal and bronchial sounds may be heard over the trachea
          and mainstem bronchi; vesicular breath sounds predominate throughout
          most of the lungs. When lung tissue loses airflow, there is increased
          transmission of high-pitched sounds. If the tracheobronchial tree is
          open, bronchial breath sounds may replace the normal vesicular sounds
          over airless areas of the lung. This change occurs in lobar pneumonia
          when the alveoli get filled with fluid and cellular debris—a process
          called consolidation. Other causes include pulmonary edema or, rarely,
          hemorrhage. Bronchial breath sounds usually correlate with an increase
          in tactile fremitus and transmitted voice sounds.
        </p>
        <p>
          If you hear abnormally located bronchovesicular or bronchial breath
          sounds, assess transmitted voice sounds using three techniques below.
          With the diaphragm of your stethoscope, listen in symmetric areas over
          the chest wall for abnormal vocal resonances suspicious for pneumonia
          or pleural effusion.
        </p>
        <p>
          Increased transmission of voice sounds suggests that embedded airways
          are blocked by inflammation or secretions.
        </p>
        <ul>
          <li>
            <Link to="/sound/egophony">Egophony</Link>
          </li>
          <li>
            <Link to="/sound/bronchophony">Bronchophony</Link>
          </li>
        </ul>
      </Intro>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Transmitted Voice Sounds"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
  li {
    a {
      color: lightblue;
    }
  }
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  width: max-content;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    cursor: pointer;
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .expand-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    color: white;
    opacity: 0.5;
    .icon {
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  font-family: futura-pt, sans-serif;
  ul {
  }

  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;

import React, { Fragment, Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import { mixpanelToken } from "./settings";

// DEPENDENCIES
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "./Context/Context";

// Components
import Homepage from "./Homepage/Homepage";
import NonAuthHomepageAlt from "./Homepage/NonAuthHomepageAlt";
import Login from "./Authentication/Login";
import Header from "./Header/Header";
import Search from "./Search/Search";
import Spinner from "./Utilities/Spinner";
import TabNavigator from "./TabNavigator/TabNavigator";
import Sounds from "./Sounds/Sounds";
import Notifications from "./Notifications/Notifications";
import CourseHome from "./Course/CourseHome";
import QuizSwitch from "./Quiz/QuizSwitch";
import AuscultationPoints from "./AuscultationPoints/AuscultationPoints";
import CaseDetails from "./ClinicalCorrelation/CaseDetails";

// Styles
import styled from "styled-components";
import { fontFamily } from "./Utilities";

// Dependencies
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      profile {
        id
        subscribedApps {
          id
          title
          description
          url
          logo
        }
      }
    }
  }
`;

mixpanel.init(mixpanelToken);

export default class App extends Component {
  state = {
    searchWord: "",
    isCaseActive: false,
    isAuscultationPointsActive: false,
  };

  changeSearchWord = (searchValue) => {
    this.setState({ searchWord: searchValue });
  };

  handleCaseActive = (caseActive) => {
    this.setState({ isCaseActive: caseActive });
  };

  handleAuscultationPoints = (auscultationPointsState) => {
    this.setState({ isAuscultationPointsActive: auscultationPointsState });
  };

  render() {
    return (
      <MixpanelContext.Provider value={mixpanel}>
        <Query query={GET_USER}>
          {({ loading, data }) => {
            if (loading) return <Spinner />;
            const user = data.user;
            if (!user) {
              return (
                <Router>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={(props) => <NonAuthHomepageAlt {...props} />}
                    />
                    <Route
                      exact
                      path="/login"
                      render={(props) => <Login {...props} user={user} />}
                    />
                    <Route component={NonAuthHomepageAlt} />
                  </Switch>
                </Router>
              );
            }

            let userHasApp;
            if (user) {
              document.body.style.backgroundColor = "#1d1d1d";
              userHasApp = user.profile.subscribedApps.find((app) => {
                return app.title == "Auscultate";
              });
              mixpanel.identify(user.id);
            }

            if (userHasApp) {
              return (
                <Router>
                  {!this.state.isCaseActive && (
                    <Header changeSearchWord={this.changeSearchWord} />
                  )}

                  <AppContainer>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={(props) => (
                          <Homepage
                            {...props}
                            user={user}
                            mixpanel={mixpanel}
                          />
                        )}
                      />
                      <Route
                        path="/how-to-auscultate/:lessonId"
                        render={(props) =>
                          user.isSuperuser ? (
                            <CourseHome {...props} user={user} />
                          ) : (
                            <Redirect to="/" />
                          )
                        }
                      />
                      <Route
                        exact
                        path="/search"
                        render={(props) => (
                          <Search
                            {...props}
                            user={user}
                            searchWord={this.state.searchWord}
                            changeSearchWord={this.changeSearchWord}
                          />
                        )}
                      />
                      <Route
                        path="/quiz/:quizId"
                        render={(props) => (
                          <QuizSwitch {...props} user={user} />
                        )}
                      />
                      <Route
                        exact
                        path="/login"
                        render={(props) => <Redirect to="/" />}
                      />
                      <Route
                        path="/sound/:soundId"
                        render={(props) => (
                          <Sounds
                            {...props}
                            user={user}
                            changeSearchWord={this.changeSearchWord}
                          />
                        )}
                      />
                      <Route
                        path="/auscultation-points/:location?"
                        render={(props) => (
                          <AuscultationPoints
                            {...props}
                            user={user}
                            changeSearchWord={this.changeSearchWord}
                            handleAuscultationPoints={
                              this.handleAuscultationPoints
                            }
                          />
                        )}
                      />
                      <Route
                        path="/notifications"
                        render={(props) => <Notifications {...props} />}
                      />
                      <Route
                        exact
                        path="/case/:caseId"
                        render={(props) => (
                          <CaseDetails
                            {...props}
                            user={user}
                            handleCaseActive={this.handleCaseActive}
                          />
                        )}
                      />
                    </Switch>
                  </AppContainer>
                  <TabNavigator />
                </Router>
              );
            } else {
              window.location = "https://medzcool.com/auscultate";
            }
          }}
        </Query>
      </MixpanelContext.Provider>
    );
  }
}
const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  background: white;
  color: black;
  border-radius: 4px;
  &.login {
    width: 150px;
  }
  &.sign-up {
    background: none;
  }
  @media (max-width: 768px) {
    font-size: 11px;
    &.login {
      width: auto;
      margin-left: 10px;
      padding: 0 15px;
    }
    &.sign-up {
      font-weight: 700;
    }
  }
`;

const AppContainer = styled.div``;

const Container = styled.div`
  margin: auto;
  padding: 15px;
  max-width: 800px;
  font-family: ${fontFamily.sansSerif};
  &.top {
    margin-top: 75px;
  }
  &.bottom {
    margin-bottom: 65px;
  }
  &.first {
    padding-top: 0;
  }
  .updates {
    margin-top: 40px;
  }
  .button-container-mobile {
    display: none;
  }
  @media (max-width: 768px) {
    &.top {
      margin-top: 50px;
    }
    .button-container-mobile {
      display: block;
      margin: 35px 0 0 0;
    }
  }
`;

const SubTitle = styled.h3`
  font-size: 20px;
  font-family: ${fontFamily.sansSerif};
  font-weight: 700;
  margin-bottom: 5px;
  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

const MediumText = styled.div`
  font-size: 16px;
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;
const SmallText = styled.div`
  font-size: 12px;
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  @media (max-width: 425px) {
    font-size: 10px;
  }
`;
const Screenshots = styled.div`
  margin: auto;
  max-width: 1000px;
  .screenshots {
    margin: auto;
    margin: 25px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    img {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .screenshots {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }
  }
`;
const Line = styled.div`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 40px 0 5px 0;
`;

import React, { Component, Fragment } from "react";
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import Editor, { composeDecorators } from "draft-js-plugins-editor";
import { Button, fontFamily } from "../Utilities";

// Dependencies
import { url } from "../settings";

// Plugins
import createToolbarPlugin, { Separator } from "draft-js-static-toolbar-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton
} from "draft-js-buttons";
import createImagePlugin from "draft-js-image-plugin";
import createAlignmentPlugin from "draft-js-alignment-plugin";

// Styles
import styled from "styled-components";
import "draft-js-static-toolbar-plugin/lib/plugin.css";

export default class MedzcoolEditorReadOnly extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const staticToolbarPlugin = createToolbarPlugin();
    const { Toolbar } = staticToolbarPlugin;

    const alignmentPlugin = createAlignmentPlugin();

    const decorator = composeDecorators(
      alignmentPlugin.decorator
      // resizeablePlugin.decorator
    );

    const imagePlugin = createImagePlugin({ decorator });

    this.plugins = [staticToolbarPlugin, imagePlugin, alignmentPlugin];

    this.components = {
      Toolbar
    };

    this.state = {
      largeFileSize: false,
      hasFocus: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.content.body) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(this.props.content.body))
        )
      });
    } else {
      this.setState({ editorState: EditorState.createEmpty() });
    }
  }

  componentDidUpdate(prevProps) {
    if (this._isMounted) {
      if (this.props.content.body !== prevProps.content.body) {
        this.setState({
          editorState: EditorState.createWithContent(
            convertFromRaw(JSON.parse(this.props.content.body))
          )
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = editorState => {
    this.setState({ editorState });
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    const { Toolbar } = this.components;
    const { content, editing } = this.props;
    if (editing) {
      return null;
    }

    if (!this.state.editorState) {
      return <h3 className="loading">Loading...</h3>;
    } else {
      return (
        <Fragment>
          <EditorContainer>
            <StyledLabel>{this.props.label}</StyledLabel>
            <Editor
              readOnly={true}
              onChange={this.onChange}
              editorState={this.state.editorState}
              plugins={this.plugins}
              ref={element => {
                this.editor = element;
              }}
            />
          </EditorContainer>
        </Fragment>
      );
    }
  }
}
const EditorContainer = styled.div`
  position: relative;
  font-family: adobe-garamond-pro, serif;
  .DraftEditor-root {
    color: white;
    margin: 5px 0;
    font-size: 22px;
    line-height: 30px;
    figure {
      margin: 10px 0;
      img {
        max-width: 100%;
      }
    }
    .public-DraftStyleDefault-block {
      margin-top: 29px;
    }

    @media (max-width: 576px) {
      font-size: 15px;
      line-height: 25px;
    }
  }
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
`;

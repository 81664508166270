import React, { Fragment, useContext } from "react";
import { MixpanelContext } from "../Context/Context";

// Dependencies
import { Route } from "react-router-dom";

// Components
import Sidebar from "../Sidebar/Sidebar";
import StethosHome from "./StethosHome";
import QuizDescribe from "./QuizDescribe";
import Button from "../Utilities/Button";
import QuizLocation from "./QuizLocation";

// Styles
import styled from "styled-components";
import { fontFamily } from "../Utilities";

// Font Awesome

export default function QuizSwitch(props) {
  const quizId = props.match.params.quizId;
  const { user } = props;
  const mixpanel = useContext(MixpanelContext);

  if (quizId == "home") {
    return (
      <Fragment>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <QuizContainer>
          <div className="main">
            <h1>Quizes</h1>
            <QuizBlockContainer>
              <QuizBlock
                className="quiz-what"
                onClick={() => {
                  mixpanel.track("Auscultate", {
                    Location: "Quizzes",
                    Label: "What Sound Do You Hear?",
                  });
                  window.location = "/quiz/1";
                }}>
                <div className="label">
                  Multiple Choice &middot; 13 Sounds &middot; Easy
                </div>
                <h2 className="title">What Sound Do You Hear?</h2>
                <p className="quiz-description">
                  An introductory quiz testing you ability to identify heart
                  sounds. Presented with an unknown sound, it is your job to
                  select what you think is the best answer.
                </p>
                <Button
                  width="120px"
                  padding="8px"
                  buttonLabel="Start Quiz"
                  active
                />
              </QuizBlock>

              <QuizBlock
                className="quiz-describe"
                onClick={() => {
                  mixpanel.track("Auscultate", {
                    Location: "Quizzes",
                    Label: "Describe that sound",
                  });
                  window.location = "/quiz/2";
                }}>
                <div className="label">
                  Multiple Choice &middot; 13 Sounds &middot; Intermediate
                </div>
                <h2 className="title">Describe that sound</h2>
                <p className="quiz-description">
                  A quiz testing your ability to correctly identify and describe
                  the characteristics of the sound you are hearing (e.g.
                  Intensity, Quality, Pitch, etc.)
                </p>
                <Button
                  width="120px"
                  padding="8px"
                  buttonLabel="Start Quiz"
                  active
                />
              </QuizBlock>
              <QuizBlock
                className="quiz-location"
                onClick={() => {
                  mixpanel.track("Auscultate", {
                    Location: "Quizzes",
                    Label: "Where is this sound best heard?",
                  });
                  window.location = "/quiz/3";
                }}>
                <div className="label">
                  Multiple Choice &middot; 7 Sounds &middot; Intermediate
                </div>
                <h2 className="title">Where is this sound best heard?</h2>
                <p className="quiz-description">
                  Test your ability to identify the heart sound and determine
                  where you might hear the sound the best or where the point of
                  maximal intensity might be.
                </p>
                <Button
                  width="120px"
                  padding="8px"
                  buttonLabel="Start Quiz"
                  active
                />
              </QuizBlock>
            </QuizBlockContainer>
          </div>
        </QuizContainer>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Route
        path="/quiz/1"
        render={(props) => <StethosHome {...props} user={user} />}
      />
      <Route
        path="/quiz/2"
        render={(props) => <QuizDescribe {...props} user={user} />}
      />
      <Route
        path="/quiz/3"
        render={(props) => <QuizLocation {...props} user={user} />}
      />
    </Fragment>
  );
}

const SidebarContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const QuizContainer = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  grid-gap: 25px;
  max-width: 1100px;
  margin-bottom: 125px;
  .main {
    grid-column-end: 3;
    padding: 50px 25px;
    color: white;
    margin-top: 25px;
    font-family: ${fontFamily.sansSerif};
  }
  @media (max-width: 768px) {
    display: block;
    .main {
      padding: 0 15px;
    }
  }
`;
const QuizBlockContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
`;
const QuizBlock = styled.div`
  /* box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25); */
  /* border-radius: 5px; */
  color: white
  font-family: ${fontFamily.sansSerif};
  .label {
    font-size: 15px;
    color: #ed0e5e;
    font-weight: 300;
  }
  .title {
    font-size: 22px;
    margin: 0;
  }
  .quiz-description {
    margin-top: 0;
  }
  /* &.quiz-what {
    background: url("https://s3-us-west-2.amazonaws.com/medzcool/auscultate/quiz-card-1.jpg");
    background-size: cover;
  }
  &.quiz-describe {
    background: url("https://s3-us-west-2.amazonaws.com/medzcool/auscultate/quiz-card-2.jpg");
    background-size: cover;
  }
  &.quiz-location {
    background: url("https://medzcool.s3-us-west-2.amazonaws.com/auscultate/quiz-card-location.jpg");
    background-size: cover;
  } */
`;

import React from "react";

// Components
import SoundCard from "./SoundCard";

// Styles
import styled from "styled-components";
import { Title2 } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export default function Disastolic(props) {
    function handleBack() {
      props.history.goBack();
    }
  return (
    <SoundIndexContainer>
    <FontAwesomeIcon
      icon={faChevronLeft}
      className="back-icon"
      onClick={handleBack}
    />
      <div className="soundcard-container">
        <Title2>Diastolic Murmurs</Title2>
        <SoundCard
          key="aortic-regurgitation"
          url="aortic-regurgitation"
          soundTitle="Aortic Regurgitation"
        />
        <SoundCard
          key="mitral-stenosis"
          url="mitral-stenosis"
          soundTitle="Mitral Stenosis"
        />
      </div>
    </SoundIndexContainer>
  );
}
const SoundIndexContainer = styled.div`
  padding-bottom: 75px;
  .back-icon {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 15px;
    color: white;
    display: none;
  }
  .soundcard-container {
    margin-top: 100px;
  }
  ${Title2} {
    color: white;
    padding: 15px 0;
    font-weight: 700;
    margin: 25px;
  }
  @media (max-width: 768px) {
    .soundcard-container {
      margin-top: 0;
    }
    .back-icon {
      display: block;
    }
    ${Title2} {
      text-align: center;
    }
  }
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";

// Data
import { soundData } from "../Static/SoundData";

export default function Holosystolic(props) {
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Split S2</h1>
      </TitleBlock>
      <Intro>
        <p>
          The S2 heart sound is caused by the simultaneous closure of the aortic
          and pulmonic valves. When these valves close at different times,
          you'll hear a split s2.
        </p>
        <p>
          The split S2 can be exaggerated by deep inspiration, which causes an
          increased venous return to the right side of the heart leading to the
          delayed closure of the pulmonic valve. Keep in mind that there are
          different variations to the split S2 heart sound.
        </p>
      </Intro>
      <P1>
        <h2>Transient (Physiologic) S2 Splitting</h2>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/transient-S2-graphic.png"
            alt="split s2"
            className="first"
          />
        </ImgContainer>
        <p>
          In physiologic splitting, the split S2 can be a normal finding in the
          appropriate clinical situation. Normally, there is a slight delay in
          the closure of the aortic and pulmonic valves which results in the
          splitting of S2.
        </p>
        <p>
          This split s2 is exaggerated during inspiration because of two
          reasons. The first reason is that during inspiration, there is an
          expansion of the intrathoracic cavity resulting in a decrease in
          intrathoracic pressure. This change in pressure facilitates increased
          venous return to the right side of the heart resulting in increased
          right ventricular volume when compared to the left ventricle. Because
          of this pressure gradient and increased volume, it takes the right
          ventricle longer to pump blood out during systole, resulting in the
          pulmonic valve closing later than the aortic valve. The second reason
          is that, also during inspiration, the capacity of pulmonary
          circulation increases. This also contributes to delayed closure of the
          pulmonic valve.
        </p>
        <p>
          This difference in valve closure between aortic and pulmonic valves
          results in the split S2 you hear during inspiration and is a normal
          finding.
        </p>
        <p>
          Because changes in intrathoracic pressure vary depending on cycles of
          inspiration and expiration, you can typically expect the duration of
          time between the split S2 to vary as well. This variations in
          splitting is a key point to remember with physiologic splitting and
          identifies this from other potentially abnormal forms of S2 splitting
        </p>
      </P1>
      <P1>
        <h2>Wide S2 Splitting</h2>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/wide-S2-graphic.png"
            alt="split s2"
            className="first"
          />
        </ImgContainer>
        <p>
          Wide splitting of the S2 heart sound refers to an increase in the
          normally occurring splitting that persists throughout the respiratory
          cycle. Remember that during inspiration, we would normally expect the
          pulmonary valve to close later than the aortic valve, resulting in
          physiology or normal splitting of S2. In wide splitting, we notice an
          exaggerated splitting of S2 that persists throughout respirations and
          even further exaggerates with inspiration. In other words, we hear a
          wider split of S2 than we would expect to hear.
        </p>
        <p>
          As illustrated here, compared to normal splitting, where S2 narrows or
          even becomes single during expiration, with wide splitting, this
          difference in A2 and P2 continues regardless of where you are in the
          respiratory cycle.
        </p>
        <p>
          With inspiration, you have an exaggerated split, and even with
          expiration, you still have this S2 split. You will also notice that
          this split is exaggerated even further than would be expected in
          normal splitting.
        </p>
        <p>
          This pattern can occur in people with pulmonic stenosis or in people
          with right ventricular conduction delay in pathologies such as right
          bundle branch blocks.
        </p>
      </P1>
      <P1>
        <h2>Fixed S2 Splitting</h2>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/fixed-S2-graphic.png"
            alt="split s2"
            className="first"
          />
        </ImgContainer>
        <p>
          In fixed splitting, there are no variations of the A2-P2 split with
          respirations. Normally when someone inspires, intrathoracic pressure
          causes an exaggerated split S2 where the pulmonic valve closes later
          than the aortic.
        </p>
        <p>
          In fixed splitting, there is no change in the duration of splitting
          with respirations. This means that when the person is in the
          inspiratory phase of their respiratory cycle, the duration of time
          between A2 and P2 is the same as when they are in their expiratory
          phase. If you were to march the duration between A2 and P2
          throughout a person's respiratory cycle, they would also be the same,
          as opposed to the variations seen with normal S2 splitting.
        </p>
        <p>
          This pattern of splitting can be seen in patients with atrial septal
          defects and sometimes in right ventricular failure.
        </p>
      </P1>
      <P1>
        <h2>Paradoxical S2 Splitting</h2>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/paradoxical-S2-graphic.png"
            alt="split s2"
            className="first"
          />
        </ImgContainer>
        <p>
          In paradoxical splitting, the split of S2 into its A2 and P2 is
          exaggerated during expiration and then becomes narrow or even a single
          S2 during inspiration. This is reversed or paradoxical to what we
          would normally expect where the split S2 is exaggerated during
          inspiration and narrowed or closed during expiration.
        </p>
        <p>
          With paradoxical splitting, the closure of the aortic valve is
          abnormally delayed to the point that A2 closes after P2 during
          expiration.
        </p>
        <p>
          This occurs in pathologies where the systolic duration of the left
          ventricle is longer than the right ventricle leading to this delayed
          aortic valve closure. The most common cause for this is a left
          ventricular conduction delay occurring in left bundle branch blocks
          but is also sometimes seen in cases of aortic stenosis.
        </p>
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }

  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(sound => sound.id == "split-s2");
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;
  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Split S2"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s2.mp3"
        visual="https://vimeo.com/336285323/b04bcc8f85"
        video="https://vimeo.com/336285282/d41346c8b0"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .first {
    max-width: 600px;
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

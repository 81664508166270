import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";

// Data
import { soundData } from "../Static/SoundData";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

export default function ASD(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Pulmonic");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Atrial Septal Defect</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>systolic murmur</li>
          <li>fixed split S2</li>
          <li>in some cases, a low pitched diastolic murmur can be heard</li>
        </ul>
        <p>
          Atrial septal defects are caused by abnormalities during embryogenesis
          that lead to persistent blood flow between the left and right atrium.
          Typically, septation of the atria begins as early as the fifth week of
          gestation, and the foramen ovale remains open, providing a passage to
          shunt blood from right to left atrium. At birth, changes in
          intrathoracic pressure and systemic vascular resistance lead to the
          closure of this shunt. In some cases, there remains a persistent shunt
          which we call an atrial septal defect (ASD), with a prevalence of
          approximately 1 to 2 per 1000 live births.
        </p>
        <ImgContainer>
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-graphic.png"
            alt="Atrial Septal Defect"
            className="first"
          />
        </ImgContainer>
        <p>
          In one study, an ejection systolic murmur and/or fixed split of S2 was
          present in greater than 90% of patients. This can be best heard at the
          left second intercostal space.
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/location-left-second-ics.jpg"
              alt="atrial septal defect"
              className="first thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
        <p>
          A large left-to-right atrial shunt may produce a rumbling low-pitched
          diastolic murmur (due to increased tricuspid flow) at the lower
          sternal borders
        </p>
        <p>
          The systolic mumur is the result of increased blood flow across the
          pulmonic valve. In contrast to the normal variation in S2 splitting
          during the respiratory cycle, the split S2 in patients with ASDs are
          wide and fixed. This is due to the ASD equalizing the respiratory
          effect on both right and left ventricular output. As a result, this
          leads to prolonged emptying of the right ventricle which delays
          pulmonic closure (P2).
        </p>
        <ImgContainer>
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-flow.png"
            alt="atrial septal defect"
            className="full"
          />
        </ImgContainer>
        <p>
          Other murmurs can be heard, depending on the specific anatomical
          abnormality, but are less common. These include:
        </p>
        <ul>
          <li>holosystolic murmur of mitral regurgitation</li>
          <li>low-pitched diastolic murmur of pulmonic regurgitation</li>
        </ul>
      </Intro>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "atrial-septal-defect"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Atrial Septal Defect"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/ASD-short.mp3"
        video="https://vimeo.com/340968715/9d14dfec00"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .full {
    width: 100%;
  }
  .first {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis-container {
    position: relative;
    width: max-content;
    margin: auto;
    .thoraxis {
      cursor: pointer;
      box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
    .expand-icon {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 15px;
      color: white;
      opacity: 0.5;
      .icon {
        vertical-align: middle;
      }
      span {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .thoraxis-container {
      width: 100%;
    }
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

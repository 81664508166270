import React, { Fragment } from "react";

// Dependencies
import { Link } from "react-router-dom";

// Styles
import styled from "styled-components";
import { fontFamily } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHome } from "@fortawesome/pro-regular-svg-icons";

export default function CourseHeader(props) {
  return (
    <HeaderContainer>
      <div className="title">How To Auscultate</div>
      <div className="navigation">
        <StyledLink to="/">
          <FontAwesomeIcon icon={faHome} />
        </StyledLink>
        <FontAwesomeIcon icon={faBars} />
      </div>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  box-sizing: border-box;
  font-family: ${fontFamily.sansSerif};
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  background: black;
  z-index: 2;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 15px;
  .navigation {
    display: grid;
    grid-column-end: none;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    margin-right: 30px;
  }
`;
const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

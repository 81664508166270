import React, { Fragment, useState, useEffect } from "react";
import { url } from "../settings.js";
import imgMobile from "../Static/auscultate-jumbotron-mobile.jpg";
import imgDesktop from "../Static/auscultate-jumbotron-desktop.jpg";

// Dependencies
import ReactGA from "react-ga";
import axios from "axios";

// Components
import MenuDrawer from "../MenuDrawer/MenuDrawer";

// Styles
import styled from "styled-components";
import Footer from "../Footer/Footer";
import { fontFamily, Title1 } from "../Utilities";
import { useSpring, animated } from "react-spring";
import { LargeText } from "../Typography";

import { toCurrency } from "../Utilities";

// Font Awesome
import { faLungs, faHeart } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMusic,
  faChartNetwork,
  faBalanceScaleRight,
  faBalanceScale,
  faUserGraduate,
  faChalkboardTeacher,
  faUserMd,
} from "@fortawesome/pro-regular-svg-icons";

export default function NonAuthHomepage(props) {
  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, []);

  const [isJumbotronLoaded, setIsJumbotronLoaded] = useState(false);
  const [isTitleLoaded, setIsTitleLoaded] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      setIsJumbotronLoaded(true);
    }, 600);
    setTimeout(function () {
      setIsTitleLoaded(true);
    }, 300);
  }, [isJumbotronLoaded]);
  const fadeAnimation = useSpring({
    opacity: isJumbotronLoaded ? `1` : `0`,
  });
  const fadeAnimationTitle = useSpring({
    opacity: isTitleLoaded ? `1` : `0`,
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }
  const menuAnimation = useSpring({
    transform: isMenuOpen ? `translate3d(0, 0, 0)` : `translate3d(100%, 0, 0)`,
  });
  const menuOpacity = useSpring({
    opacity: isMenuOpen ? 0.5 : 0,
    display: isMenuOpen ? `block` : `none`,
  });

  const [app, setApp] = useState();
  useEffect(() => {
    axios.get(`${url}/auscultate/fetch-app/`).then(function (response) {
      setApp(response.data);
    });
  }, []);

  if (!app) {
    return null;
  }

  return (
    <Fragment>
      <MenuDrawer
        style={menuAnimation}
        toggleMenu={toggleMenu}
        opacity={menuOpacity}
      />
      <JumbotronContainer>
        <div className="header">
          <StyledButton className="desktop pricing">
            <a href="#pricing">Pricing</a>
          </StyledButton>

          <StyledButton
            className="desktop sign-up"
            onClick={() =>
              (window.location = "https://medzcool.com/auscultate/checkout")
            }
          >
            Sign-Up
          </StyledButton>
          <StyledButton
            className="desktop login"
            onClick={() => (window.location = "/login")}
          >
            Log In
          </StyledButton>
          <StyledButton
            className="mobile menu-bars"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            isMenuOpen={isMenuOpen}
          >
            <FontAwesomeIcon icon={faBars} size="2x" />
          </StyledButton>
        </div>
        <AuscultateTitle className="title-container" style={fadeAnimationTitle}>
          <div className="title-block">
            <Title1>
              <span className="desktop">Medical Sound Library</span>
              <span className="mobile">Auscultate</span>
            </Title1>
            <div className="subtitle">
              As a student or medical professional, it is unlikely you will ever
              come across all “commonly” auscultated sounds, let alone, rare
              pathology, during your lifetime.
            </div>
            <div className="subtitle">
              Auscultate, brings these sounds to you.{" "}
              <strong>On-demand.</strong>
            </div>
            <div className="button-container">
              <StyledButton
                onClick={() =>
                  (window.location = "https://medzcool.com/auscultate/checkout")
                }
              >
                Get Auscultate
              </StyledButton>
            </div>
          </div>
        </AuscultateTitle>
      </JumbotronContainer>

      <HomepageNonAuthContainer style={fadeAnimation}>
        <Fragment>
          <Container className="first">
            <LandingBlock className="grid-2">
              <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/screenshot-5.jpg" />
              <div>
                <SubTitle>What is Auscultate?</SubTitle>
                <MediumText>
                  Auscultate is a medical sound library and web application to
                  help you learn the physical exam skill of auscultation. You'll
                  learn to identify and differentiate between various heart
                  sounds and ultimately apply this to your clinical practice
                  through interactive images, videos, quizes and other
                  interactive content.
                </MediumText>
              </div>
            </LandingBlock>
          </Container>

          <div className="below-fold">
            <Container>
              <LandingBlock className="sound-library">
                <div>
                  <FontAwesomeIcon icon={faMusic} size="8x" />
                  <SubTitle>The Sound Library</SubTitle>
                  <LargeText>
                    Organized by category based on pathology, timing of sounds,
                    normal sounds and by organ system. This grouping is the
                    foundation you'll need to starting learning and
                    differentiating normal versus abnormal sounds.
                  </LargeText>
                </div>
              </LandingBlock>
            </Container>

            <Container>
              <LandingBlock className="columns-2">
                <div>
                  <div className="sound-category">
                    <FontAwesomeIcon icon={faHeart} size="4x" />
                    <SubTitle>Heart Sounds</SubTitle>
                  </div>
                  <LargeText>
                    The collection of heart sounds includes normal sounds and a
                    variety of abnormal heart sounds organized by location,
                    pathology and timing of the heart sounds by their timing in
                    systole/diastole. This also includes <i>exclusive</i> sounds
                    not found on our Youtube channel.
                  </LargeText>
                  <ul>
                    <li>Normal Heart Sounds</li>
                    <li>Aortic Regurgitation</li>
                    <li>Mitral Stenosis</li>
                    <li>Mitral Valve Prolapse</li>
                    <li>
                      Pericardial Friction Rub{" "}
                      <span className="exclusive">(exclusive)</span>
                    </li>
                    <li>
                      Patent Ductus Arteriosis{" "}
                      <span className="exclusive">(exclusive)</span>
                    </li>
                    <li>Atrial Septal Defect</li>
                    <li>
                      Hypertrophic Cardiomyopathy{" "}
                      <span className="exclusive">(exclusive)</span>
                    </li>
                    <li>
                      Pulmonic Stenosis{" "}
                      <span className="exclusive">(exclusive)</span>
                    </li>
                    <li>And many more ...</li>
                  </ul>
                </div>
                <div>
                  <div className="sound-category">
                    <FontAwesomeIcon icon={faLungs} size="4x" />
                    <SubTitle>Lung Sounds</SubTitle>
                  </div>
                  <LargeText>
                    The lung sound collection provides an overview and
                    comparison of normal lung sounds, adventitious lung sounds
                    and transmitted lung sounds that can be heard in various
                    pathologies. This also includes <i>exclusive</i> sounds not
                    found on our Youtube channel.
                  </LargeText>
                  <ul>
                    <li>Vesicular Breath Sounds</li>
                    <li>Bronchial Breath Sounds</li>
                    <li>
                      Tracheal Breath Sounds{" "}
                      <span className="exclusive">(exclusive)</span>
                    </li>
                    <li>Bronchovesicular Breath Sounds</li>
                    <li>Stridor</li>
                    <li>Fine Crakles (Rales)</li>
                    <li>Coarse Rales</li>
                    <li>
                      Pleural Rub <span className="exclusive">(exclusive)</span>
                    </li>
                    <li>
                      Egophony <span className="exclusive">(exclusive)</span>
                    </li>
                    <li>
                      Bronchophony{" "}
                      <span className="exclusive">(exclusive)</span>
                    </li>
                    <li>And many more ...</li>
                  </ul>
                </div>
              </LandingBlock>
            </Container>

            <Container>
              <LandingBlock className="second feature below-fold-block">
                <div>
                  <FontAwesomeIcon icon={faChartNetwork} size="4x" />
                  <SubTitle>Unique Sound Variations</SubTitle>
                  <MediumText>
                    A sound group might have different variations of a sound.
                    Sounds at the apex may differ from sounds at the base. A
                    sound may vary depending on severity of the pathology.
                    Whatever the variation is, you can toggle between them to
                    listen to each variation.
                  </MediumText>
                </div>
                <img src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/toggle-variations.png" />
              </LandingBlock>
            </Container>

            <Container>
              <LandingBlock className="second feature below-fold-block">
                <div>
                  <FontAwesomeIcon icon={faBalanceScaleRight} size="4x" />
                  <SubTitle>Side-by-Side Sound  Comparison</SubTitle>
                  <MediumText>
                    Some heart sounds sound similar, and differentiating between
                    the two can be difficult. On Auscultate, you can compare
                    sounds side by side by clicking the{" "}
                    <FontAwesomeIcon icon={faBalanceScale} /> icon to reveal a
                    list of comparable sounds.
                  </MediumText>
                </div>
                <img src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/comparison-feature.png" />
              </LandingBlock>
            </Container>

            <Container>
              <LandingBlock className="second below-fold-block">
                <div>
                  <SubTitle>
                    <div className="above-subtitle-text">
                      BEYOND THE TEXTBOOK
                    </div>
                    <span>Interactive Content</span>
                  </SubTitle>
                  <MediumText>
                    We learn the fastest and retain information longer when we
                    use multiple formats/mediums for learning. With Auscultate,
                    you interact with the content through sound, images, videos
                    and tacticle feedback with the click of a mouse.
                  </MediumText>
                </div>
                <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/interactive-content.jpg" />
              </LandingBlock>
            </Container>

            <Container>
              <LandingBlock className="third below-fold-block">
                <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/feature-quiz.png" />
                <div>
                  <SubTitle>
                    <div className="above-subtitle-text">QUIZES</div>
                    <span>Train Your Ears & Test Yourself</span>
                  </SubTitle>
                  <MediumText>
                    Reinforce & test your hearing comprehension with quizes. On
                    Auscultate, sounds are radomized and presented to you in
                    multiple choice format, with feedback after each answer so
                    that you can learn from your mistakes.
                  </MediumText>
                </div>
              </LandingBlock>
            </Container>

            <Container>
              <LandingBlock className="second below-fold-block">
                <div>
                  <SubTitle>
                    <div className="above-subtitle-text">
                      CLINICAL CORRELATIONS
                    </div>
                    <span>Beyond The Sound & Into The Clinic</span>
                  </SubTitle>
                  <MediumText>
                    Learning the physical exam skill of auscultation is only
                    useful if you can associate this to clinical presentations.
                    On Auscultate, we're using CodeHealth's clinical case
                    reports to help you associate what you learn to real life
                    medical cases.
                  </MediumText>
                </div>
                <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/clinical-correlation.jpg" />
              </LandingBlock>
            </Container>

            <Container>
              <div className="who">
                <SubTitle>Who is Auscultate for?</SubTitle>
                <MediumText>
                  Auscultate was made for healthcare and medical professionals
                  and students. <br />
                  The sound library is a perfect reference for students and
                  professionals to revisit and compare sounds you might hear in
                  clinic.
                </MediumText>
                <div className="grid-3">
                  <div>
                    <FontAwesomeIcon icon={faChalkboardTeacher} size="4x" />
                    <div className="who-for">For Students</div>
                    <div>
                      Using Auscultate's interactive software, students are able
                      to hear and learn normal and abdnormal heart sounds before
                      ever stepping into a clinic.
                    </div>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faUserGraduate} size="4x" />
                    <div className="who-for">For New Graduates</div>
                    <div>
                      Junior doctors, nurses and other advance healthcare
                      providers will learn to differentiate between sounds and
                      start associating these with real-world examples.
                    </div>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faUserMd} size="4x" />
                    <div className="who-for">For Professionals</div>
                    <div>
                      Professionals will reinforce their knowledge by analyzing
                      sounds and correlating them to real-world pathology with
                      Auscultate's clinical correlation feature.
                    </div>
                  </div>
                </div>
              </div>
            </Container>

            <Container>
              <LandingBlock className="pricing below-fold-block">
                <SubTitle id="pricing">Pricing</SubTitle>
                <div className="price">
                  {app.isSale ? (
                    <Fragment>
                      <div className="sale-tag">ON SALE!</div>
                      <span className="strikethrough">
                        {"$" + toCurrency(app.price)}
                      </span>
                      <span className="sale">
                        {"$" + toCurrency(app.salePrice)}
                      </span>
                      <span> / One-time fee</span>
                      <div>Unlimited lifetime access</div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span className="sale">
                        {"$" + toCurrency(app.price)}
                      </span>
                      <span> / One-time fee</span>
                      <div>Unlimited lifetime access</div>
                    </Fragment>
                  )}
                </div>

                <StyledButton
                  className="yellow"
                  onClick={() =>
                    (window.location =
                      "https://medzcool.com/auscultate/checkout")
                  }
                >
                  BUY NOW
                </StyledButton>
              </LandingBlock>
            </Container>

            <Container>
              <LandingBlock className="call-to-action below-fold-block">
                <SubTitle>What are you waiting for?</SubTitle>
                <MediumText>
                  You can access the Auscultate app through your Medzcool
                  account - login and subscribe. If you don’t have a Medzcool
                  account, you can create one here.
                </MediumText>
                <StyledButton
                  className="yellow"
                  onClick={() =>
                    (window.location =
                      "https://medzcool.com/auscultate/checkout")
                  }
                >
                  SIGN UP & BUY NOW
                </StyledButton>
                <div className="or-login">
                  or <a href="/login">Log In</a> with an existing account
                </div>
              </LandingBlock>
            </Container>
          </div>
        </Fragment>
      </HomepageNonAuthContainer>
      <Footer />
    </Fragment>
  );
}

const AuscultateTitle = styled(animated.div)``;
const HomepageNonAuthContainer = styled(animated.div)`
  padding: 5px 15px 50px 15px;
  background: white;
  .below-fold {
    .below-fold-block {
      margin: 160px 15px;
      .above-subtitle-text {
        color: #ed0e5e;
        font-size: 12px;
        margin-bottom: 8px;
      }
    }
    .second {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-gap: 50px;
      align-items: center;
      img {
        width: 100%;
      }
      &.feature {
        text-align: center;
        grid-template-columns: 1fr 1.3fr;
        grid-gap: 60px;
        h3 {
          margin-top: 20px;
        }
      }
    }
    .third {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 50px;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .pricing {
      text-align: center;
      .price {
        padding: 20px;
      }
      .strikethrough {
        text-decoration: line-through;
        color: gray;
        font-size: 22px;
      }
      .sale {
        font-size: 36px;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 425px) {
    .below-fold {
      h3 {
        line-height: 1em;
      }
      .below-fold-block {
        margin: 120px 0;
        .above-subtitle-text {
          font-size: 10px;
        }
      }
      .second {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        grid-template-columns: 2fr 1.4fr;
        grid-gap: 18px;
        &.feature {
          grid-template-columns: 1fr;
          grid-gap: 15px;
        }
      }
      .third {
        margin-bottom: 0;
        grid-gap: 18px;
        grid-template-columns: 1.4fr 2fr;
      }
    }
  }
`;
const JumbotronContainer = styled.div`
  margin: auto;
  position: relative;
  background: white;
  .header {
    position: absolute;
    top: 25px;
    right: 25px;
    display: grid;
    grid-template-columns: 0.6fr 1.2fr 1fr;
    z-index: 2;
    .mobile {
      display: none;
    }
  }
  .title-container {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 2fr;
    background-image: url(${imgDesktop});
    position: relative;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 56vw;
    .title-block {
      justify-self: center;
      align-self: center;
      position: relative;
      max-width: 60%;
      .button-container {
        margin-top: 35px;
        button {
          background: black;
          color: white;
        }
      }
    }
    ${Title1} {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 50px;
      .mobile {
        display: none;
      }
    }
    .subtitle {
      font-size: 22px;
      font-family: ${fontFamily.sansSerif};
      margin-bottom: 15px;
      margin-top: 5px;
    }
  }
  @media (max-width: 1200px) {
    .title-container {
      .title-block {
        max-width: 70%;
      }
      ${Title1} {
        font-size: 34px;
      }
      .subtitle {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 1024px) {
    .title-container {
      ${Title1} {
        font-size: 30px;
      }
      .subtitle {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 768px) {
    .title-container {
      ${Title1} {
        font-size: 26px;
      }
      .subtitle {
        font-size: 14px;
      }
    }
    .header {
      top: 15px;
      right: 15px;
      .desktop {
        display: none;
      }
      .mobile {
        display: block;
        background: none;
        grid-column-end: none;
      }
    }
  }
  @media (max-width: 550px) {
    .title-container {
      display: block;
      background-image: url(${imgMobile});
      .title-block {
        top: 0px;
        left: 0px;
        padding: 15px;
        .button-container {
          display: none;
        }
      }
      ${Title1} {
        font-size: 18px;
        .desktop {
          display: none;
        }
        .mobile {
          display: block;
        }
      }
      .subtitle {
        display: none;
      }
    }
    .header {
      top: 10px;
      right: 10px;
    }
  }
`;

const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  color: black;
  max-width: 225px;
  border-radius: 4px;
  &.login {
    width: 150px;
  }
  &.sign-up {
    background: none;
    margin-right: 25px;
  }
  &.pricing {
    background: none;
    a {
      text-decoration: none;
      color: black;
    }
  }
  &.yellow {
    background: #edcd18;
    margin-top: 25px;
    font-family: futura-pt, sans-serif;
  }

  @media (max-width: 768px) {
    font-size: 11px;
    &.login {
      width: auto;
      margin-left: 10px;
      padding: 0 15px;
    }
    &.sign-up {
      font-weight: 700;
    }
  }
`;
const LandingBlock = styled.div`
  margin: 50px 0;
  &.columns-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    .sound-category {
      text-align: center;
    }
    ul {
      text-align: left;
      .exclusive {
        color: orange;
        font-weight: 300;
      }
    }
    h3 {
      font-size: 22px;
    }
  }
  &.call-to-action {
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 500px;
    .or-login {
      margin-top: 3px;
      font-family: futura-pt, sans-serif;
    }
  }
  &.sound-library {
    margin-top: 160px;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 0px;
    text-align: center;
    h3 {
      margin-top: 25px;
    }
  }
  &.grid-2 {
    display: grid;
    grid-template-columns: 200px auto;
    grid-gap: 35px;
    img {
      width: 100%;
    }
    h3 {
      margin-top: 0;
    }
  }
  @media (max-width: 768px) {
    &.sound-library {
      h3 {
        font-size: 26px;
      }
    }
    &.columns-2 {
      grid-template-columns: 1fr;
      h3 {
        font-size: 20px;
        margin-top: 15px;
      }
    }
    &.grid-2 {
      grid-template-columns: 3fr 5fr;
      grid-gap: 25px;
      top: -40px;
    }
  }
  @media (max-width: 425px) {
    &.columns-2 {
      grid-template-columns: 1fr;
    }
    &.grid-2 {
      display: block;
      grid-template-columns: 2fr 5fr;
      grid-gap: 15px;
      top: 0;
      padding: 15px 0;
      box-shadow: none;
      margin-top: 25px;
      img {
        display: none;
      }
    }
  }
`;
const SubTitle = styled.h3`
  font-size: 30px;
  font-family: ${fontFamily.sansSerif};
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 5px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
const AppContainer = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  @media (max-width: 768px) {
    display: block;
  }
`;
const SidebarContainer = styled.div`
  background: #131313;
  @media (max-width: 768px) {
    display: none;
  }
`;
const MainStage = styled.div`
  display: grid;
  margin-bottom: 250px;
  @media (max-width: 768px) {
    margin-bottom: 75px;
  }
`;

const Container = styled.div`
  margin: auto;
  max-width: 1000px;
  font-family: ${fontFamily.sansSerif};
  &.top {
    margin-top: 75px;
  }
  &.first {
    padding-top: 0;
    position: relative;
    max-width: 800px;
    ${SubTitle} {
      margin-bottom: 5px;
    }
  }
  .who {
    text-align: center;
    .who-for {
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 3px;
    }
  }
  .grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 35px;
    margin-top: 50px;
  }

  .button-container-mobile {
    display: none;
  }
  @media (max-width: 768px) {
    &.first {
      max-width: 600px;
    }
    &.top {
      margin-top: 50px;
    }
    .button-container-mobile {
      display: block;
      margin: 35px 0 0 0;
    }
  }
  @media (max-width: 425px) {
    .grid-3 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 25px;
      margin-top: 50px;
    }
    .who {
      margin-top: 120px;
    }
  }
`;

const MediumText = styled.div`
  font-size: 18px;
  font-family: futura-pt, sans-serif;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;
const SmallText = styled.div`
  font-size: 12px;
  font-family: futura-pt, sans-serif;
  @media (max-width: 425px) {
    font-size: 10px;
  }
`;
const Screenshots = styled.div`
  margin: auto;
  max-width: 1000px;
  .screenshots {
    margin: auto;
    margin: 25px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    img {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .screenshots {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }
  }
`;
const Line = styled.div`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 40px 0 5px 0;
`;
const Notification = styled.div`
  margin-top: 15px;
  margin-bottom: 45px;
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
  SmallText
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";

// Data
import { soundData } from "../Static/SoundData";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

export default function MitralStenosis(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Mitral");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Mitral Stenosis</h1>
      </TitleBlock>
      <Intro>
        <p>
          The murmur of mitral stenosis will sometimes follow an opening snap,
          which is caused by the the abrupt halt in leaflet motion in early
          diastole, after rapid initial rapid opening of the mitral valve
          leaflefts. The opening snap is then followed by a mid-to-late,
          rumbling diastolic murmur.
        </p>
        <ImgContainer>
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mitral-stenosis-murmur-graphic.png"
            alt="mitral stenosis"
            className="first"
          />
        </ImgContainer>
        <p>
          This murmur is often described as low-pitched, decrescendo-crescendo,
          rumbling diastolic murmur, and is heard best at the apex of the heart.
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/mitral-stenosis-location.jpg"
              alt="mitral stenosis"
              className="second thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
        <p>
          In the setting of a murmur, the loud S1 may be difficult to hear. It
          is better appreciated when listening to the heart sound only with an
          opening snap. The sound of the opening snap is high pitched with a
          sharp/snapping quality. It can be mistaken for a split S2, however the
          opening snap is best appreciated at the apex, and a split S2 is best
          heard at the base of the heart.
        </p>
        <p>
          Some people with mitral stenosis may not have a diastolic murmur and
          only an opening snap. However, keep in mind that the intensity of the
          diastolic murmur does not correlate with the severity of the stenosis.
        </p>
      </Intro>
      <P1>
        <h2>Causes</h2>
        <p>
          Mitral stenosis is often a consequence of rheumatic heart disease.
        </p>
      </P1>
      <P1>
        <h2>
          Clinical Correlation
          <SmallText>Medical Multiple choices questions.</SmallText>
        </h2>

        <CaseCard
          caseId="ELe3Mb69"
          caseTitle="Asymptomatic Diastolic Heart Murmur"
        />
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }

  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "mitral-stenosis"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Mitral Stenosis"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mitral-stenosis.mp3"
        visual="https://vimeo.com/336226868/434c7eda01"
        video="https://vimeo.com/336220722/daa19bf292"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .first {
    max-width: 600px;
    width: 100%;
    margin: 15px 0;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis-container {
    position: relative;
    width: max-content;
    margin: auto;
    .thoraxis {
      cursor: pointer;
      box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
    .expand-icon {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 15px;
      color: white;
      opacity: 0.5;
      .icon {
        vertical-align: middle;
      }
      span {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .thoraxis-container {
      width: 100%;
    }
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

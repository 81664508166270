import React, { Fragment } from "react";

// Dependencies
import { Link } from "react-router-dom";

// Styles
import styled from "styled-components";
import { fontFamily } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHome } from "@fortawesome/pro-regular-svg-icons";

export default function CourseSidebar(props) {
  const { lessons } = props;
  const sectionOneLessons = lessons.slice(0, 4);
  const sectionTwoLessons = lessons.slice(4, 7);
  const sectionThreeLessons = lessons.slice(7, 12);
  const sectionFourLessons = lessons.slice(12, 17);
  return (
    <SidebarContainer>
      <Inner>
        <SectionContainer>
          <Section>
            <div className="section-header">
              <div className="section-label">Section 1</div>
              <div className="section-title">Anatomy & Physiology</div>
            </div>
            <div className="section-lessons">
              {sectionOneLessons.map(lesson => (
                <Lesson key={lesson.id} to={lesson.id}>
                  <span className="lesson-id">{lesson.lessonId}</span>
                  <span className="lesson-title">{lesson.title}</span>
                </Lesson>
              ))}
            </div>
          </Section>
          <Section>
            <div className="section-header">
              <div className="section-label">Section 2</div>
              <div className="section-title">Fundamentals of Auscultation</div>
            </div>
            <div className="section-lessons">
              {sectionTwoLessons.map(lesson => (
                <Lesson key={lesson.id} to={lesson.id}>
                  <span className="lesson-id">{lesson.lessonId}</span>
                  <span className="lesson-title">{lesson.title}</span>
                </Lesson>
              ))}
            </div>
          </Section>
          <Section>
            <div className="section-header">
              <div className="section-label">Section 3</div>
              <div className="section-title">S1 & S2 Heart Sounds</div>
            </div>
            <div className="section-lessons">
              {sectionThreeLessons.map(lesson => (
                <Lesson key={lesson.id} to={lesson.id}>
                  <span className="lesson-id">{lesson.lessonId}</span>
                  <span className="lesson-title">{lesson.title}</span>
                </Lesson>
              ))}
            </div>
          </Section>
          <Section>
            <div className="section-header">
              <div className="section-label">Section 4</div>
              <div className="section-title">Extra Heart Sounds</div>
            </div>
            <div className="section-lessons">
              {sectionFourLessons.map(lesson => (
                <Lesson key={lesson.id} to={lesson.id}>
                  <span className="lesson-id">{lesson.lessonId}</span>
                  <span className="lesson-title">{lesson.title}</span>
                </Lesson>
              ))}
            </div>
          </Section>
        </SectionContainer>
      </Inner>
    </SidebarContainer>
  );
}
const SidebarContainer = styled.div`
  background: black;
  position: relative;
  font-family: ${fontFamily.sansSerif};
`;
const Inner = styled.div`
  position: fixed;
  overflow-y: scroll;
  width: 249px;
  top: 50px;
  height: 100%;
  margin-bottom: 60px;
  border-right: 1px solid #5a5a5a;
`;
const Section = styled.div`
  .section-header {
    background: #303030;
    border-bottom: 1px solid #5a5a5a;
    color: white;
    padding: 15px;
    .section-label {
      font-size: 12px;
    }
    .section-title {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
const SectionContainer = styled.div`
  margin-bottom: 70px;
`;
const Lesson = styled(Link)`
  text-decoration: none;
  background: #131313;
  border-bottom: 1px solid #5a5a5a;
  color: white;
  padding: 15px 15px;
  font-size: 14px;
  display: grid;
  grid-template-columns: auto 4fr;
  grid-gap: 10px;
`;

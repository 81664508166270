import React, { useEffect, useState, Fragment } from "react";

// Dependencies
import ReactPlayer from "react-player";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily, Button } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faPauseCircle,
  faBackward
} from "@fortawesome/pro-regular-svg-icons";
import { faForward } from "@fortawesome/pro-solid-svg-icons";

export default function StethosSoundPlayer(props) {
  const { media, isAnswered, isDone, handleBack, sessionObject } = props;
  const currentSound = sessionObject.heartSounds[sessionObject.arrayPosition];
  const answerSelected = currentSound.selectedChoice;

  const [playing, setPlaying] = useState(true);

  function handlePlay() {
    setPlaying(!playing);
  }

  const [volume, setVolume] = useState(1.0);

  const audio = currentSound.audio;

  const [playerType, setPlayerType] = useState("audio");
  const [activeMedia, setActiveMedia] = useState(audio);
  useEffect(
    () => {
      setActiveMedia(audio);
    },
    [audio]
  );

  if (!audio) return null;

  return (
    <MediaPlayerContainer>
      <MediaPlayerInner>
        <ReactPlayerContainer playerType={playerType}>
          <StyledReactPlayer
            url={activeMedia}
            volume={volume}
            playing={playing}
            loop
          />
        </ReactPlayerContainer>
        <Controls>
          <StyledFontAwesomeIcon
            icon={playing ? faPauseCircle : faPlayCircle}
            size="3x"
            onClick={handlePlay}
          />

          {isAnswered ? null : (
            <Fragment>
              {sessionObject.arrayPosition !== 0 && (
                <StyledButton onClick={handleBack} className="nav back">
                  <FontAwesomeIcon icon={faBackward} />
                </StyledButton>
              )}

              <StyledButton
                className="nav next submit"
                onClick={props.handleSubmit}
                disabled={!answerSelected && true}
              >
                {answerSelected ? "SUBMIT ANSWER" : "Choose an Answer"}
              </StyledButton>
            </Fragment>
          )}

          {isAnswered ? (
            sessionObject.arrayPosition + 1 ==
            sessionObject.heartSounds.length ? (
              <Fragment>
                <StyledButton onClick={handleBack} className="nav back">
                  <FontAwesomeIcon icon={faBackward} />
                </StyledButton>
                <StyledButton
                  onClick={() => props.handleNext("finish")}
                  className="nav next"
                >
                  FINISH
                </StyledButton>
              </Fragment>
            ) : sessionObject.arrayPosition == 0 ? (
              <Fragment>
                <StyledButton onClick={props.handleNext} className="nav next">
                  <FontAwesomeIcon icon={faForward} />
                </StyledButton>
              </Fragment>
            ) : (
              <Fragment>
                <StyledButton onClick={handleBack} className="nav back">
                  <FontAwesomeIcon icon={faBackward} />
                </StyledButton>
                <StyledButton onClick={props.handleNext} className="nav next">
                  <FontAwesomeIcon icon={faForward} />
                </StyledButton>
              </Fragment>
            )
          ) : null}
        </Controls>
      </MediaPlayerInner>
    </MediaPlayerContainer>
  );
}
const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  background: #edcd18;
  color: black;
  border-radius: 4px;
  @media (max-width: 768px) {
    font-size: 11px;
    &.login {
      width: auto;
      margin-left: 10px;
      padding: 0 15px;
    }
    &.sign-up {
      font-weight: 700;
    }
  }
  ${props => props.disabled && `background: lightgray; color: gray;`}
`;
const Controls = styled.div`
  display: grid;
  grid-template-columns: auto 2fr 1fr 1fr;
  grid-gap: 50px;
  padding: 20px 50px;
  margin: auto;
  max-width: 1000px;
  .nav {
    min-width: 100px;
    padding: 17px;
  }
  .next {
    grid-column-end: 5;
  }
  .back {
    grid-column-end: 4;
  }
  .submit {
    min-width: 125px;
  }
  @media (max-width: 768px) {
    grid-template-columns: auto 0fr;
    padding: 15px;
    grid-gap: 15px;
    align-items: center;
  }
  @media (max-width: 425px) {
    .nav {
      min-width: 75px;
    }
  }
`;
const ControlButton = styled.div`
    text-align: center;
  .label {
      font-size: 8px
      text-align: center;
      color:white;
      margin-top: 3px;
  }
`;
const MediaPlayerContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  @media (max-width: 768px) {
    z-index: 4;
    position: fixed;
    width: 100%;
  }
`;
const MediaPlayerInner = styled.div`
  background: #111111;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: white;
  cursor: pointer;
  ${props => props.active && `color:#C23B55`}
`;
const StyledReactPlayer = styled(ReactPlayer)`
  margin: auto;
  width: 100% !important;
  height: 500px !important;
`;
const ReactPlayerContainer = styled.div`
  ${StyledFontAwesomeIcon} {
    position: absolute;
    top: 25px;
    right: 25px;
  }
  ${props => props.playerType == "audio" && `display:none;`}
`;

import React, { useContext } from "react";
import { MixpanelContext } from "../Context/Context";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeartbeat,
  faHeart,
  faLungs,
  faStomach,
} from "@fortawesome/pro-light-svg-icons";

// Dependencies
import { Link } from "react-router-dom";

export default function SoundSidebar(props) {
  const { soundTitle, url, isBreath, isBowel } = props;
  const mixpanel = useContext(MixpanelContext);
  return (
    <SoundContainer>
      <StyledLink
        to={"/sound/" + url}
        onClick={() => {
          mixpanel.track("Auscultate", {
            Location: "Sidebar",
            Label: soundTitle,
          });
        }}>
        {isBreath ? (
          <FontAwesomeIcon icon={faLungs} />
        ) : isBowel ? (
          <FontAwesomeIcon icon={faStomach} />
        ) : (
          <FontAwesomeIcon icon={faHeart} />
        )}
        <span>{soundTitle}</span>
      </StyledLink>
    </SoundContainer>
  );
}

const SoundContainer = styled.div`
  color: ${grayscale.white};
  margin-bottom: 15px;
  span {
    margin-left: 10px;
    font-family: ${fontFamily.sansSerif};
    font-weight: 400;
    font-size: 14px;
  }
`;

const StyledLink = styled(Link)`
  color: ${grayscale.white};
  text-decoration: none;
`;

import React, { useState, useEffect } from "react";
import { homeUrl, url } from "../settings";

// Dependencies
import { Link, Redirect } from "react-router-dom";
import ReactGA from "react-ga";

// Styles
import styled from "styled-components";
import {
  Title,
  MediumText,
  SmallText,
  AnchorTag,
  fontWeight
} from "../Utilities";

import Button from "../Utilities/Button";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user {
        id
        isSuperuser
        profile {
          id
          subscribedApps {
            id
            title
            description
            url
            logo
          }
        }
      }
    }
  }
`;
const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      profile {
        id
        subscribedApps {
          id
          title
          description
          url
          logo
        }
      }
    }
  }
`;

export default function Login(props) {
  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, []);

  const [username, setUsername] = useState("");
  function handleUsername(e) {
    setUsername(e.target.value);
    handleButtonActive();
  }
  const [password, setPassword] = useState("");
  function handlePassword(e) {
    setPassword(e.target.value);
    handleButtonActive();
  }

  const [formFilled, setFormFilled] = useState(false);
  function handleButtonActive() {
    if (username.length > 0 && password.length > 0) {
      setFormFilled(true);
    }
  }

  function handleLogin(e, login) {
    e.preventDefault();
    if (username.length > 0 && password.length > 0) {
      login();
    }
  }

  function handleSubmit(e, login) {
    e.preventDefault();
    if (username.length > 0 && password.length > 0) {
      login();
    }
  }
  const [cannotAuthMsg, setCannotAuthMsg] = useState();
  function cannotAuth(msg) {
    setCannotAuthMsg(msg);
  }

  if (props.user) {
    return <Redirect to="/" />;
  }

  return (
    <LoginContainer>
      <Title>Login</Title>
      <MediumText>
        or{" "}
        <a href="https://medzcool.com/register/auscultate">create an account</a>
      </MediumText>
      <Mutation
        mutation={LOGIN}
        variables={{ username: username, password: password }}
        update={(cache, data) => {
          if (!data.data.login.user) {
            cannotAuth(
              "The username and/or password do not match. Please try again."
            );
          }
          cache.writeData({ data: { user: data.data.login.user } });
        }}
        onCompleted={data => {
          console.log(data.login.user);
          if (!data.login.user) {
            cannotAuth(
              "The username and/or password do not match. Please try again."
            );
          } else {
            if (props.match.params.redirect) {
              props.history.push("/" + props.match.params.redirect);
            } else {
              props.history.push("/");
            }
          }
        }}
      >
        {(login, { data, error }) => {
          return (
            <FormContainer>
              <MediumText>
                <p>{cannotAuthMsg}</p>
              </MediumText>
              {error && (
                <MediumText>
                  <p>{error.graphQLErrors[0].message}</p>
                </MediumText>
              )}

              <form onSubmit={e => handleSubmit(e, login)}>
                <input
                  type="text"
                  value={username}
                  placeholder="Username or Email"
                  onChange={handleUsername}
                />
                <input
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={handlePassword}
                />
                <Button
                  buttonLabel="Sign-In"
                  active={formFilled}
                  onClick={e => handleLogin(e, login)}
                  type="submit"
                />
              </form>
              <MediumText>
                <p className="consent">
                  By clicking “Sign-In” below, you agree to our{" "}
                  <a href="https://medzcool.com/terms">Terms of Service</a> and{" "}
                  <a href="https://medzcool.com/privacy">Privacy Statement</a>.
                </p>
              </MediumText>
            </FormContainer>
          );
        }}
      </Mutation>
    </LoginContainer>
  );
}

const LoginContainer = styled.div`
  padding: 15px;
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
  ${Title} {
    margin-bottom: 0px;
  }
  .consent {
    margin-top: 50px;
  }
  a {
    color: #295AEC;
  }
`;
const FormContainer = styled.div`
  margin-top: 35px;
  input {
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    border-radius: 3px;
    border: lightgray 1px solid;
    margin-bottom: 15px;
    font-size: 16px;
  }
`;

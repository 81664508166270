import React, { useState, useEffect, Fragment, Suspense, lazy } from "react";
import { url } from "../settings";

import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";

// Dependencies
import { Route, Link } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";

// Components
import CaseStem from "./CaseStem";
import CaseQuestionAnswer from "./CaseQuestionAnswer";
import Spinner from "../Utilities/Spinner";
import CaseFooter from "./CaseFooter";
import CaseDetailsHeader from "../Header/CaseDetailsHeader";

// Elements
// import { CaseBlock, StyledLabel } from "../elements";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faChevronDown,
  faChevronUp,
  faAward
} from "@fortawesome/pro-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

// Styles
import styled from "styled-components";
import {
  purple,
  green,
  red,
  grayscale,
  fontFamily,
  LoadingContainer
} from "../Utilities";
// import { Button } from "../elements";

// Apollo, GraphQL
import { Query, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

const CaseChoices = lazy(() => import("./CaseChoices"));
const CaseExplanation = lazy(() => import("./CaseExplanation"));
// const RelatedCases = lazy(() => import("./RelatedCases"));

export default function CaseDetails(props) {
  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, []);

  const caseId = props.match.params.caseId;

  const { collection, handleCaseAnswerState } = props;

  const [caseObject, setCaseObject] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    props.handleCaseActive(true);
    return () => {
      props.handleCaseActive(false);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    axios({
      method: "post",
      url: "https://codehealth.io/api/v0.1/fetch-case/",
      data: {
        caseId: caseId
      }
    }).then(function(response) {
      setCaseObject(response.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    setCaseObject();
    setSelectedChoice();
    setAnswered(false);
    setCorrect();

    axios({
      method: "post",
      url: "https://codehealth.io/api/v0.1/fetch-case/",
      data: {
        caseId: caseId
      }
    }).then(function(response) {
      setCaseObject(response.data.data);
      setLoading(false);
    });
  }, [caseId]);

  const [selectedChoice, setSelectedChoice] = useState();
  function handleChoiceChange(event) {
    if (!answered) {
      setSelectedChoice(event.target.value);
    }
  }

  const [answered, setAnswered] = useState(false);
  const [correct, setCorrect] = useState();

  function handleAnswer(choices, layout) {
    window.scrollTo(0, 0);

    // 1. Get the correct answer choice
    const correctChoice = choices.find(choice => {
      return choice.correct_answer === true;
    });

    // 2. Compare user's selected choice with the correct answer choice
    // 3. Set case state to 'answered'
    if (selectedChoice == correctChoice.id) {
      setCorrect(true);
      collection && handleCaseAnswerState("correct", caseId);
    } else {
      collection && handleCaseAnswerState("incorrect", caseId);
    }
    setAnswered(true);
  }

  // when user selects a related case, resent casedetails states
  function handleRelatedCase() {
    setAnswered(false);
    setCorrect();
    setSelectedChoice();
  }

  function goBack() {
    props.history.goBack();
  }

  function createMarkup(html) {
    return { __html: html };
  }

  function createDraftText(caseObject) {
    if (caseObject.caseType == "clinical scenario") {
      return caseObject.stems[0].stem;
    } else if (caseObject.caseType == "question") {
      return caseObject.questions[0].question;
    } else {
      return null;
    }
  }

  if (loading)
    return (
      <LoadingContainer>
        <CaseDetailsHeader user={props.user} />
        <Spinner />
      </LoadingContainer>
    );

  return (
    <Fragment>
      <CaseDetailsHeader
        user={props.user}
        {...props}
        correct={correct}
        answered={answered}
      />
      <CaseMainContainer answered={answered} correct={correct}>
        <CaseSubContainer answered={answered}>
          <MainCaseBlock>
            <CaseStem
              caseType={caseObject.caseType}
              answered={answered}
              caseLayout={caseObject.layout}
              oldStem={createMarkup(caseObject.clinicalScenario)}
              stem={createDraftText(caseObject)}
              oldQuestion={createMarkup(caseObject.clinicalScenario)}
              question={createDraftText(caseObject)}
            />

            <ChoicesBlock answered={answered} mobile={true}>
              <CaseBlock>
                <CaseQuestionAnswer
                  caseObject={caseObject}
                  createMarkup={createMarkup}
                />
                <Suspense fallback={<Loading>Loading....</Loading>}>
                  <CaseChoices
                    caseObject={caseObject}
                    handleChoiceChange={handleChoiceChange}
                    caseHash={props.match.params.caseHash}
                    handleAnswer={handleAnswer}
                    selectedChoice={selectedChoice}
                    answered={answered}
                    correct={correct}
                    user={props.user}
                  />
                </Suspense>
              </CaseBlock>
            </ChoicesBlock>
            <Suspense fallback={<Loading>Loading....</Loading>}>
              <CaseExplanation
                answered={answered}
                caseHash={props.match.params.caseHash}
                caseObject={caseObject}
              />
            </Suspense>
          </MainCaseBlock>

          <ChoicesBlock answered={answered}>
            <CaseBlock>
              <CaseQuestionAnswer
                caseObject={caseObject}
                createMarkup={createMarkup}
              />
              <Suspense fallback={<Loading>Loading....</Loading>}>
                <CaseChoices
                  caseObject={caseObject}
                  handleChoiceChange={handleChoiceChange}
                  caseHash={props.match.params.caseHash}
                  handleAnswer={handleAnswer}
                  selectedChoice={selectedChoice}
                  answered={answered}
                  correct={correct}
                  user={props.user}
                />
              </Suspense>
            </CaseBlock>
          </ChoicesBlock>
        </CaseSubContainer>
        <CaseFooter
          user={props.user}
          caseHash={caseId}
          caseObject={caseObject}
          answered={answered}
          collection={collection}
          {...props}
        />
      </CaseMainContainer>
    </Fragment>
  );
}

const CaseBlock = styled.div`
  margin: 15px 0;
  background: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 25px;
  position: relative;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
  margin-bottom: 15px;
`;

const RelatedCasesContainer = styled.div`
  margin-bottom: 150px;
`;
const CallToAction = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #333333;
  padding: 15px;
  color: white;
  font-family: futura-pt, sans-serif;
  box-sizing: border-box;
`;
const FooterContainer = styled.div`
  z-index: 20;
  padding: 18px 0 32px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f7f7f7;
  border-top: 1px solid #e7e7e7;
  @media (max-width: 768px) {
    height: 50px;
  }
`;
const CaseMainContainer = styled.div`
  position: absolute;
  top:0;
  left: 0
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: auto;
  background: ${grayscale.lightGray};
  a {
    color: ${purple.darkLight};
    ${props => props.correct && props.answered && `color: ${green.bright};`}
    ${props => !props.correct && props.answered && `color: ${red.bright};`}
  }
  .old-case-text {
      line-height: 30px;
      font-family: adobe-text-pro, serif;
      font-size: 18px;
  }
  .question {
      margin-bottom: 10px;
  }
  .public-DraftStyleDefault-block {
      margin-bottom: 10px;
  }
  @media (max-width: 576px) {
      .old-case-text{
          font-size: 15px;
          line-height:25px;
      }
  }
`;
const CaseSubContainer = styled.div`
  padding: 0px;
  max-width: 1100px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 100px;
  ${props => !props.answered && `margin-bottom: 100px;`}
`;
const MainCaseBlock = styled.div`
  margin: 0 15px 0 11px;
  display: inline-block;
  width: 60%;
  vertical-align: top;
  @media (max-width: 992px) {
    width: 58.4%;
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;
const ChoicesBlock = styled.div`
  display: inline-block;
  width: 36.5%;
  vertical-align: top;
  ${props => props.mobile && "display: none"}
  @media (max-width: 992px) {
    width: 38.4%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    ${props => props.mobile && "display: block"}
    ${props => !props.mobile && "display: none"}
  }
`;
const ExplanationCaseBlock = styled(CaseBlock)`
  display: none;
  ${props => props.answered && `display: block;`}
  img {
    width: 100%;
  }
`;
const TitleCaseBlock = styled(CaseBlock)`
  border-radius:0
  margin: 15px 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0;
  z-index: 99;
  width: 100%;
  background: white;
  min-height: 30px;
  h2 {
    font-family: futura-pt, sans-serif;
    margin: 5px;
    max-width: 1100px;
    margin: auto;
    font-size:18px;
    @media (max-width: 1200px) {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      max-width: 76%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${props =>
    props.correct &&
    props.answered &&
    `background: ${green.bright}; color:white;`}
  ${props =>
    !props.correct &&
    props.answered &&
    `background: ${red.bright};color:white;`}
`;
const BackButton = styled.button`
  border: 0;
  color: black;
  padding: 0;
  outline: none;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  cursor: pointer;
  ${props => props.answered && `color: white;`}
  @media (max-width: 1200px) {
    display: inline-block;
    position: relative;
    margin: 0;
    transform: none;
    vertical-align: middle;
    left: 0;
    margin: 0 25px 0 15px;
  }
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 14px;
  text-align: center;
  color: gray;
`;
const DoesNotExist = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 50px 15px 15px 15px;
  text-align: center;
  font-family: ${fontFamily.sansSerif};
  h2 {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
} from "../../Utilities";
import Spinner from "../../Utilities/Spinner";
import MediaPlayerStatic from "../../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../../Header/HeaderMobile";
import CaseCard from "../CaseCard";
import { SmallText } from "../../Utilities";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

// Data
import { soundData } from "../../Static/SoundData";

export default function HypoactiveBowel(props) {
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Hypoactive Bowel Sounds</h1>
      </TitleBlock>
      <Intro>
        <p>
          Hypoactive bowel sounds include a reduction in the loudness, tone, or
          frequency compared to normal, and in extreme circumstances, they may
          even be absent.
        </p>
        <p>
          This can occur in cases of adynamic ileus and peritonitis. Absent
          bowel sounds can also suggest bowel necrosis or perforation.
        </p>
        <p>
          Before determining that bowel sounds are absent, make sure to listen
          for bowel sounds for 2 minutes or longer.
        </p>
      </Intro>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.bowelSounds;
    const currentSound = jsonSoundData.filter(
      (sound) => sound.id == "hypoactive-bowel-sounds"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Hypoactive Bowel Sounds"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic audioArray={audioArray} isBreath />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  width: max-content;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    cursor: pointer;
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .expand-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    color: white;
    opacity: 0.5;
    .icon {
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  font-family: futura-pt, sans-serif;
  ul {
  }

  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;

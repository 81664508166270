import React, { Fragment, useEffect } from "react";

// Dependencies
import ReactGA from "react-ga";

// Components
import Sidebar from "../Sidebar/Sidebar";
import SoundCard from "../Sounds/SoundCard";
import SoundSidebar from "../Sidebar/SoundSidebar";
import Header from "../Header/Header";

// Styles
import styled from "styled-components";
import { fontFamily, Title1, Title2, Line } from "../Utilities";

// Dependencies
import { Route, Link } from "react-router-dom";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-regular-svg-icons";

export default function Homepage(props) {
  const { mixpanel } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(props.location.pathname);
    mixpanel.track("Auscultate", {
      Location: "Homepage",
      Action: "Pageview",
    });
  }, []);
  return (
    <Fragment>
      <HomepageContainer>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
      </HomepageContainer>

      <SoundCategories>
        <div className="sound-categories-inner">
          <div
            className="card bowel"
            onClick={() => props.history.push("/sound/bowel-sounds")}>
            <div className="new">NEW</div>
            <div className="label">Bowel Sounds</div>
          </div>
          <div
            className="card lungs"
            onClick={() => props.history.push("/sound/lung-sounds")}>
            <div className="label">Lung Sounds</div>
          </div>
        </div>
        <div className="sound-categories-inner">
          <div
            className="card location"
            onClick={() => props.history.push("/auscultation-points")}>
            <div className="new">Heart Sounds</div>
            <div className="label">Sounds by Auscultation Area</div>
          </div>
          <div
            className="card extra"
            onClick={() => props.history.push("/sound/extra-sounds")}>
            <div className="label">Extra Heart Sounds</div>
          </div>
          <div
            className="card systolic"
            onClick={() => props.history.push("/sound/systolic-murmurs")}>
            <div className="label">Systolic Murmurs</div>
          </div>
          <div
            className="card diastolic"
            onClick={() => props.history.push("/sound/diastolic-murmurs")}>
            <div className="label">Diastolic Murmurs</div>
          </div>
        </div>
      </SoundCategories>
      <SoundIndexContainer>
        <Title2>Sound Index</Title2>
        <div className="soundcard-container">
          <SoundCard
            key="normal-heart-sound"
            url="normal-heart-sound"
            soundTitle="Normal Heart Sound"
          />
          <SoundCard
            key="aortic-regurgitation"
            url="aortic-regurgitation"
            soundTitle="Aortic Regurgitation"
          />
          <SoundCard
            key="aortic-stenosis"
            url="aortic-stenosis"
            soundTitle="Aortic Stenosis"
          />
          <SoundCard
            key="atrial-septal-defect"
            url="atrial-septal-defect"
            soundTitle="Atrial Septal Defect"
          />
          <SoundCard
            key="holosystolic-murmur"
            url="holosystolic-murmur"
            soundTitle="Holosystolic Murmur"
          />
          <SoundCard
            key="hypertrophic-cardiomyopathy"
            url="hypertrophic-cardiomyopathy"
            soundTitle="Hypertrophic Cardiomyopathy"
          />
          <SoundCard
            key="innocent-murmur"
            url="innocent-murmur"
            soundTitle="Innocent Murmur"
          />
          <SoundCard
            key="mitral-stenosis"
            url="mitral-stenosis"
            soundTitle="Mitral Stenosis"
          />
          <SoundCard
            key="mitral-valve-prolapse"
            url="mitral-valve-prolapse"
            soundTitle="Mitral Valve Prolapse"
          />
          <SoundCard
            key="patent-ductus-arteriosus"
            url="patent-ductus-arteriosus"
            soundTitle="Patent Ductus Arteriosus"
          />
          <SoundCard
            key="pulmonic-stenosis"
            url="pulmonic-stenosis"
            soundTitle="Pulmonic Stenosis"
          />
          <SoundCard key="s3" url="s3" soundTitle="S3 Heart Sound" />
          <SoundCard key="s4" url="s4" soundTitle="S4 Heart Sound" />
          <SoundCard key="split-s1" url="split-s1" soundTitle="Split S1" />
          <SoundCard key="split-s2" url="split-s2" soundTitle="Split S2" />

          <SoundCard
            key="normal-breath-sounds"
            url="normal-breath-sounds"
            soundTitle="Normal Breath Sounds"
            isBreath
          />
          <SoundCard
            key="transmitted-voice-sounds"
            url="transmitted-voice-sounds"
            soundTitle="Transmitted Voice Sounds"
            isBreath
          />
          <SoundCard
            key="stridor"
            url="stridor"
            soundTitle="Stridor"
            isBreath
          />
          <SoundCard
            key="crackles"
            url="crackles"
            soundTitle="Crackles (Rales)"
            isBreath
          />
          <SoundCard
            key="wheezing-rhonchi"
            url="wheezing-rhonchi"
            soundTitle="Wheezing & Rhonchi"
            isBreath
          />
          <SoundCard
            key="pleural-rub"
            url="pleural-rub"
            soundTitle="Pleural Rub"
            isBreath
          />
          <SoundCard
            key="egophony"
            url="egophony"
            soundTitle="Egophony"
            isBreath
          />
          <SoundCard
            key="bronchophony"
            url="bronchophony"
            soundTitle="Bronchophony"
            isBreath
          />

          <SoundCard
            key="normal-bowel-sounds"
            url="normal-bowel-sounds"
            soundTitle="Normal Bowel Sounds"
            isBowel
          />
          <SoundCard
            key="hypoactive-bowel-sounds"
            url="hypoactive-bowel-sounds"
            soundTitle="Hypoactive Bowel Sounds"
            isBowel
          />
          <SoundCard
            key="hyperactive-bowel-sounds"
            url="hyperactive-bowel-sounds"
            soundTitle="Hyperactive Bowel Sounds"
            isBowel
          />
          <SoundCard
            key="high-pitch-bowel-sounds"
            url="high-pitch-bowel-sounds"
            soundTitle="High-Pitch Bowel Sounds"
            isBowel
          />
        </div>
      </SoundIndexContainer>
    </Fragment>
  );
}
const LungSoundBanner = styled.div`
  max-width: 1300px;
  color: white;
`;
const SoundCategories = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  grid-gap: 25px;
  max-width: 1300px;
  .sound-categories-inner {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    grid-column-end: 3;
    grid-gap: 25px;
    margin-right: 20px;
    .card {
      cursor: pointer;
      padding: 20px;
      background: black;
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      color: white;
      &.systolic {
        background: url("https://s3-us-west-2.amazonaws.com/medzcool/auscultate/systolic-murmur-display.jpg");
        height: 150px;
        background-size: cover;
      }
      &.diastolic {
        background: url("https://s3-us-west-2.amazonaws.com/medzcool/auscultate/diastolic-murmur-display.jpg");
        height: 150px;
        background-size: cover;
      }

      &.extra {
          background: url("https://medzcool.s3-us-west-2.amazonaws.com/auscultate/black-heart-card.jpg");
          height: 150px;
          background-size: cover;
      }
      &.location {
          background: url("https://medzcool.s3-us-west-2.amazonaws.com/auscultate/auscultation-location-card.jpg");
          height: 150px;
          background-size: cover;
      }
      &.lungs {
          background: url("https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Lungs.jpg");
          height: 150px;
          background-size: cover;
      }
      &.bowel {
          background: url("https://medzcool-images.s3.amazonaws.com/bowel-thumb.png");
          height: 150px;
          background-size: cover;
      }
      .new {
          font-size: 14px;
          font-family: ${fontFamily.sansSerif};
      }
      .label {
        font-weight: 700;
        font-size: 26px;
        font-family: ${fontFamily.sansSerif};
      }
    }
  }
  @media(max-width: 768px) {
      display: block;
      margin-top: 50px
      padding: 15px;
      .sound-categories-inner {
          grid-template-columns: 1fr 1fr;
          margin-right: 0;
      }
  }
  @media(max-width: 425px) {
      .sound-categories-inner {
          display: block;
          .card {
              margin-bottom: 20px;
          }
      }
  }
`;
const HomepageContainer = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  color: white;
  padding: 50px;
  .title-container {
    margin-bottom: 50px;
  }
  @media (max-width: 768px) {
    padding: 20px 15px;
    margin-top: 0;
    display: none;
  }
`;
const SidebarContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const SoundIndexContainer = styled.div`
  padding-bottom: 75px;
  display: none;
  ${Title2} {
    color: white;
    padding: 15px 0;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    background: black;
    margin: 0;
    position: fixed;
    width: 100%;
    top: 0;
    .notifications-mobile {
      position: absolute;
      right: 0;
      margin-right: 20px;
      cursor: pointer;
      a {
        color: white;
      }
    }
  }
  @media (max-width: 768px) {
    display: block;
  }
`;
const Notification = styled.div`
  font-family: ${fontFamily.sansSerif};
  width: 100%;
  max-width: 800px;
  .date {
    font-weight: 300;
    margin-bottom: 5px;
    font-size: 12px;
  }
  p,
  li {
    font-size: 14px;
    line-height: 1.5;
  }
  h3 {
    font-size: 16px;
  }
  ${Title2} {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
  }
  @media (max-width: 768px) {
    ${Title2} {
      font-size: 18px;
    }
  }
`;

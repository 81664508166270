import React, {
  useState,
  useContext,
  useEffect,
  lazy,
  Suspense,
  Fragment,
} from "react";
import { useSpring, animated } from "react-spring";
import { MixpanelContext } from "../Context/Context";

// Components
import SoundCard from "../Sounds/SoundCard";
import Spinner from "../Utilities/Spinner";

// Dependencies
import ReactPlayer from "react-player";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily, Button } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeadphones,
  faFilm,
  faTimes,
  faEye,
  faForward,
  faBalanceScaleRight,
  faChevronLeft,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faBalanceScale,
  faPlayCircle,
  faPauseCircle,
} from "@fortawesome/pro-light-svg-icons";

const Compare = React.lazy(() => import("./CompareFeature"));

export default function MediaPlayer(props) {
  const { media, audio, video, visual, audioArray, isBreath } = props;
  const mixpanel = useContext(MixpanelContext);

  const [compareOpen, setCompareOpen] = useState(false);
  function toggleCompare() {
    setCompareOpen(!compareOpen);
  }
  const compareAnimation = useSpring({
    transform: compareOpen ? `translate3d(0, 0, 0)` : `translate3d(100%, 0, 0)`,
    right: compareOpen ? `0px` : `-10px`,
  });

  const [volume, setVolume] = useState(0.5);
  const [playing, setPlaying] = useState(false);
  function handlePlay() {
    if (!playing) {
      mixpanel.track("Auscultate", {
        Location: "Sound Page",
        Action: "Play Sound",
        Label: audioArray[soundPosition].soundDescription,
      });
    }
    setPlaying(!playing);
  }

  const [playerType, setPlayerType] = useState("audio");
  const [activeMedia, setActiveMedia] = useState();
  function changeMedia(mediaType) {
    if (mediaType == "audio") {
      setActiveMedia(audio);
      setPlayerType("audio");
    } else if (mediaType == "video") {
      setActiveMedia(video);
      setPlayerType("video");
    } else if (mediaType == "visual") {
      setActiveMedia(visual);
      setPlayerType("visual");
    }
  }

  const [soundPosition, setSoundPosition] = useState(0);
  useEffect(() => {
    setActiveMedia(audioArray[0].audioURL);
  }, []);

  function nextSound() {
    const nextPosition = soundPosition + 1;
    if (nextPosition > audioArray.length - 1) {
      setActiveMedia(audioArray[0].audioURL);
      setSoundPosition(0);
    } else {
      setActiveMedia(audioArray[nextPosition].audioURL);
      setSoundPosition(soundPosition + 1);
    }
  }

  if (!audioArray) return null;
  return (
    <MediaPlayerContainer>
      {!isBreath && (
        <CompareContainer
          className="compare-container"
          style={compareAnimation}>
          <Suspense fallback={<Spinner />}>
            <Compare toggleCompare={toggleCompare} compareOpen={compareOpen} />
          </Suspense>
        </CompareContainer>
      )}

      <MediaPlayerInner>
        <ReactPlayerContainer playerType={playerType}>
          <StyledFontAwesomeIcon
            icon={faTimes}
            size="2x"
            onClick={() => changeMedia("audio")}
          />
          <StyledReactPlayer
            url={activeMedia}
            volume={volume}
            playing={playing}
            controls={false}
            loop
          />
        </ReactPlayerContainer>

        <ToolbarGrid>
          <div className="toolbar-player">
            {playerType != "audio" ? (
              <Fragment>
                <ControlButton>
                  <StyledFontAwesomeIcon
                    icon={playing ? faPauseCircle : faPlayCircle}
                    size="3x"
                    onClick={handlePlay}
                    className="play-btn"
                  />
                </ControlButton>
                <div>
                  <ControlButton onClick={() => changeMedia("audio")}>
                    <StyledFontAwesomeIcon
                      icon={faChevronLeft}
                      size="lg"
                      active={playerType == "audio" ? "true" : undefined}
                    />
                    <span style={{ marginLeft: "10px" }}>Return to audio</span>
                  </ControlButton>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <ControlButton>
                  <StyledFontAwesomeIcon
                    icon={playing ? faPauseCircle : faPlayCircle}
                    size="3x"
                    onClick={handlePlay}
                    className="play-btn"
                  />
                </ControlButton>
                <div className="sound-description toolbar-text">
                  {audioArray[soundPosition].soundDescription}
                </div>
                <div className="sound-position toolbar-text">
                  {soundPosition + 1} of {audioArray.length}
                </div>
                <ControlButton className="toolbar-control-forward">
                  {audioArray.length > 1 && (
                    <StyledFontAwesomeIcon
                      icon={faForward}
                      size="lg"
                      onClick={() => nextSound()}
                    />
                  )}
                </ControlButton>
              </Fragment>
            )}
          </div>

          <div className="toolbar-options">
            {visual && (
              <ControlButton onClick={() => changeMedia("visual")}>
                <StyledFontAwesomeIcon
                  icon={faEye}
                  size="lg"
                  audio={audio}
                  active={playerType == "visual" ? "true" : undefined}
                />
                <div className="label">Visual</div>
              </ControlButton>
            )}
            {video && (
              <ControlButton onClick={() => changeMedia("video")}>
                <StyledFontAwesomeIcon
                  icon={faFilm}
                  size="lg"
                  audio={audio}
                  active={playerType == "video" ? "true" : undefined}
                />
                <div className="label">Video</div>
              </ControlButton>
            )}
            {!isBreath && (
              <ControlButton
                className="compare"
                onClick={() => toggleCompare()}>
                <StyledFontAwesomeIcon icon={faBalanceScaleRight} size="lg" />
                <div className="label">Compare</div>
              </ControlButton>
            )}
          </div>
        </ToolbarGrid>
      </MediaPlayerInner>
    </MediaPlayerContainer>
  );
}
const CompareContainer = styled(animated.div)`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  color: white;
  background: black;
  font-family: ${fontFamily.sansSerif};
  width: 350px;
  height: calc(100% - 105px);
  z-index: 2;
  margin-top: 10px;
  border-radius: 5px;
  margin-right: 10px;
  @media (max-width: 768px) {
    width: 100%;
    height: calc(100% - 145px);
    margin-top: 0;
    margin-right: 0;
  }
  @media (max-width: 425px) {
    height: calc(100% - 127px);
  }
`;
const SoundCardContainer = styled.div`
  display: grid;
  grid-template-columns: 100px auto auto;
  grid-gap: 25px;
  border-bottom: 1px solid gray;
  .label {
    align-self: center;
    font-weight: 700;
    font-family: futura-pt, sans-serif;
  }
  .icon-block {
    height: 100px;
    display: grid;
    &.black {
      background: black;
    }
    .icon {
      align-self: center;
      color: #d9386b;
      &.heart {
        justify-self: center;
        font-size: 30px;
      }
      &.chevron {
        justify-self: end;
        margin-right: 25px;
      }
    }
  }
  @media (max-width: 425px) {
    grid-template-columns: 75px auto auto;
    .icon-block {
      height: 75px;
    }
    .label {
      font-size: 13px;
    }
  }
`;
const ToolbarGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr auto;
  align-items: center;
  padding: 20px 0;
  margin: 0 50px;
  font-family: ${fontFamily.sansSerif};
  color: white;
  .toolbar-player {
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    grid-gap: 25px;
    align-items: center;
    .sound-position {
      color: gray;
    }
  }
  .toolbar-options {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 50px;
    text-align: center;
  }

  @media (max-width: 768px) {
    grid-template-columns: auto;
    grid-row-gap: 15px;
    margin: 0px;
    padding: 0;
    .toolbar-player {
      grid-template-columns: auto 4fr auto auto;
      grid-gap: 25px;
      background: #252525;
      padding: 10px 25px;
      .toolbar-text {
        font-size: 12px;
      }
    }
  }
  @media (max-width: 425px) {
    .toolbar-player {
      grid-template-columns: auto 4fr auto auto;
      grid-gap: 15px;
      padding: 10px 15px;
    }
  }
`;
const ControlButton = styled.div`
  cursor: pointer;
  .label {
    font-size: 10px;
    text-align: center;
    color: white;
    margin-top: 3px;
  }
  @media (max-width: 435px) {
    font-size: 12px;
  }
`;
const MediaPlayerContainer = styled.div`
  position: fixed;
  width: calc(100% - 250px);
  bottom: 0;
  right: 0;
  z-index: 2;
  @media (max-width: 768px) {
    z-index: 4;
    position: fixed;
    width: 100%;
  }
`;
const MediaPlayerInner = styled.div`
  background: black;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    padding-bottom: 23px;
  }
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: white;
  cursor: pointer;
  ${(props) => props.active && `color:#C23B55`}
`;
const StyledReactPlayer = styled(ReactPlayer)`
  margin: auto;
  width: 100% !important;
  height: 500px !important;
`;
const ReactPlayerContainer = styled.div`
  ${StyledFontAwesomeIcon} {
    position: absolute;
    top: 25px;
    right: 25px;
  }
  ${(props) => props.playerType == "audio" && `display:none;`}
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";
import { SmallText } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

// Data
import { soundData } from "../Static/SoundData";

export default function S3(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Mitral");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>S3 Heart Sound</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>early diastololic heart sound, shortly after S2</li>
          <li>low-frequency heart sound</li>
          <li>best heard at the apex</li>
        </ul>
        <p>
          The S3 heart sound is a low-frequency heart sound that arises from the
          ventricular filling of blood during diastole. It is heard in healthy
          young children and adults. However, it is considered abnormal in
          people older than 40 years of age, which would suggest an enlarged
          ventricular chamber.
        </p>
        <ImgContainer>
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/S3-graphic.png"
            alt="S3 heart sound"
            className="first"
          />
        </ImgContainer>
        <p>
          Because of its low-frequency sound, it is best heard with the bell of
          the stethoscope over the cardiac apex. It has the cadence of the word
          Kentucky (ken-tu-key).
        </p>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/mitral-stenosis-location.jpg"
            alt="cardiac apex"
            className="first thoraxis"
            onClick={thoraxis}
          />
          <div className="expand-icon" onClick={thoraxis}>
            <span>Expand</span>
            <FontAwesomeIcon icon={faExpand} className="icon"/>
          </div>
        </ImgContainer>
        <p>The S3 heart sound can also be heard along side an S4 heart sound, especially in cases of heart failure. As a results, this finding of an S3 and S4 gallop is typically abnormal.</p>
      </Intro>
      <P1>
        <h2>Causes</h2>
        <p>Normal in young healthy adults, children, and pregancy.</p>
        <p>
          Abnormal in older adults, suggesting and enlarged ventricular chamber:
        </p>
        <ul>
          <li>Cardiomyopathy</li>
          <li>Systolic heart failure</li>
        </ul>
      </P1>
      <P1>
        <h2>
          Clinical Correlation
          <SmallText>Medical Multiple choices questions.</SmallText>
        </h2>

        <CaseCard
          caseId="nXe0O3dx"
          caseTitle="Shortness of Breath"
        />
      </P1>
    </SoundDetailsContainer>
  );
  if (props.stethos) {
    return soundBody;
  }

  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(sound => sound.id == "s3");
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="S3 Heart Sound"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/s3-heart-sound.mp3"
        visual="https://vimeo.com/336237331/ee58d3c80f"
        video="https://vimeo.com/336237490/3e652fbae5"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  width: max-content;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    cursor: pointer;
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .expand-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    color: white;
    opacity: 0.5;
    .icon {
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

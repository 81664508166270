import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";
import { SmallText } from "../Utilities";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

// Data
import { soundData } from "../Static/SoundData";

export default function AorticRegurgitation(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Erb");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Aortic Regurgitation</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>high-pitched</li>
          <li>early diastolic, decrescendo murmur</li>
          <li>left parasternal border</li>
        </ul>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/aortic-regurgitation-graphic.png"
            alt="aortic regurgitation"
            className="second"
          />
        </ImgContainer>
        <p>
          Aortic Regurgitation, (AR, also called aortic insufficiency) is caused
          by flow of blood across the aortic valve. The murmur occurs when there
          is inadequate closure of the aortic valve leaflets because of the
          dilation of the aortic root.
        </p>
        <p>
          This murmur is often described as a high-pitched early diastolic,
          decrescendo murmur (decrescendo murmurs grow softer). It is best heard
          along the left parasternal border, at the third and fourth intercostal
          space.
        </p>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/aortic-regurgitation-location.jpg"
            alt="aortic regurgitation"
            className="first thoraxis"
            onClick={thoraxis}
          />
          <div className="expand-icon" onClick={thoraxis}>
            <span>Expand</span>
            <FontAwesomeIcon icon={faExpand} className="icon" />
          </div>
        </ImgContainer>
      </Intro>
      <P1>
        <div>
          <h2>Causes</h2>
          <p>
            In the developing world, the most common cause of AR is rheumatic
            heart disease. In developed countries, aortic root dilation,
            congenital bicuspid aortic valve, and calcific valve disease are
            more common.
          </p>
          <p>Other causes of AR include:</p>
          <ul>
            <li>Endocarditits</li>
            <li>
              Systemic rheumatic disorders (e.g. SLE, giant cell arteritis,
              Ankylosing spondylitis, etc.)
            </li>
            <li>
              Genetic syndromes (e.g. Marfan Syndome, Ehlers-Danlos syndrome,
              Osteogenesis imperfecta, etc.)
            </li>
          </ul>
        </div>
      </P1>
      <P1>
        <h2>
          Clinical Correlation
          <SmallText>Medical Multiple choices questions.</SmallText>
        </h2>

        <CaseCard
          caseId="jnegp9aw"
          caseTitle="Respiratory distress after NSTEMI "
        />
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "aortic-regurgitation"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Aortic Regurgitation"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/aortic-regurgitation.mp3"
        visual="https://vimeo.com/332489703/2da97eeac3"
        video="https://vimeo.com/341119826/6068591835"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  width: max-content;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    cursor: pointer;
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .expand-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    color: white;
    opacity: 0.5;
    .icon {
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  font-family: futura-pt, sans-serif;
  ul {
  }

  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;

import React, { useContext } from "react";
import { MixpanelContext } from "../Context/Context";

// Dependencies
import { Link } from "react-router-dom";

// Components
import SoundSidebar from "./SoundSidebar";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily } from "../Utilities";
import Spinner from "../Utilities/Spinner";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStethoscope } from "@fortawesome/pro-light-svg-icons";
import {
  faHome,
  faGraduationCap,
  faChalkboardTeacher,
} from "@fortawesome/pro-solid-svg-icons";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      profile {
        id
        subscribedApps {
          id
          title
          description
          url
          logo
        }
      }
    }
  }
`;

export default function Sidebar(props) {
  const mixpanel = useContext(MixpanelContext);

  return (
    <Query query={GET_USER}>
      {({ loading, data }) => {
        if (loading) return <Spinner />;
        const user = data.user;
        return (
          <SidebarContainer>
            <StyledLink to="/">
              <Logo>
                <Subheading>
                  <FontAwesomeIcon icon={faStethoscope} />{" "}
                  <span>AUSCULTATE</span>
                </Subheading>
              </Logo>
            </StyledLink>

            <SidebarInnerContainer>
              <Subheading>
                <StyledLink
                  to="/"
                  onClick={() => {
                    mixpanel.track("Auscultate", {
                      Location: "Sidebar",
                      Label: "Home",
                    });
                  }}>
                  <div className="icon">
                    <FontAwesomeIcon icon={faHome} />
                  </div>
                  <span>Home</span>
                </StyledLink>
              </Subheading>

              <Subheading>
                <StyledLink
                  to="/quiz/home"
                  onClick={() => {
                    mixpanel.track("Auscultate", {
                      Location: "Sidebar",
                      Label: "Quizzes",
                    });
                  }}>
                  <div className="icon">
                    <FontAwesomeIcon icon={faGraduationCap} />
                  </div>
                  <span>Quizzes</span>
                </StyledLink>
              </Subheading>

              <Subheading>Heart Sounds</Subheading>
              <SoundSidebar
                key="normal-heart-sound"
                url="normal-heart-sound"
                soundTitle="Normal Heart Sound"
              />
              <SoundSidebar
                key="aortic-regurgitation"
                url="aortic-regurgitation"
                soundTitle="Aortic Regurgitation"
              />
              <SoundSidebar
                key="aortic-stenosis"
                url="aortic-stenosis"
                soundTitle="Aortic Stenosis"
              />
              <SoundSidebar
                key="atrial-septal-defect"
                url="atrial-septal-defect"
                soundTitle="Atrial Septal Defect"
              />
              <SoundSidebar
                key="coarctation"
                url="coarctation"
                soundTitle="Coarctation of the Aorta"
              />
              <SoundSidebar
                key="holosystolic-murmur"
                url="holosystolic-murmur"
                soundTitle="Holosystolic Murmur"
              />
              <SoundSidebar
                key="hypertrophic-cardiomyopathy"
                url="hypertrophic-cardiomyopathy"
                soundTitle="Hypertrophic Cardiomyopathy"
              />
              <SoundSidebar
                key="innocent-murmur"
                url="innocent-murmur"
                soundTitle="Innocent Murmur"
              />
              <SoundSidebar
                key="mitral-stenosis"
                url="mitral-stenosis"
                soundTitle="Mitral Stenosis"
              />
              <SoundSidebar
                key="mitral-valve-prolapse"
                url="mitral-valve-prolapse"
                soundTitle="Mitral Valve Prolapse"
              />
              <SoundSidebar
                key="patent-ductus-arteriosus"
                url="patent-ductus-arteriosus"
                soundTitle="Patent Ductus Arteriosus"
              />
              <SoundSidebar
                key="pericardial-friction-rub"
                url="pericardial-friction-rub"
                soundTitle="Pericardial Friction Rub"
              />
              <SoundSidebar
                key="pulmonic-stenosis"
                url="pulmonic-stenosis"
                soundTitle="Pulmonic Stenosis"
              />
              <SoundSidebar key="s3" url="s3" soundTitle="S3 Heart Sound" />
              <SoundSidebar key="s4" url="s4" soundTitle="S4 Heart Sound" />
              <SoundSidebar
                key="split-s1"
                url="split-s1"
                soundTitle="Split S1"
              />
              <SoundSidebar
                key="split-s2"
                url="split-s2"
                soundTitle="Split S2"
              />

              <Subheading
                style={{
                  marginTop: "50px",
                }}>
                Lung Sounds
              </Subheading>
              <SoundSidebar
                key="normal-breath-sounds"
                url="normal-breath-sounds"
                soundTitle="Normal Breath Sounds"
                isBreath
              />
              <SoundSidebar
                key="stridor"
                url="stridor"
                soundTitle="Stridor"
                isBreath
              />
              <SoundSidebar
                key="crackles"
                url="crackles"
                soundTitle="Crackles (Rales)"
                isBreath
              />
              <SoundSidebar
                key="wheezing-rhonchi"
                url="wheezing-rhonchi"
                soundTitle="Wheezing & Rhonchi"
                isBreath
              />
              <SoundSidebar
                key="pleural-rub"
                url="pleural-rub"
                soundTitle="Pleural Rub"
                isBreath
              />
              <SoundSidebar
                key="transmitted-voice-sounds"
                url="transmitted-voice-sounds"
                soundTitle="Transmitted Voice Sounds"
                isBreath
              />
              <SoundSidebar
                key="egophony"
                url="egophony"
                soundTitle="Egophony"
                isBreath
              />
              <SoundSidebar
                key="bronchophony"
                url="bronchophony"
                soundTitle="Bronchophony"
                isBreath
              />

              <Subheading
                style={{
                  marginTop: "50px",
                }}>
                Bowel Sounds
              </Subheading>
              <SoundSidebar
                key="normal-bowel-sounds"
                url="normal-bowel-sounds"
                soundTitle="Normal Bowel Sounds"
                isBowel
              />
              <SoundSidebar
                key="hypoactive-bowel-sounds"
                url="hypoactive-bowel-sounds"
                soundTitle="Hypoactive Bowel Sounds"
                isBowel
              />
              <SoundSidebar
                key="hyperactive-bowel-sounds"
                url="hyperactive-bowel-sounds"
                soundTitle="Hyperactive Bowel Sounds"
                isBowel
              />
              <SoundSidebar
                key="high-pitch-bowel-sounds"
                url="high-pitch-bowel-sounds"
                soundTitle="High-Pitch Bowel Sounds"
                isBowel
              />
            </SidebarInnerContainer>
          </SidebarContainer>
        );
      }}
    </Query>
  );
}

const SidebarContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  background: ${grayscale.dark};
  height: 100%;
  position: fixed;
  width: 250px;
  z-index: 2;
  top: 0;
  left: 0;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    background: transparent;
    width: 0;
  }
`;
const Logo = styled.div`
  padding: 0 25px;
  h3 {
    font-size: 18px;
    font-weight: 300;
  }
  span {
    margin-left: 10px;
  }
`;
const SidebarInnerContainer = styled.div`
  padding: 0 25px 25px 25px;
`;
const Subheading = styled.h3`
  color: ${grayscale.white};
  font-size: 18px;
  font-family: ${fontFamily.sansSerif};
  font-weight: 700;
  margin-bottom: 25px;
  &.section {
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 14px;
  }
  a {
    color: ${grayscale.white};
    text-decoration: none;
  }
  span {
    margin-left: 10px;
  }
  .icon {
    font-size: 14px;
    display: inline-block;
  }
`;
const StyledLink = styled(Link)`
  color: ${grayscale.white};
  text-decoration: none;
`;

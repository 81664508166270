import React from "react";

// Components
import SoundCard from "./SoundCard";

// Styles
import styled from "styled-components";
import { Title2 } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export default function ExtraSounds(props) {
  function handleBack() {
    props.history.goBack();
  }
  return (
    <SoundIndexContainer>
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="back-icon"
        onClick={handleBack}
      />
      <div className="soundcard-container">
        <Title2>Splitting</Title2>
        <SoundCard key="split-s1" url="split-s1" soundTitle="Split S1" />
        <SoundCard key="split-s2" url="split-s2" soundTitle="Split S2" />
        <Title2>Extra Systolic Sounds</Title2>
        <SoundCard
          key="mitral-valve-prolapse"
          url="mitral-valve-prolapse"
          soundTitle="Systolic Click - Mitral Valve Prolapse"
        />

        <Title2>Extra Diastolic Sounds</Title2>
        <SoundCard key="s3" url="s3" soundTitle="S3 Heart Sound" />
        <SoundCard key="S4" url="S4" soundTitle="S4 Heart Sound" />
      </div>
    </SoundIndexContainer>
  );
}
const SoundIndexContainer = styled.div`
  padding-bottom: 75px;
  .back-icon {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 15px;
    color: white;
    display: none;
  }
  .soundcard-container {
    margin-top: 100px;
  }
  ${Title2} {
    color: white;
    padding: 15px 0;
    font-weight: 700;
    margin: 25px;
  }
  @media (max-width: 768px) {
    .back-icon {
      display: block;
    }
    .soundcard-container {
      margin-top: 0;
    }
    ${Title2} {
      text-align: center;
    }
  }
`;

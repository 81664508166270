import React from "react";

// Dependencies
import { Link } from "react-router-dom";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProcedures, faChevronRight } from "@fortawesome/pro-light-svg-icons";

// Styles
import styled from "styled-components";

export default function CaseCard(props) {
  const { url, caseTitle, caseId } = props;
  if (caseId) {
      return (
          <StyledRouterLink to={`/case/${caseId}`} >
            <SoundCardContainer>
              <div className="icon-block black">
                <div className="icon heart">
                  <FontAwesomeIcon icon={faProcedures} />
                </div>
              </div>
              <div className="label">{caseTitle}</div>
              <div className="icon-block">
                <div className="icon chevron">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </SoundCardContainer>
          </StyledRouterLink>
      )
  }
  return (
    <StyledLink href={url} target="_blank">
      <SoundCardContainer>
        <div className="icon-block black">
          <div className="icon heart">
            <FontAwesomeIcon icon={faProcedures} />
          </div>
        </div>
        <div className="label">{caseTitle}</div>
        <div className="icon-block">
          <div className="icon chevron">
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </SoundCardContainer>
    </StyledLink>
  );
}
const SoundCardContainer = styled.div`
  display: grid;
  grid-template-columns: 100px auto auto;
  grid-gap: 25px;
  border-bottom: 1px solid gray;
  .label {
    align-self: center;
    font-weight: 700;
    font-family: futura-pt, sans-serif;
  }
  .icon-block {
    height: 100px;
    display: grid;
    &.black {
      background: black;
    }
    .icon {
      align-self: center;
      color: #d9386b;
      &.heart {
        justify-self: center;
        font-size: 30px;
      }
      &.chevron {
        justify-self: end;
        margin-right: 25px;
      }
    }
  }
  @media (max-width: 425px) {
    grid-template-columns: 75px auto auto;
    .icon-block {
      height: 75px;
    }
    .label {
      font-size: 13px;
    }
  }
`;
const StyledLink = styled.a`
  color: white;
  text-decoration: none;
`;
const StyledRouterLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

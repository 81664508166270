import React, { Component } from "react";

// Sounds
import NormalHeartSound from "../Sounds/NormalHeartSound";
import AorticRegurgitation from "../Sounds/AorticRegurgitation";
import AorticStenosis from "../Sounds/AorticStenosis";
import Holosystolic from "../Sounds/Holosystolic";
import MitralStenosis from "../Sounds/MitralStenosis";
import MitralValveProlapse from "../Sounds/MitralValveProlapse";
import PulmonicStenosis from "../Sounds/PulmonicStenosis";
import HCM from "../Sounds/HCM";
import S3 from "../Sounds/S3";
import S4 from "../Sounds/S4";
import ASD from "../Sounds/ASD";
import Innocent from "../Sounds/Innocent";
import SplitS1 from "../Sounds/SplitS1";
import SplitS2 from "../Sounds/SplitS2";

// Styles
import styled from "styled-components";

export default function SoundDetailsSwitch(props) {
  let soundComponent;
  let soundTitle;
  switch (props.activeSoundObject.id) {
    case "normal-heart-sound":
      soundComponent = <NormalHeartSound stethos />;
      soundTitle = "Normal Heart Sound";
      break;
    case "s4":
      soundComponent = <S4 stethos />;
      soundTitle = "S4 Heart Sound";
      break;
    case "s3":
      soundComponent = <S3 stethos />;
      soundTitle = "S3 Heart Sound";
      break;
    case "aortic-regurgitation":
      soundComponent = <AorticRegurgitation stethos />;
      soundTitle = "Aortic Regurgitation";
      break;
    case "aortic-stenosis":
      soundComponent = <AorticStenosis stethos />;
      soundTitle = "Aortic Stenosis";
      break;
    case "atrial-septal-defect":
      soundComponent = <ASD stethos />;
      soundTitle = "Atrial Septal Defect";
      break;
    case "holosystolic-murmur":
      soundComponent = <Holosystolic stethos />;
      soundTitle = "Holosystolic Murmur";
      break;
    case "hypertrophic-cardiomyopathy":
      soundComponent = <HCM stethos />;
      soundTitle = "Hypertrophic Cardiomyopathy";
      break;
    case "innocent-murmur":
      soundComponent = <Innocent stethos />;
      soundTitle = "Innocent Murmur";
      break;
    case "mitral-stenosis":
      soundComponent = <MitralStenosis stethos />;
      soundTitle = "Mitral Stenosis";
      break;
    case "mitral-valve-prolapse":
      soundComponent = <MitralValveProlapse stethos />;
      soundTitle = "Mitral Valve Prolapse";
      break;
    case "pulmonic-stenosis":
      soundComponent = <PulmonicStenosis stethos />;
      soundTitle = "Pulmonic Stenosis";
      break;
    case "split-s1":
      soundComponent = <SplitS1 stethos />;
      soundTitle = "Split S1";
      break;
    case "split-s2":
      soundComponent = <SplitS2 stethos />;
      soundTitle = "Split S2";
      break;
  }
  return (
    <div>
      <SoundTitle>{soundTitle}</SoundTitle>
      {soundComponent}
    </div>
  );
}

const SoundTitle = styled.h1`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  @media (max-width: 425px) {
    font-size: 26px;
    text-align: center;
  }
`;

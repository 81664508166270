import React, { useState } from "react";

// Components
import DrawerRight from "../Header/DrawerRight";

// Dependencies
import { withRouter } from "react-router-dom";

// Style
import styled from "styled-components";
import { grayscale } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHome,
  faSearch,
  faGraduationCap
} from "@fortawesome/pro-regular-svg-icons";

function TabNavigator(props) {
  const [drawerRight, setDrawerRight] = useState(false);
  function clickAvatar() {
    setDrawerRight(true);
  }
  function closeDrawer() {
    setDrawerRight(false);
  }
  return (
    <TabNavigatorConatiner>
      <TabNavigatorInner>
        <FontAwesomeIcon
          icon={faHome}
          size="lg"
          onClick={() => props.history.push("/")}
        />
        <FontAwesomeIcon
          icon={faGraduationCap}
          size="lg"
          onClick={() => props.history.push("/quiz/home")}
        />
        <FontAwesomeIcon
          icon={faSearch}
          size="lg"
          onClick={() => props.history.push("/search")}
        />
        <FontAwesomeIcon icon={faUser} size="lg" onClick={clickAvatar}/>
      </TabNavigatorInner>
      <DrawerRight drawerRight={drawerRight} closeDrawer={closeDrawer} />
    </TabNavigatorConatiner>
  );
}
export default withRouter(TabNavigator);

const TabNavigatorConatiner = styled.div`
  padding: 18px 0;
  background: ${grayscale.white};
  width: 101%;
  position: fixed;
  bottom: -3px;
  z-index: 3;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const TabNavigatorInner = styled.div`
  height: 40px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-items: center;
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";

// Data
import { soundData } from "../Static/SoundData";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

export default function MitralValveProlapse(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Mitral");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Mitral Valve Prolapse</h1>
      </TitleBlock>
      <Intro>
        <p>
          The sound of Mitral Valve Prolapse a mid-systolic click followed by a
          late systolic murmur.
        </p>
        <ImgContainer>
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mvp-murmur-graphic-1.png"
            alt="Mitral Valve Prolapse"
            className="first"
          />
        </ImgContainer>
        <p>
          The murmur is heard best at the apex with the patient lying in a left
          lateral decubitus position. The murmur is a crescendo murmur and is
          loudest just before S2.
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/mitral-stenosis-location.jpg"
              alt="mitral stenosis"
              className="second thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
      </Intro>
      <P1>
        <h2>Causes</h2>
        <p>
          Mitral valve prolapse is often caused by myxomatous degeneration of
          the mitral valve leaflets. This can be seen in connective tissue
          diseases such as Marfan syndrome and Elhers-Danlos syndrome, but can
          also be seen in:
        </p>
        <ul>
          <li>rheumatic heart disease</li>
          <li>chordae rupture</li>
        </ul>
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "mitral-valve-prolapse"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;
  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Mitral Valve Prolapse"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mitral-valve-prolapse.mp3"
        visual="https://vimeo.com/336282202/9d8b573982"
        video="https://vimeo.com/336282139/0d8654e2d4"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .first {
    max-width: 600px;
    width: 100%;
    margin: 15px 0;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis-container {
    position: relative;
    width: max-content;
    margin: auto;
    .thoraxis {
      cursor: pointer;
      box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
    .expand-icon {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 15px;
      color: white;
      opacity: 0.5;
      .icon {
        vertical-align: middle;
      }
      span {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .thoraxis-container {
      width: 100%;
    }
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

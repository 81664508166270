import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
  SmallText
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";

// Data
import { soundData } from "../Static/SoundData";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

export default function PulmonicStenosis(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Pulmonic");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Pulmonic Stenosis</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>
            mid-systolic, crescendo-decrescendo murmur, preceded by an ejection
            click
          </li>
          <li>
            best heard at the left parasternal border at the 2nd intercostal
            space
          </li>
          <li>radiation to left shoulder/neck</li>
        </ul>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pulmonic-stenosis-graphic.png"
            alt="Pulmonary Stenosis"
            className="first"
          />
        </ImgContainer>
        <p>
          The murmur of pulmonic stenosis is a systolic ejection murmur. It has
          a medium pitch and often having a harsh quality. An early pulmonic
          ejection click is common.
        </p>

        <p>Sometimes you can also hear a wide splitting of S2. This is caused by a prolonged pulmonic ejection leading to a delayed P2 sound.</p>

        <p>
          It is best heard at the left parasternal border at the second
          intercostal space, and often radiates towards the left shoulder and
          left neck.
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/location-left-second-ics.jpg"
              alt="pulmonic stenosis"
              className="first thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
      </Intro>
      <P1>
        <div>
          <h2>Causes</h2>
          <p>
            Is usually found in children secondary to a congenital disorder,
            usually associated with a benign clinical course and high rate of
            survival to adulthood.
          </p>
        </div>
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "pulmonic-stenosis"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Pulmonic Stenosis"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic audioArray={audioArray} />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis-container {
    position: relative;
    width: max-content;
    margin: auto;
    .thoraxis {
      cursor: pointer;
      box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
    .expand-icon {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 15px;
      color: white;
      opacity: 0.5;
      .icon {
        vertical-align: middle;
      }
      span {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .thoraxis-container {
      width: 100%;
    }
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

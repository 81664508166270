export const themeColors = {
  black: "#000000",
  dark: "#1D1D1D",
  gray: "#898989",
  lightGray: "#E1E1E1"
};

export const grayscale = {
  darkDark: "#333333",
  lightDark: "#999999",
  gray: "#D8D8D8",
  white: "white",
  lightGray: "#F2F2F2",
  offWhite: "#FAFAFA",
  black: "#000000",
  dark: "#131313",
  darkGray: "#1D1D1D",
  gray: "#3F3F3F",
  lightGray: "#D8D8D8",
  white: "#FDFDFD"
};

export const purple = {
  darkDark: "#16111C",
  darkLight: "#3F2064",
  bright: "#5143BD",
  dull: "#565064",
  lightDark: "#C9C1E0",
  lightWhite: "#FAF8FE"
};

export const blue = {
  bright: "#295AEC",
  neutral: "#3b5998"
};

export const green = {
  dark: "#008C82",
  bright: "#5ED1A5"
};

export const red = {
  dark: "#D23D56",
  bright: "#F0445D",
  pink: "#fd2c9b"
};

export const yellow = {
  dark: "#E67E22",
  light: "#ffc820"
};

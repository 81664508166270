import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  pink,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
  StyledLink
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import HeaderMobile from "../Header/HeaderMobile";
import { Link } from "react-router-dom";

// Data
import { soundData } from "../Static/SoundData";

export default function NormalHeartSound(props) {
  const user = props.user;

  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Normal Heart Sound</h1>
      </TitleBlock>
      <Intro>
        <p>
          The classic normal heart sound consists of an S1 and an S2 and is
          heard throughout the precordium. However, the relative intensities of
          S1 and S2 vary depending on the location of where you are
          auscultating.
        </p>
      </Intro>

      <P2>
        <div className="img-container">
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-heart-sound-graphic-apex.png"
            alt="normal heart sound"
          />
        </div>
        <div className="center">
          <p>S1 is usually louder than S2 at the apex</p>
        </div>
      </P2>

      <P2>
        <div className="img-container">
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-heart-sound-graphic-base.png"
            alt="normal heart sound"
          />
        </div>
        <div className="center">
          <p>S2 is usually louder than S1 at the base.</p>
        </div>
      </P2>

      <P1>
        <div>
          <h2>First Heart Sound - S1</h2>
          <p>
            The first heart sound (S1) is the result of the mitral and tricuspid
            valves closing. Each of these valves creates their sound when
            closing (mitral valve = M1 and tricuspid valve = T1). However, they
            are perceived as one sound (S1), because these valves close almost
            simultaneously.
          </p>
        </div>
        <div className="img-container">
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/tricuspid-mitral-highlighted.png"
            alt="s1 heart sound"
          />
        </div>
      </P1>

      <P1>
        <div>
          <p>
            As mentioned above, there can be variations in the intensity of the S1
            heart sound depending on where you are auscultating on the chest
            wall. Although not all intensity variations are abnormal, there are
            some cases which should prompt you to consider certain pathologies.
            These include:
          </p>
          <div>
            <ul>
              <li>
                Exaggerated S1 Heart Sounds - seen in hyperdynamic states such
                as in sepsis or infection and tachycardia
              </li>
              <li>
                Diminished S1 Heart Sounds
                <ul>
                  <li>first degree heart block</li>
                  <li>calcified mitral valves</li>
                  <li>heart failure</li>
                  <li>coronary heart disease</li>
                </ul>
              </li>
              <li>
                Dynamic S1 Heart Sound Variations - where the intensity of
                the S1 heart sounds varies with each beat.
              </li>
              <ul>
                <li>Atrial Fibrillation</li>
                <li>Complete Heart Block</li>
              </ul>
            </ul>
          </div>
          <p>
            Variations in the components of S1 into M1 and T1 can also occur,
            making the S1 heart sound split into two parts, this is called a{" "}
            <Link to="/sound/split-s1">split S1 heart sound</Link>.
          </p>
        </div>
      </P1>

      <P1>
        <div>
          <h2>Second Heart Sound - S2</h2>
          <p>
            The second heart sound is the result of the aortic and pulmonic
            valves closing. Like the S1 sound, S2 is produced by the
            simultaneous closure of both the aortic valve (termed A2) and the
            pulmonic valve (termed P2).
          </p>
        </div>
        <div className="img-container">
          <img
            src="https://medzcool-images.s3.amazonaws.com/aortic-pulmonic-valves-labeled.png"
            alt="s2 heart sound"
          />
        </div>
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "normal-heart-sound"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;
  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Normal Heart Sound"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>

      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-heart-sound.mp3"
        visual="https://vimeo.com/335737513/2e7b68b0d6"
        video="https://vimeo.com/336162572/c0a4244a14"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  img {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  display: block;
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  .img-container {
    text-align: center;
  }
  a {
    color: white;
  }
`;

const P2 = styled(SoundDetailsBlock)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: center
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    width: 100%
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align:center;
    }
    .center {
        text-align: center;
    }
  }

`;

import React, { useState, Fragment } from "react";

// Components
import SearchBarMobile from "../Search/SearchBarMobile";
import DrawerRight from "./DrawerRight";

// Styles
import styled from "styled-components";
import { grayscale } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

export default function HeaderMobile(props) {
  const [drawerRight, setDrawerRight] = useState(false);
  function clickAvatar() {
    setDrawerRight(true);
  }
  function closeDrawer() {
    setDrawerRight(false);
  }
  const { appView, headerTitle } = props;
  return (
    <HeaderContainer>
      <HeaderInnerContainer>
        {appView == "soundDetails" && (
          <Fragment>
            <ChevronBack
              icon={faChevronLeft}
              color={"#C23B55"}
              onClick={() => props.history.goBack()}
            />
            <HeaderTitle>{headerTitle}</HeaderTitle>
          </Fragment>
        )}
        {appView == "search/home" && (
          <SearchBarMobile
            changeSearchWord={props.changeSearchWord}
            searchWord={props.searchWord}
          />
        )}
      </HeaderInnerContainer>
    </HeaderContainer>
  );
}
const HeaderContainer = styled.div`
  position: relative;
  height: 50px;
  display: none;
  @media (max-width: 768px) {
    display: block;
    height: 0;
  }
`;
const HeaderInnerContainer = styled.div`
  background: ${grayscale.dark};
  height: 50px;
  padding: 5px 0;
  position: fixed;
  width: 100%;
  z-index: 1;
  display: grid;
  align-items: center;
  justify-items: center;
  top: 0;
  left: 0;
`;
const HeaderTitle = styled.div`
  color: ${grayscale.white};
  font-size: 16px;
  font-weight: 500;
  font-family: futura-pt, sans-serif;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${grayscale.white};
  padding: 15px;
  ${props => props.color && `color:${props.color}`}
  ${props => props.chevronBack && `position: absolute; left: 5px;`}
`;
const ChevronBack = styled(StyledFontAwesomeIcon)`
  color: ${grayscale.white};
  padding: 15px;
  position: absolute;
  left: 5px;
  cursor: pointer;
  ${props => props.color && `color:${props.color}`}
`;

export const soundData = {
  data: {
    heartSounds: [
      {
        id: "aortic-regurgitation",
        title: "Aortic Regurgitation",
        audio: [
          {
            soundDescription: "Early diastolic, decrescendo murmur",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/aortic-regurg-early-dias.mp3",
          },
        ],
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/aortic-regurgitation-graphic.png",
      },
      {
        id: "aortic-stenosis",
        title: "Aortic Stenosis",
        audio: [
          {
            soundDescription:
              "Crescendo-decrescendo systolic murmur, S2 present",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-s2.mp3",
          },
          {
            soundDescription:
              "Harsh Systolic Murmur systolic ejection murmur, absent S2 (severe aortic stenosis)",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/severe-as.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-graphic.png",
      },
      {
        id: "atrial-septal-defect",
        title: "Atrial Septal Defect",
        audio: [
          {
            soundDescription:
              "Systolic ejection murmur with persistent split s2",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-sys-mur.mp3",
          },
          {
            soundDescription:
              "Systolic ejection murmur, persistent split s2, low pitched diastolic murmur",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-diastolic-murmur.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-graphic.png",
      },
      {
        id: "coarctation",
        title: "Coaractation of the Aorta",
        audio: [
          {
            soundDescription:
              "Coarctation with systolic ejection click & continuation murmur",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/coarctation+(w+systolic+ej+click).mp3",
          },
          {
            soundDescription:
              "Coarctation with continuation murmur & intensified S2",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/coarctation+(intense+S2%2C+systolic+murmur%2C+diastolic+murmur).mp3",
          },
          {
            soundDescription:
              "Coarctation with continuation murmur & normal S1 and S2",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/coaractation+(normal+S1S2%2C+sys+mur%2C+dias+mur).mp3",
          },
        ],
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/CoA-graphic.png",
      },
      {
        id: "holosystolic-murmur",
        title: "Holosystolic Murmur",
        audio: [
          {
            soundDescription: "blowing quality, systolic murmur",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/holosys.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/holosystolic-graphic.png",
      },
      {
        id: "hypertrophic-cardiomyopathy",
        title: "Hypertrophic Cardiomyopathy",
        audio: [
          {
            soundDescription:
              "Crescendo-decrescendo systolic murmur, squatting",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/HOCM+standing+to+squatting+(decreased).mp3",
          },
          {
            soundDescription: "Crescendo-decrescendo systolic murmur, Valsalva",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/HOCM+with+Valsalva+(increased).mp3",
          },
        ],
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/HOCM-graphic.png",
      },
      {
        id: "innocent-murmur",
        title: "Innocent Murmur",
        audio: [
          {
            soundDescription: "soft, rumbling, systolic murmur",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-short.mp3",
          },
        ],
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-murmur-graphic.png",
      },
      {
        id: "mitral-stenosis",
        title: "Mitral Stenosis",
        audio: [
          {
            soundDescription:
              "Opening snap & decrescendo-crescendo diastolic murmur",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/os-diastolic-mumur.mp3",
          },
          {
            soundDescription: "Opening snap",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Opening+Snap.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mitral-stenosis-murmur-graphic.png",
      },
      {
        id: "mitral-valve-prolapse",
        title: "Mitral Valve Prolapse",
        audio: [
          {
            soundDescription: "Mid-Systolic click & late systolic murmur",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/systolic-click-late-murmur.mp3",
          },
          {
            soundDescription: "Mid-Systolic click, without murmur",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/mid-systolic-click.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mvp-murmur-graphic-1.png",
      },
      {
        id: "patent-ductus-arteriosus",
        title: "Patent Ductus Arteriosus",
        audio: [
          {
            soundDescription: "Continuous, machine-like murmur",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Patent+Ductus+Arteriosus.mp3",
          },
        ],
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/PDA-graphic.png",
      },
      {
        id: "pericardial-friction-rub",
        title: "Pericardial Friction Rub",
        audio: [
          {
            soundDescription:
              "Rubbing, scratchy sound heart sounds & murmur (Leaning Forward)",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Pericardial+Friction+Rub+(Leaning+Forward).mp3",
          },
          {
            soundDescription:
              "Muffled, Rubbing, scratchy sound heart sounds & murmur (Supine)",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Pericardial+Friction+Rub+(Supine).mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-graphic.png",
      },
      {
        id: "pulmonic-stenosis",
        title: "Pulmonic Stenosis",
        audio: [
          {
            soundDescription:
              "Mid-Systolic, Crescendo-decrescendo murmur, preceded by an ejection click",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pulmonic-stenosis-ejection-click.mp3",
          },
          {
            soundDescription:
              "Mid-Systolic, Crescendo-decrescendo murmur, preceded by an ejection click, split S2",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pulmonary+Stenosis+with+split+s2.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-graphic.png",
      },
      {
        id: "split-s1",
        title: "Split S1",
        audio: [
          {
            soundDescription: "Split S1",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s1.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-S1-sound.png",
      },
      {
        id: "split-s2",
        title: "Split S2",
        audio: [
          {
            soundDescription: "Persistent Split S2",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s2-persistent.mp3",
          },
          {
            soundDescription:
              "Transient Split S2, variations with respirations",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s2-transient.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s2.png",
      },
      {
        id: "s3",
        title: "S3 Heart Sound",
        audio: [
          {
            soundDescription: "S3 Heart Sound",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/s3.mp3",
          },
          {
            soundDescription: "S3, S4 Gallop",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/s3+s4+gallop.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/S3.png",
      },
      {
        id: "s4",
        title: "S4 Heart Sound",
        audio: [
          {
            soundDescription: "S4 Heart Sound",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/s4.mp3",
          },
          {
            soundDescription: "S3, S4 Gallop",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/s3+s4+gallop.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/S4-graphic.png",
      },
      {
        id: "normal-heart-sound",
        title: "Normal Heart Sound",
        audio: [
          {
            soundDescription: "Normal S1 S2, at Apex",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-s1-s2-apex.mp3",
          },
          {
            soundDescription: "Normal S1 S2, at Base",
            audioURL:
              "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-s1-s2-base.mp3",
          },
        ],
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-sound-graphic-1.png",
      },
    ],
    breathSounds: [
      {
        id: "stridor",
        title: "Stridor",
        audio: [
          {
            soundDescription: "Stridor",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/stridor.mp3",
          },
        ],
      },
      {
        id: "crackles",
        title: "Crackles (Rales)",
        audio: [
          {
            soundDescription: "Fine Crackles (Rales)",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Fine+Crackles.mp3",
          },
          {
            soundDescription: "Coarse Crackles (Rales)",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Coarse+Crackles.mp3",
          },
        ],
      },
      {
        id: "wheezing-rhonchi",
        title: "Wheezing and Rhonchi",
        audio: [
          {
            soundDescription: "Expiratory Wheezing",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Expiratory+Wheezing.mp3",
          },
          {
            soundDescription: "Rhonchi",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Ronchi.mp3",
          },
        ],
      },
      {
        id: "pleural-rub",
        title: "Pleural Rub",
        audio: [
          {
            soundDescription: "Pleural Rub",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Pleural+Rub.mp3",
          },
        ],
      },
      {
        id: "normal-breath-sounds",
        title: "Normal Breath Sounds",
        audio: [
          {
            soundDescription: "Vesicular Breath Sounds",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Vesicular.mp3",
          },
          {
            soundDescription: "Bronchovesicular Breath Sounds",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/bronchovesicular.mp3",
          },
          {
            soundDescription: "Bronchial Breath Sounds",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Bronchial.mp3",
          },
          {
            soundDescription: "Tracheal Breath Sounds",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Tracheal-10s.mp3",
          },
        ],
      },
      {
        id: "egophony",
        title: "Egophony",
        audio: [
          {
            soundDescription: "Egophony (Normal Lungs)",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Egophony+Normal.mp3",
          },
          {
            soundDescription: "Egophony (Abnormal)",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Egophony+Abnormal.mp3",
          },
        ],
      },
      {
        id: "bronchophony",
        title: "Bronchophony",
        audio: [
          {
            soundDescription: "Bronchophony (Normal Lungs)",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Bronchophony+(Normal).mp3",
          },
          {
            soundDescription: "Bronchophony (Abnormal)",
            audioURL:
              "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/breath+sounds/Bronchophony+(Abnormal).mp3",
          },
        ],
      },
    ],
    bowelSounds:[
      {
        id: "normal-bowel-sounds",
        title: "Normal Bowel Sounds",
        audio: [
          {
            soundDescription: "Normal Bowel Sounds",
            audioURL:
              "https://medzcool.s3.us-west-2.amazonaws.com/auscultate/Normal+Bowel+Sounds+(auscultate).mp3",
          },
        ],
      },
      {
        id: "hypoactive-bowel-sounds",
        title: "Hypoactive Bowel Sounds",
        audio: [
          {
            soundDescription: "Hypoactive Bowel Sounds",
            audioURL:
              "https://medzcool.s3.us-west-2.amazonaws.com/auscultate/Hypoactive+Bowel+Sounds+(auscultate-edit).mp3",
          },
        ],
      },
      {
        id: "hyperactive-bowel-sounds",
        title: "Hyperactive Bowel Sounds",
        audio: [
          {
            soundDescription: "Hyperactive Bowel Sounds",
            audioURL:
              "https://medzcool.s3.us-west-2.amazonaws.com/auscultate/Hyperactive+Bowel+Sounds+(auscultate).mp3",
          },
        ],
      },
      {
        id: "high-pitch-bowel-sounds",
        title: "High-Pitch Bowel Sounds",
        audio: [
          {
            soundDescription: "High-Pitch Bowel Sounds",
            audioURL:
              "https://medzcool.s3.us-west-2.amazonaws.com/auscultate/high-pitched+bowel+sounds+(auscultate).mp3",
          },
        ],
      }
    ]
  },
};

import React, { useState, Fragment } from "react";
import { useSpring, animated } from "react-spring";

// Styles
import styled from "styled-components";
import { fontFamily } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faHome, faMoneyBillWave, faUsersMedical, faUser } from "@fortawesome/pro-regular-svg-icons";

export default function MenuDrawer(props) {
  return (
    <Fragment>
      <MenuContainer style={props.style}>
        <div onClick={props.toggleMenu} className="close">
          <span>Close Menu</span>
          <FontAwesomeIcon icon={faTimes} />
        </div>

        <div className="menu-item" onClick={props.toggleMenu}>
          <a href="#pricing">
            <FontAwesomeIcon icon={faMoneyBillWave} />
            <span>Pricing</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="https://medzcool.com/auscultate/checkout">
            <FontAwesomeIcon icon={faUsersMedical} />
            <span>Sign-Up & Purchase</span>
          </a>
        </div>
        <div className="menu-item">
          <a href="/login">
            <FontAwesomeIcon icon={faUser} />
            <span>Login</span>
          </a>
        </div>
      </MenuContainer>
      <MenuBackground style={props.opacity} onClick={props.toggleMenu} />
    </Fragment>
  );
}

const MenuContainer = styled(animated.div)`
  font-family: ${fontFamily.sansSerif};
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  color: white;
  background: #1d1d1d;
  z-index: 3;
  padding: 25px;
  .menu-item {
    cursor: pointer;
    span {
      margin-left: 15px;
    }
    padding: 10px;
    margin: 15px 0;
    a {
      color: white;
      text-decoration: none;
    }
  }
  .close {
    cursor: pointer;
    text-align: right;
    margin-bottom: 25px;
    border-bottom: 1px solid gray;
    padding-bottom: 25px;
    span {
      margin-right: 15px;
    }
  }
  @media (max-width: 425px) {
    width: 100%;
    padding: 0;
    .close {
      padding: 25px;
    }
    .menu-item {
      text-align: center;
    }
  }
`;
const MenuBackground = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: black;
`;

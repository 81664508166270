import React, { Fragment, useEffect } from "react";

// Dependencies
import { Route } from "react-router-dom";
import ReactGA from "react-ga";

// Components
import Sidebar from "../Sidebar/Sidebar";

// Styles
import styled from "styled-components";
import { fontFamily, Title2 } from "../Utilities";

export default function Notifications(props) {
  const quizId = props.match.params.quizId;
  const { user } = props;

  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, []);

  return (
    <Fragment>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <MainContainer>
        <div className="main">
          <h1>What's New</h1>
          <Notification>
            <div className="date">3.08.2020</div>
            <div className="title">New Sound - Coarctation of the Aorta</div>
            <div className="body">
              <p>
                A detailed review of pathology, new graphics and 3 sound variations. 
              </p>
            </div>
          </Notification>
          <Notification>
            <div className="date">2.28.20</div>
            <div className="title">Bug Fixes</div>
            <div className="body">
              <p>
                Fixed a known bug affecting visual and video playback
              </p>
            </div>
          </Notification>
          <Notification>
            <div className="date">10.14.19</div>
            <div className="title">New Sound - Pericardial Friction Rub</div>
            <div className="body">
              <p>
                Pericardial Friction Rub. There are two variations of this sound with different intensities based on a person's position.
              </p>
            </div>
          </Notification>
          <Notification>
            <div className="date">07.08.19</div>
            <div className="title">New Sound Group</div>
            <div className="body">
              <p>
                Hypertrophic Cardiomyopathy (HCM) added to sound groups. There
                are also two variations of the murmur heard in cases of HCM
                which varies with different maneuvers which cause changes in
                venous return.
              </p>
            </div>
          </Notification>
          <Notification>
            <div className="date">07.01.19</div>
            <div className="title">New Interactive Auscultation Areas</div>
            <div className="body">
              <p>You can now sort sounds by their ausculutation location.</p>
            </div>
          </Notification>
          <Notification>
            <div className="date">07.01.19</div>
            <div className="title">New Sound Group</div>
            <div className="body">
              <p>
                Pulmonic Stenosis added to sound groups. A dedicated video and
                detailed explanation of the murmur and pathophysiology coming in
                the next few days.
              </p>
            </div>
          </Notification>
          <Notification>
            <div className="date">06.28.19</div>
            <div className="title">New Sound Variation</div>
            <div className="body">
              <p>
                Opening Snap. The mitral stenosis sound group was updated with
                the opening snap variation. Previously, Mitral stenosis only had
                the "opening snap with a diastolic murmur" associated with it.
                Now you can toggle between the sound of mitral stenosis in the
                presence of a murmur or without the diastolic murmur, and only
                the opening snap.
              </p>
            </div>
          </Notification>
          <Notification>
            <div className="date">06.20.19</div>
            <div className="title">New Sound Group</div>
            <div className="body">
              <p>
                The innocent/physiologic murmur has now been added to the
                library. Visit the sound page to learn more and compare it to
                other systolic murmurs. Quizes have also been updated with the
                sound so you can test yourself in identifying this murmur.
              </p>
            </div>
          </Notification>
          <Notification>
            <div className="date">06.08.19</div>
            <div className="title">Correction</div>
            <div className="body">
              <p>
                Aortic Regurgitation. Previously the location for maximal
                intensity for listening to Aortic Regurgitation was said to be
                at the right upper sternal border. However the correct location
                for the location for maximal intensity and the best area to
                listen for aortic regurgitation is at the left parasternal
                border at the 3rd/4th intercostal spaces.
              </p>
            </div>
          </Notification>
        </div>
      </MainContainer>
    </Fragment>
  );
}
const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  grid-gap: 25px;
  max-width: 1200px;
  .main {
    grid-column-end: 3;
    padding: 50px 25px;
    color: white;
    margin-top: 25px;
    font-family: ${fontFamily.sansSerif};
  }
  @media (max-width: 768px) {
    display: block;
    .main {
      padding: 0 15px;
    }
  }
`;
const SidebarContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const Notification = styled.div`
  font-family: ${fontFamily.sansSerif};
  margin-bottom: 45px;
  .date {
    font-weight: 300;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .title {
    font-size: 20px;
    font-weight: 700;
  }
`;

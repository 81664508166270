import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";

// Data
import { soundData } from "../Static/SoundData";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

export default function Innocent(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Pulmonic");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Innocent & Physiologic Murmurs</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>systolic murmur</li>
          <li>low grade intensity</li>
          <li>soft, rumbling quality</li>
        </ul>
        <p>
          Innocent and physiologic murmurs have similar characteristics, only
          differing in their causes. Physiologic murmurs are temporary murmurs
          that generally occur in physiologic states that increase blood flow.
          These include cases of pregnancy, in anemia, people with fevers,
          hyperthyroidism, etc. Innocent murmurs result from turbulent blood
          flow coming from the ventricles during systole as it is ejected from
          ventricles towards the semilunar valves. Regardless, both physiology
          and innocent murmurs occur from this turbulent blood flow during
          systole.
        </p>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-murmur-graphic.png"
            alt="Innocent Murmur"
            className="first"
          />
        </ImgContainer>
        <p>
          It is not a very loud murmur, usually, grade 1-2 with a medium pitch.
          The quality of the murmur is variable, but because of its soft
          intensity, it is generally blowing or a quiet rumbling.
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-murmur-location.jpg"
              alt="Innocent Murmur"
              className="first thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
        <p>
          Both physiologic murmurs and Innocent murmurs are best heard at the
          2nd to 4th left intercostal spaces, but can also be heard at the apex.
          There is little radiation of the murmur from its area of maximal
          intensity.
        </p>
        <p>
          These murmurs are commonly heard in children and young adults but less
          frequently heard in the elderly. They are generally considered normal
          and not associated with any underlying cardiovascular disease.
        </p>
      </Intro>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "innocent-murmur"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Innocent Murmur"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-short.mp3"
        video="https://vimeo.com/343373184/69953e3766"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .full {
    width: 100%;
  }
  .first {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis-container {
    position: relative;
    width: max-content;
    margin: auto;
    .thoraxis {
      cursor: pointer;
      box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
    .expand-icon {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 15px;
      color: white;
      opacity: 0.5;
      .icon {
        vertical-align: middle;
      }
      span {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .thoraxis-container {
      width: 100%;
    }
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

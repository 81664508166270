import React from "react";
import { url } from "../settings";

// Material UI
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faLock,
  faSignOut,
  faHome
} from "@fortawesome/pro-regular-svg-icons";

// Apollo, GraphQL
import { Mutation, Query, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";

const LOGOUT = gql`
  mutation logout {
    logout {
      user {
        id
      }
    }
  }
`;

const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  smText: {
    fontSize: 10
  },
  subheader: {
    backgroundColor: "black"
  },
  menuAnchor: {
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  }
};

class DrawerMenuRight extends React.Component {
  state = {
    right: false
  };

  clickAvatar = () => {
    this.setState({
      ["right"]: true
    });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
    this.props.closeDrawer();
  };

  componentDidUpdate(prevProps) {
    if (this.props.drawerRight !== prevProps.drawerRight) {
      this.setState({
        ["right"]: this.props.drawerRight
      });
    }
  }

  render() {
    const { classes, history } = this.props;

    const sideList = (
      <StyledDrawer className={classes.list}>
        <List>
          <ListItem
            button
            onClick={() => (window.location = "https://medzcool.com")}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faHome} />
            </ListItemIcon>
            <ListItemText disableTypography>
              <span className="drawer-text">Return to Medzcool.com</span>
            </ListItemText>
          </ListItem>
        </List>

        <Divider />

        <List>
          <ListItem
            button
            onClick={() =>
              (window.location = "https://medzcool.com/change-password")
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faLock} />
            </ListItemIcon>
            <ListItemText disableTypography>
              <span className="drawer-text">Change Password</span>
            </ListItemText>
          </ListItem>
          <Mutation
            mutation={LOGOUT}
            update={(cache, data) => {
              cache.writeData({ data: { user: null } });
            }}
            onCompleted={data => {
              window.location = "https://medzcool.com";
            }}
          >
            {(logout, { data }) => (
              <ListItem button onClick={e => logout()}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faSignOut} />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <span className="drawer-text">Sign Out</span>
                </ListItemText>
              </ListItem>
            )}
          </Mutation>
        </List>
      </StyledDrawer>
    );

    return (
      <Drawer
        anchor="right"
        open={this.state.right}
        onClose={this.toggleDrawer("right", false)}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={this.toggleDrawer("right", false)}
          onKeyDown={this.toggleDrawer("right", false)}
        >
          {sideList}
        </div>
      </Drawer>
    );
  }
}

DrawerMenuRight.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DrawerMenuRight);

const StyledDrawer = styled.div`
  font-family: ${fontFamily.sansSerif};
  .drawer-text {
    font-size: 11px;
  }
`;

import React, { Fragment } from "react";

// Components
import CourseHeader from "./CourseHeader";
import CourseSidebar from "./CourseSidebar";
import CourseLesson from "./CourseLesson";
import Spinner from "../Utilities/Spinner";

// Dependencies
import { Route } from "react-router-dom";

// Styles
import styled from "styled-components";
import { fontFamily } from "../Utilities";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_LESSONS = gql`
  query GET_LESSONS {
    lessons {
      id
      lessonId
      title
    }
  }
`;

export default function CourseHome(props) {
  const { user } = props;
  return (
    <Query query={GET_LESSONS}>
      {({ loading, data }) => {
        if (loading) return <Spinner />;

        return (
          <Fragment>
            <CourseHeader />
            <CourseContainer>
              <CourseSidebar lessons={data.lessons} user={user} />
              <div className="body">
                <Route
                  path="/how-to-auscultate/:lessonId"
                  render={props => (
                    <CourseLesson {...props} user={user} />
                  )}
                />
              </div>
            </CourseContainer>
          </Fragment>
        );
      }}
    </Query>
  );
}

const CourseContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  display: grid;
  grid-template-columns: 250px auto;
  grid-gap: 25px;
  .body {
    padding: 100px 50px 50px 50px;
    max-width: 800px;
    color: white;
  }
`;

const SidebarContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
`;

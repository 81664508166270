import React, { Component, Fragment } from "react";
// Dependencies
import { url } from "../settings";

// Components
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import Editor, { composeDecorators } from "draft-js-plugins-editor";
import { Button } from "../Utilities";
import ImageAdd from "./ImageAdd";

// Plugins
import createToolbarPlugin, { Separator } from "draft-js-static-toolbar-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton
} from "draft-js-buttons";
import createImagePlugin from "draft-js-image-plugin";
import createAlignmentPlugin from "draft-js-alignment-plugin";
import createFocusPlugin from "draft-js-focus-plugin";
import createResizeablePlugin from "draft-js-resizeable-plugin";

// Styles
import styled from "styled-components";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
import "draft-js-image-plugin/lib/plugin.css";
import "draft-js-alignment-plugin/lib/plugin.css";
import "draft-js-focus-plugin/lib/plugin.css";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-light-svg-icons";

// Apollo, GraphQL
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

const EDIT_LESSON = gql`
  mutation EDIT_LESSON($id: Int!, $body: String!) {
    editLessonBody(id: $id, body: $body) {
      lesson {
        id
        body
      }
    }
  }
`;

const EDIT_SOUND = gql`
  mutation EDIT_SOUND($id: Int!, $body: String!) {
    editSoundBody(id: $id, body: $body) {
      sound {
        id
        body
      }
    }
  }
`;

const imagePlugin = createImagePlugin();

export default class MedzcoolEditor extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    // Plugins
    const staticToolbarPlugin = createToolbarPlugin();

    const focusPlugin = createFocusPlugin();

    // const resizeablePlugin = createResizeablePlugin();

    const alignmentPlugin = createAlignmentPlugin();
    const { AlignmentTool } = alignmentPlugin;

    const { Toolbar } = staticToolbarPlugin;

    const decorator = composeDecorators(
      alignmentPlugin.decorator,
      focusPlugin.decorator
      // resizeablePlugin.decorator
    );

    const imagePlugin = createImagePlugin({ decorator });
    const addImage = createImagePlugin().addImage;

    this.plugins = [
      staticToolbarPlugin,
      imagePlugin,
      alignmentPlugin,
      focusPlugin
    ];

    this.components = {
      Toolbar,
      AlignmentTool
    };

    this.state = {
      largeFileSize: false,
      hasFocus: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.content.body) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(JSON.parse(this.props.content.body))
        ),
        mounted: true
      });
    } else {
      this.setState({ editorState: EditorState.createEmpty(), mounted: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this._isMounted) {
      if (this.props.content.body !== prevProps.content.body) {
        this.setState({
          editorState: EditorState.createWithContent(
            convertFromRaw(JSON.parse(this.props.content.body))
          )
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = editorState => {
    this.setState({ editorState });
  };

  handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  focus = () => {
    this.editor.focus();
  };

  handleSave = (id, mutation, editorState) => {
    const contentState = editorState.getCurrentContent();
    const body = JSON.stringify(convertToRaw(contentState));
    mutation({
      variables: {
        id,
        body
      }
    });
    this.props.handleEditToggle();
  };

  render() {
    const { Toolbar, AlignmentTool } = this.components;
    const { content } = this.props;
    if (!this.props.editing) {
      return null;
    }

    if (!this.state.editorState) {
      return <h3 className="loading">Loading...</h3>;
    } else {
      return (
        <Fragment>
          <EditorContainer>
            <Editor
              editorState={this.state.editorState}
              onChange={this.onChange}
              handleKeyCommand={this.handleKeyCommand}
              plugins={this.plugins}
              ref={element => {
                this.editor = element;
              }}
            />
            <Toolbar>
              {externalProps => (
                <Fragment>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <Separator {...externalProps} />
                  <HeadlineOneButton {...externalProps} />
                  <HeadlineTwoButton {...externalProps} />
                  <Separator {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                  <Separator {...externalProps} />
                </Fragment>
              )}
            </Toolbar>
          </EditorContainer>
          <AlignmentTool />
          <ImageAdd
            editorState={this.state.editorState}
            onChange={this.onChange}
            modifier={imagePlugin.addImage}
          />
          {this.props.contentType == "sound" ? (
            <Mutation mutation={EDIT_SOUND}>
              {editSoundBody => (
                <Button
                  margin={"15px 0"}
                  onClick={() => {
                    this.handleSave(
                      content.id,
                      editSoundBody,
                      this.state.editorState
                    );
                  }}
                >
                  Save Changes
                </Button>
              )}
            </Mutation>
          ) : (
            <Mutation mutation={EDIT_LESSON}>
              {editLessonBody => (
                <Button
                  margin={"15px 0"}
                  onClick={() => {
                    this.handleSave(
                      content.id,
                      editLessonBody,
                      this.state.editorState
                    );
                  }}
                >
                  Save Changes
                </Button>
              )}
            </Mutation>
          )}
        </Fragment>
      );
    }
  }
}
const EditorContainer = styled.div`
  position: relative;
  font-family: adobe-garamond-pro, serif;
  .DraftEditor-root {
    background: white;
    color: black;
    margin: 15px;
    margin: 5px 0;
    border: 1px solid gray;
    border-radius: 3px;
    font-size: 22px;
    line-height: 30px;
    figure {
      margin: 10px 0;
      img {
        max-width: 100%;
      }
    }
    .public-DraftStyleDefault-block {
      margin-top: 29px;
    }
    @media (max-width: 576px) {
      font-size: 15px;
      line-height: 25px;
    }
  }
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
`;

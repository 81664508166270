import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
} from "../../Utilities";
import Spinner from "../../Utilities/Spinner";
import MediaPlayerStatic from "../../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../../Header/HeaderMobile";
import CaseCard from "../CaseCard";
import { SmallText } from "../../Utilities";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

// Data
import { soundData } from "../../Static/SoundData";

export default function WheezingRhonchi(props) {
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Wheezing and Rhonchi</h1>
      </TitleBlock>
      <Intro>
        <h2>Wheezing</h2>
        <p>
          Wheezes: relatively high-pitched (≥400 Hz) with hissing or shrill
          quality (>80 ms)
        </p>
        <p>
          Wheezes are continuous musical sounds that occur during rapid airflow
          when bronchial airways are narrowed almost to the point of closure.
          Wheezes can be inspiratory, expiratory, or biphasic. They may be
          localized, due to a foreign body, mucous plug, or tumor, or heard
          throughout the lung. Although wheezes are typical of asthma, they can
          occur in a number of pulmonary diseases. Recent studies suggest that
          as the airways become more narrowed, wheezes become less audible,
          culminating finally in “the silent chest” of severe asthma requiring
          immediate intervention.
        </p>

        <h2>Rhonchi</h2>
        <p>
          Rhonchi: relatively low-pitched (150–200 Hz) with snoring quality (>80
          ms)
        </p>
        <p>
          Rhonchi are considered by some to be a variant of wheezes, arising
          from the same mechanism, but lower in pitch. Unlike wheezes, rhonchi
          may disappear with coughing, so secretions may be involved. Many
          clinicians use the term “rhonchi” to describe sounds from secretions
          in large airways that may change with coughing.Note that the American
          Thoracic Society describes rhonchi as a low-pitched wheeze (unrelated
          to airway secretions), so some recommend not using the term “rhonchi.”
        </p>
      </Intro>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.breathSounds;
    const currentSound = jsonSoundData.filter(
      (sound) => sound.id == "wheezing-rhonchi"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Wheezing and Rhonchi"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic audioArray={audioArray} isBreath />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  width: max-content;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    cursor: pointer;
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .expand-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    color: white;
    opacity: 0.5;
    .icon {
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  font-family: futura-pt, sans-serif;
  ul {
  }

  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
  SmallText
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";

// Data
import { soundData } from "../Static/SoundData";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

export default function HCM(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Tricuspid");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Hypertrophic Cardiomyopathy</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>
            high-pitched, harsh, crescendo-decrescendo, mid-systolic murmur
          </li>
          <li>best heard at the left lower sternal border and apex</li>
          <li>radiation to base</li>
        </ul>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/HOCM-graphic.png"
            alt="Hypertrophic Cardiomyopathy"
            className="first"
          />
        </ImgContainer>
        <p>
          Hypertrophic cardiomyopathy (HCM) is caused by a genetic mutation
          leading to left ventricular outflow obstruction often by left
          ventricular hypertrophy. The murmur associated with HCM is secondary
          to this left ventricular outflow tract obstruction. It is a high
          pitched murmur with a harsh quality. It has a crescendo-decrescendo
          shape and often heard just after S1. It sound similar to aortic
          stenosis, however can be differentiated from aortic stenosis based on
          various maneuvers which change the murmur's intensity.
        </p>

        <p>
          It is best heard at the along the mid-lower left sternal border and at
          the apex, and may be heard radiating towards the base of the heart,
          this is in contrast to aortic stenosis which classically radiates
          towards the neck bilaterally.
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/hcm-location.jpg"
              alt="hypertrophic cardiomyopathy"
              className="first thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
      </Intro>
      <P1>
        <div>
          <h2>Maneuvers and Dynamic Auscultation</h2>
          <p>
            Dynamic auscultation can help differentiate the murmur of HCM from
            aortic stenosis. In cases of HCM, the murmur will typically get
            louder with maneuvers that decreased ventricular filling. These
            include the Valsalva maneuver and going from a supine or squatting
            position to a standing position.
          </p>
          <p>
            Maneuvers that will increased venous return and increased
            ventricular filling will decreases the severity the left ventricular
            outflow obstruction and decrease the intensity of the murmur.
            Maneuvers that will decrease the intensity of a HCM murmur include,
            going from standing to squatting or sitting, handgrip or passive leg
            raises.
          </p>
          <p>
            This is in contrast to aortic stenosis where maneuvers such as the
            Valsalva do not significantly change the intensity of the murmur, or
            may sometimes make the murmur of aortic stenosis softer.
            Additionally, going from standing to squatting will increased venous
            return and make the murmur of aortic stenosis louder.
          </p>
        </div>
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "hypertrophic-cardiomyopathy"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Hypertrophic Cardiomyopathy"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis.mp3"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis-container {
    position: relative;
    width: max-content;
    margin: auto;
    .thoraxis {
      cursor: pointer;
      box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
    .expand-icon {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 15px;
      color: white;
      opacity: 0.5;
      .icon {
        vertical-align: middle;
      }
      span {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .thoraxis-container {
      width: 100%;
    }
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

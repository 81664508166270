import React from "react";

// Components
import SoundCard from "../SoundCard";

// Styles
import styled from "styled-components";
import { Title2 } from "../../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export default function LungSounds(props) {
  function handleBack() {
    props.history.goBack();
  }
  return (
    <SoundIndexContainer>
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="back-icon"
        onClick={handleBack}
      />
      <div className="soundcard-container">
        <Title2>Bowel Sounds</Title2>
        <SoundCard
          key="normal-bowel-sounds"
          url="normal-bowel-sounds"
          soundTitle="Normal Bowel Sounds"
          isBowel
        />
        <SoundCard
          key="hypoactive-bowel-sounds"
          url="hypoactive-bowel-sounds"
          soundTitle="Hypoactive Bowel Sounds"
          isBowel
        />
        <SoundCard
          key="hyperactive-bowel-sounds"
          url="hyperactive-bowel-sounds"
          soundTitle="Hyperactive Bowel Sounds"
          isBowel
        />
        <SoundCard
          key="high-pitch-bowel-sounds"
          url="high-pitch-bowel-sounds"
          soundTitle="High-Pitch Bowel Sounds"
          isBowel
        />
      </div>
    </SoundIndexContainer>
  );
}
const SoundIndexContainer = styled.div`
  padding-bottom: 75px;
  .back-icon {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 15px;
    color: white;
    display: none;
  }
  .soundcard-container {
    margin-top: 100px;
  }
  ${Title2} {
    color: white;
    padding: 15px 0;
    font-weight: 700;
    margin: 25px;
  }
  @media (max-width: 768px) {
    .soundcard-container {
      margin-top: 0;
    }
    .back-icon {
      display: block;
    }
    ${Title2} {
      text-align: center;
    }
  }
`;

import React, { useState } from "react";

// Components
import Spinner from "../Utilities/Spinner";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";

// Styles
import styled from "styled-components";
import { fontFamily, Title1 } from "../Utilities";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_LESSON = gql`
  query GET_LESSON($id: Int!) {
    lesson(id: $id) {
      id
      body
      title
    }
  }
`;

export default function CourseLesson(props) {
  const { user } = props;

  const [editing, setEditing] = useState(false);

  function handleEditToggle() {
    setEditing(!editing);
  }

  return (
    <Query query={GET_LESSON} variables={{ id: props.match.params.lessonId }}>
      {({ loading, data }) => {
        if (loading) return <Spinner />;

        const lesson = data.lesson;
        return (
          <LessonContainer>
            {user.isSuperuser && <button onClick={handleEditToggle}>Edit</button>}
            <Title1>{lesson.title}</Title1>
            <MedzcoolEditor
              content={lesson}
              editing={editing}
              handleEditToggle={handleEditToggle}
            />
            <MedzcoolEditorReadOnly content={lesson} editing={editing} />
          </LessonContainer>
        );
      }}
    </Query>
  );
}

const LessonContainer = styled.div`
  box-sizing: border-box;
  font-family: ${fontFamily.sansSerif};
`;

import React, { useState, useEffect } from "react";

// Dependencies
import { withRouter } from "react-router-dom";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";

function SearchBar(props) {
  const { history, changeSearchWord } = props;
  const [searchWord, setSearchWord] = useState("");
  useEffect(() => {
    if (props.location.pathname != "/search") {
      setSearchWord("");
    }
  });

  function handleChange(e) {
    const searchValue = e.target.value;
    setSearchWord(searchValue);
    history.push("/search");
    changeSearchWord(searchValue);
  }
  return (
    <SearchBarContainer>
      <FontAwesomeIcon icon={faSearch} />
      <SearchBarInput
        placeholder={"Search"}
        value={searchWord}
        onChange={handleChange}
      />
    </SearchBarContainer>
  );
}
export default withRouter(SearchBar);

const SearchBarContainer = styled.div`
    border-radius: 3px
    background: ${grayscale.gray};
    padding: 0px 10px;
    color: ${grayscale.lightGray};
    width: 350px;
    font-family: ${fontFamily.sansSerif};
    span {
        margin-left: 8px;
    }
`;
const SearchBarInput = styled.input`
  background: ${grayscale.gray};
  border: none;
  color: ${grayscale.lightGray};
  padding: 10px;
  width: 300px;
  outline: none;
  font-size: 16px;
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
} from "../../Utilities";
import Spinner from "../../Utilities/Spinner";
import MediaPlayerStatic from "../../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../../Header/HeaderMobile";
import CaseCard from "../CaseCard";
import { SmallText } from "../../Utilities";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

// Data
import { soundData } from "../../Static/SoundData";

export default function Crackles(props) {
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Crackles (Rales)</h1>
      </TitleBlock>
      <Intro>
        <p>
          Crackles are discontinuous nonmusical sounds that can be early
          inspiratory (as in COPD), late inspiratory (as in pulmonary fibrosis),
          or biphasic (as in pneumonia). They are currently considered to result
          from a series of tiny explosions when small distal airways, deflated
          during expiration, pop open during inspiration. With few exceptions,
          recent acoustic studies indicate that the role of secretions as a
          cause of crackles is less likely.
        </p>

        <h2>Fine Crackles</h2>
        <p>
          Fine crackles are softer, higher pitched, and more frequent per breath
          than coarse crackles. They are heard from mid to late inspiration,
          especially in the dependent areas of the lung, and change according to
          body position. They have a shorter duration and higher frequency than
          coarse crackles. Fine crackles appear to be generated by the “sudden
          inspiratory opening of small airways held closed by surface forces
          during the previous expiration.”
        </p>

        <p>
          Examples include pulmonary fibrosis (known for “Velcro rales”) and
          interstitial lung diseases such as interstitial fibrosis and
          interstitial pneumonitis.
        </p>

        <h2>Coarse Crackles</h2>
        <p>
          Coarse crackles appear in early inspiration and last throughout
          expiration (biphasic), have a popping sound, are heard over any lung
          region, and do not vary with body position. They have a longer
          duration and lower frequency than fine crackles, change or disappear
          with coughing, and are transmitted to the mouth. Coarse crackles
          appear to result from “boluses of gas passing through airways as they
          open and close intermittently.”
        </p>

        <p>
          Examples include COPD, asthma, bronchiectasis, pneumonia (crackles may
          become finer and change from mid to late inspiratory during recovery),
          and heart failure.
        </p>
      </Intro>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.breathSounds;
    const currentSound = jsonSoundData.filter((sound) => sound.id == "crackles");
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile headerTitle="Crackles (Rales)" appView={"soundDetails"} {...props} />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic audioArray={audioArray} isBreath />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  width: max-content;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    cursor: pointer;
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .expand-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    color: white;
    opacity: 0.5;
    .icon {
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  font-family: futura-pt, sans-serif;
  ul {
  }

  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;

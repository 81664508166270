import React, { Fragment, useEffect, useState } from "react";

// Dependencies
import { shuffle } from "lodash";

// Components
import StethosSoundPlayer from "../MediaPlayer/StethosSoundPlayer";
import SoundDetailsSwitch from "./SoundDetailsSwitch";
import StethosHeader from "./StethosHeader";
import SoundCard from "../Sounds/SoundCard";

// Styles
import styled from "styled-components";
import { Title1, Line, fontFamily } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";

// Data
import { jsonResponse } from "./QuizData";

export default function StethosHome(props) {
  // 1. Initialize
  const [sessionObject, setSessionObject] = useState();
  const [activeSound, setActiveSound] = useState();
  const [activeSoundObject, setActiveSoundObject] = useState();
  const [answerChoices, setAnswerChoices] = useState();
  const [soundArray, setSoundArray] = useState();
  useEffect(() => {
    const newSessionObject = jsonResponse.data;
    const jsonSoundArray = newSessionObject.heartSounds;
    const randomizedSoundArray = shuffle(jsonSoundArray);
    setSoundArray(randomizedSoundArray);

    newSessionObject["heartSounds"] = randomizedSoundArray;
    setSessionObject(newSessionObject);

    const soundObject = randomizedSoundArray[soundPosition];
    mountSound(soundObject);
  }, []);

  // 2. Mount Sound
  const [correctAnswer, setCorrectAnswer] = useState();
  function mountSound(soundObject) {
    setActiveSound(soundObject.audio);
    setActiveSoundObject(soundObject);

    const answerChoices = soundObject.answerChoices;

    // 1. Remove correct answer
    const answersWithoutCorrect = answerChoices.filter(answer => {
        return !answer.correctAnswer
    })

    // 2. Randomized & splice Incorrect answer choices
    let randomizedAnswerChoices = shuffle(answersWithoutCorrect);
    randomizedAnswerChoices = randomizedAnswerChoices.slice(0, 5);

    const correct = answerChoices.find(choice => {
      return choice.correctAnswer == true;
    });
    setCorrectAnswer(correct);

    // 3. Concat Answer choices with correct

    randomizedAnswerChoices = randomizedAnswerChoices.concat(correct)
    randomizedAnswerChoices = shuffle(randomizedAnswerChoices)
    console.log(randomizedAnswerChoices)
    setAnswerChoices(randomizedAnswerChoices);

    if (soundObject.selectedChoice) {
      setIsAnswered(true);
      setSelectedChoice(soundObject.selectedChoice);
    }
  }

  const [showResults, setShowResults] = useState();
  const [soundPosition, setSoundPosition] = useState(0);
  const [isDone, setIsDone] = useState(false);
  function handleNext(isFinished) {
    window.scrollTo(0, 0);
    if (isFinished == "finish") {
      setIsDone(true);
      const updatedSession = { ...sessionObject };
      updatedSession["isSubmitted"] = true;
      setSessionObject(updatedSession);
    }

    const soundArrayLength = sessionObject.heartSounds.length;

    // Increment only if NOT at end of arrays
    if (sessionObject.arrayPosition + 1 < soundArrayLength) {
      console.log("increment");
      const updatedSession = { ...sessionObject };
      updatedSession["arrayPosition"] = ++updatedSession.arrayPosition;
      console.log(updatedSession);
      setSessionObject(updatedSession);

      setSoundPosition(updatedSession.arrayPosition);

      const soundObject =
        updatedSession.heartSounds[updatedSession.arrayPosition];
      mountSound(soundObject);

      if (soundObject.selectedChoice) {
        setIsAnswered(true);
        setSelectedChoice(soundObject.selectedChoice);
      } else {
        setIsAnswered(false);
        setSelectedChoice(null);
      }
    }

    if (isDone) {
      setShowResults(true);
    }
  }

  function handleBack() {
    window.scrollTo(0, 0);
    const soundArrayLength = sessionObject.heartSounds.length;

    if (sessionObject.arrayPosition !== 0) {
      const updatedSession = { ...sessionObject };
      updatedSession["arrayPosition"] = updatedSession["arrayPosition"] - 1;
      setSessionObject(updatedSession);
      setSoundPosition(updatedSession.arrayPosition);
      mountSound(updatedSession.heartSounds[updatedSession.arrayPosition]);
    }
  }

  const [selectedChoice, setSelectedChoice] = useState(null);
  function handleSelect(choice) {
    if (!isAnswered) {
      setSelectedChoice(choice);

      const updatedSession = { ...sessionObject };
      updatedSession.heartSounds[
        updatedSession.arrayPosition
      ].selectedChoice = choice;
      setSessionObject(updatedSession);
    }
  }

  // Logic for after user submits answer
  const [correctArray, setCorrectArray] = useState([]);
  const [incorrectArray, setIncorrectArray] = useState([]);
  const [isAnswered, setIsAnswered] = useState(false);
  function handleSubmit() {
    const updatedSession = { ...sessionObject };
    const currentSound =
      updatedSession.heartSounds[updatedSession.arrayPosition];

    if (!currentSound.selectedChoice) return null;

    window.scrollTo(0, 0);
    setIsAnswered(true);
    if (selectedChoice.id == correctAnswer.id) {
      const newCorrectArray = correctArray.concat(activeSoundObject);
      setCorrectArray(newCorrectArray);

      updatedSession["correct"] = updatedSession.correct.concat(currentSound);
      setSessionObject(updatedSession);
    } else if (correctAnswer.id !== selectedChoice.id) {
      const newInorrectArray = incorrectArray.concat(activeSoundObject);
      setIncorrectArray(newInorrectArray);

      updatedSession["incorrect"] = updatedSession.incorrect.concat(
        currentSound
      );
      setSessionObject(updatedSession);
    }
  }

  function renderGrade(choiceLabel) {
    if (!selectedChoice) return null;
    if (
      isAnswered &&
      selectedChoice.id == choiceLabel &&
      correctAnswer.id == choiceLabel
    ) {
      return <FontAwesomeIcon icon={faCheck} className="icon" />;
    } else if (
      isAnswered &&
      selectedChoice.id == choiceLabel &&
      correctAnswer.id !== choiceLabel
    ) {
      return <FontAwesomeIcon icon={faTimes} className="icon" />;
    }
  }

  function renderCorrectColor(choiceLabel) {
    if (!selectedChoice) return null;
    if (
      isAnswered &&
      selectedChoice.id == choiceLabel &&
      correctAnswer.id == choiceLabel
    ) {
      return "true";
    } else if (
      isAnswered &&
      selectedChoice.id == choiceLabel &&
      correctAnswer.id !== choiceLabel
    )
      return "false";
  }

  if (!activeSound) return null;
  if (showResults || sessionObject.isSubmitted) {
    return (
      <Fragment>
        <StethosHeader sessionObject={sessionObject} />
        <Container>
          <h1 className="results-title">Your Results</h1>
          <ResultsContainer>
            <div className="soundcard-container">
              <h2>Correct</h2>
              <div>
                {sessionObject.correct.map(soundObject => {
                  return (
                    <SoundCard
                      key={soundObject.id}
                      url={soundObject.id}
                      soundTitle={soundObject.title}
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <h2>Incorrect</h2>
              <div>
                {sessionObject.incorrect.map(soundObject => {
                  return (
                    <SoundCard
                      key={soundObject.id}
                      url={soundObject.id}
                      soundTitle={soundObject.title}
                    />
                  );
                })}
              </div>
            </div>
          </ResultsContainer>
        </Container>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <StethosHeader
        soundPosition={soundPosition}
        total={soundArray.length}
        sessionObject={sessionObject}
      />
      <Container>
        <DisplayImg>
          {isAnswered ? (
            <img src={activeSoundObject.graphic} />
          ) : (
            <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/soundwave.png" />
          )}
        </DisplayImg>
        {!isAnswered && <h2 className="question">What Sound Do You Hear?</h2>}

        <ChoiceContainer>
          {answerChoices.map(choice => {
            return (
              <AnswerChoice
                key={choice.id}
                choiceObject={choice}
                correctAnswer={choice.correctAnswer && true}
                onClick={() => handleSelect(choice)}
                isCorrect={renderCorrectColor(choice.id)}
                isAnswered={isAnswered}
                isSelected={
                  selectedChoice
                    ? selectedChoice.id == choice.id && "true"
                    : "false"
                }
              >
                <div className="grade-container">{renderGrade(choice.id)}</div>

                {choice.id}
              </AnswerChoice>
            );
          })}
        </ChoiceContainer>

        {isAnswered && activeSound && (
          <SoundDetailsContainer>
            <Line />
            <SoundDetailsSwitch activeSoundObject={activeSoundObject} />
          </SoundDetailsContainer>
        )}
      </Container>
      {activeSound && (
        <StethosSoundPlayer
          audio={activeSound}
          handleSubmit={handleSubmit}
          handleNext={handleNext}
          handleBack={handleBack}
          isAnswered={isAnswered}
          isDone={isDone}
          sessionObject={sessionObject}
        />
      )}
    </Fragment>
  );
}

const Container = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 75px;
  padding: 50px 50px 50px 50px;
  font-family: ${fontFamily.sansSerif};
  color: white;
  .question {
    font-size: 18px;
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    margin-top: 5px;
    padding: 50px 15px;
    margin-bottom: 75px;
  }
  @media (max-width: 768px) {
    .question {
      text-align: center;
    }
    .results-title {
      text-align: center;
      margin-top: 0;
      margin-bottom: 50px;
    }
  }
  @media (max-width: 425px) {
    padding: 50px 8px;
  }
`;

const StethosNav = styled.div`
  position: fixed;
  bottom: 0;
`;
const SoundDetailsContainer = styled.div`
  margin-bottom: 100px;
`;

const DisplayImg = styled.div`
  text-align: center;
  img {
    width: 100%;
    max-width: 400px;
  }
`;
const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 35px;
  color: white;
  h2 {
    margin-bottom: 25px;
  }
  @media (max-width: 768px) {
    display: block;
    .soundcard-container {
      margin-bottom: 50px;
    }
  }
`;
const ChoiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 35px;
  .grade-container {
    margin: 0 15px;
    display: inline-block;
  }
  @media (max-width: 1024px) {
    grid-gap: 10px;
    margin-top: 25px;
    .grade-container {
      margin: 0 10px 0 0;
      .icon {
        margin-left: 15px;
      }
    }
  }
  @media (max-width: 375px) {
    grid-gap: 8px;
    margin-top: 20px;
    .grade-container {
      margin: 0 8px 0 0;
      .icon {
        margin-left: 10px;
      }
    }
  }
`;
const AnswerChoice = styled.div`
  border: 1px solid white;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  @media (max-width: 1024px) {
    padding: 15px 0;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
  ${props =>
    props.isSelected == "true" && `background: #EDCD18; color: black;`};
  ${props =>
    !props.isAnswered
      ? null
      : props.isSelected
      ? props.isCorrect == "true"
        ? `background: #0EEDB7; color: black; border: none;`
        : `background: #ED0E5E; color: black; border: none;`
      : `background: #353535; color: gray; border: none;`};
  ${props =>
    props.correctAnswer && props.isAnswered && `border: 1px solid #0EEDB7;`};
`;

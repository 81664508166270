import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";
import { SmallText } from "../Utilities";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

// Data
import { soundData } from "../Static/SoundData";

export default function Coarctation(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Pulmonic");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Coarctation of the Aorta</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>murmur reflects associated cardiac abnormalities</li>
          <li>systolic ejection click</li>
          <li>continuation systolic murmur</li>
          <li>intensified S2 (pulmonary hypertension)</li>
        </ul>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/CoA-graphic.png"
            alt="coarctation of the aorta"
            className="second"
          />
        </ImgContainer>
        <p>
          Coarctation of the aorta (CoA) is a congenital aortic narrowing,
          usually located at the insertion site of the ductus arteriosus. CoA
          accounts for a small percentage of all congenital heart defects with a
          prevalence of approximately 4 per 10,000 live births. It occurs more
          commonly in males than in females (59 versus 41 percent).
        </p>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/coarctation-anatomy.png"
            alt="coarctation"
            className="first"
          />
        </ImgContainer>
        <p>
          CoA is usually accompanied by another cardiac lesion, although it has
          been reported that in less than one-quarter of patients with CoA have
          isolated CoA without associated cardiovascular abnormalities. In
          general, less than one-quarter of patients with CoA have isolated CoA
          without associated cardiovascular abnormalities. In infants and
          children, a considerable proportion of patients have associated
          complex congenital heart disease (hypoplastic left heart syndrome,
          atrioventricular [AV] canal defect, transposition of the great
          arteries, VSD, etc). In adults, bicuspid aortic valve is the most
          common associated defect.
        </p>
        <p>
          CoA does not cause a hemodynamic problem in utero. That's because
          two-thirds of the combined cardiac output flows through the patent
          ductus arteriosus (PDA) into the descending thoracic aorta, thus
          bypassing the site of constriction in cases of CoA.
        </p>
        <p>
          During the neonatal period, when the PDA and foramen ovale (between
          the right and left atria) begin to close, the cardiac output that must
          cross the narrowed aortic segment to reach the lower extremities
          steadily increases. With these changes, the hemodynamic changes may
          range from mild systolic hypertension to severe heart failure
          depending upon the severity of the coarctation and upon the presence
          of other associated lesions.
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/location-left-second-ics.jpg"
              alt="atrial septal defect"
              className="first thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
      </Intro>
      <P1>
        <div>
          <h2>Physical Exam</h2>
          <p>
            In neonates, on physical examination, a clinical diagnosis is made
            if there is an absent or delayed femoral pulse (when compared with
            the brachial pulse).
          </p>
          <p>
            Diagnosis is often delayed in older infants, children, and adults
            because physical findings are subtle and because most patients are
            asymptomatic. As with neonates, in older populations, suggestive
            findings on physical exam include lower systolic blood pressure in
            the legs and lower extremities when compared to the upper
            extremities and brachial or radial artery to femoral artery pulse
            delay. In previously undiagnosed adults, the classic presenting sign
            is hypertension.
          </p>
        </div>
      </P1>
      <P1>
        <div>
          <h2>The Murmur</h2>
          <p>
            The murmur associated with CoA often reflects any associated cardiac
            abnormalities. However, cardiac auscultation may be normal if there
            are no associated cardiac abnormalities.
          </p>
          <h3>S2 Heart Sound</h3>
          <p>
            If a murmur is heard, it can likely best be heard in the upper left
            sternal border with radiation towards the left axilla.
          </p>
          <h3>Systolic Ejection Click and Murmur</h3>
          <p>
            In patients who have a bicuspid aortic valve, a systolic ejection
            click and murmur can be heard. When present, this is best heard at
            the apex or left sternal border. As mentioned above, systolic
            murmurs could also represent associated cardiac abnormalities such
            as PDA, VSD or AS.
          </p>
          <h3>Continuation of the systolic murmur</h3>
          <p>
            Blood flow across the coarctation can lead to a systolic murmur,
            which can be heard extending beyond the S2 heart sound into
            asystole. When present, this can best be heard at the left
            paravertebral interscapular area. Additionally, dilated intercostal
            collateral arteries may cause a continuous murmur in the intercostal
            spaces.
          </p>
        </div>
      </P1>
      <P1>
        <h2>
          Clinical Correlation
          <SmallText>Medical Multiple choices questions.</SmallText>
        </h2>

        <CaseCard
          caseId="Vyb83lav"
          caseTitle="Which of the following is not associated with coarctation of the Aorta?"
        />
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "coarctation"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Aortic Regurgitation"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  width: max-content;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .second {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    cursor: pointer;
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
  .expand-icon {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    color: white;
    opacity: 0.5;
    .icon {
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  font-family: futura-pt, sans-serif;
  ul {
  }

  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;

import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
  SmallText
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";

// Data
import { soundData } from "../Static/SoundData";

export default function HCM(props) {
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Patent Ductus Arteriosus</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>continuous, machine-like murmur</li>
          <li>heard best in the left infraclavicular region</li>
          <li>
            murmur is grade 3/6, loudest intensity just before or after S2
          </li>
        </ul>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/PDA-graphic.png"
            alt="Patent Ductus Arteriosus"
            className="first"
          />
        </ImgContainer>
        <p>
          The murmur is grade 3/6 or less and is heard best in the left
          infraclavicular region. The intensity of the murmur is maximal
          immediately before and after the second heart sound (S2) and is not
          affected by position.
        </p>
        <ImgContainer>
          <img
            src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pda-location.jpg"
            alt="Patent Ductus Arteriosus Location"
            className="first thoraxis"
          />
        </ImgContainer>
        <p>
          In the newborn, aortic systolic pressure is greater than pulmonary
          systolic pressure, but this gradient may not be present during
          diastole. As a result, the murmur may be confined to systole.
        </p>
        <p>
          After the newborn period, the pulmonary artery pressure falls after
          the newborn period. As a result, aortic pressure is higher than
          pulmonary artery pressure during both systole and diastole, producing
          continuous flow through the ductus and a characteristic continuous or
          machine-like murmur.
        </p>
      </Intro>
      <P1>
        <div>
          <h2>Pathophysiology and Epidemiology</h2>
          <p>
            The ductus arteriosus (DA) is a fetal vascular connection between
            the main pulmonary artery and the aorta that diverts blood away from
            the pulmonary bed. After birth, the DA undergoes active constriction
            and eventual obliteration. A patent ductus arteriosus (PDA) occurs
            when the DA fails to completely close postnatally.
          </p>

          <ImgContainer>
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pda-anatomy.png"
              alt="Patent Ductus Arteriosus Anatomy"
              className="first"
            />
          </ImgContainer>

          <p>
            The reported incidence of an isolated PDA among term infants ranges
            from 3 to 8 per 10,000 live births. There is a female predominance
            for PDA with a 2:1 female to male ratio in most case series of term
            infants. One of the most important risk factors for PDA is
            prematurity. It has also been seen in high incidence in infants born
            at high altitudes and in infants with congenital rubella.
          </p>
        </div>
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "patent-ductus-arteriosus"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Patent Ductus Arteriosus"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Patent+Ductus+Arteriosus.mp3"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis {
    box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

import React from "react";

// Components
import SoundCard from "./SoundCard";

// Styles
import styled from "styled-components";
import { Title2 } from "../Utilities";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export default function Systolic(props) {
  function handleBack() {
    props.history.goBack();
  }
  return (
    <SoundIndexContainer>
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="back-icon"
        onClick={handleBack}
      />
      <div className="soundcard-container">
        <Title2>Systolic Murmurs</Title2>
        <SoundCard
          key="aortic-stenosis"
          url="aortic-stenosis"
          soundTitle="Aortic Stenosis"
        />
        <SoundCard
          key="atrial-septal-defect"
          url="atrial-septal-defect"
          soundTitle="Atrial Septal Defect"
        />
        <SoundCard
          key="holosystolic-murmur"
          url="holosystolic-murmur"
          soundTitle="Holosystolic Murmur"
        />
        <SoundCard
          key="hypertrophic-cardiomyopathy"
          url="hypertrophic-cardiomyopathy"
          soundTitle="Hypertrophic Cardiomyopathy"
        />
        <SoundCard
          key="innocent-murmur"
          url="innocent-murmur"
          soundTitle="Innocent Murmur"
        />
        <SoundCard
          key="mitral-valve-prolapse"
          url="mitral-valve-prolapse"
          soundTitle="Mitral Valve Prolapse"
        />
      </div>
    </SoundIndexContainer>
  );
}
const SoundIndexContainer = styled.div`
  padding-bottom: 75px;
  .back-icon {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 15px;
    color: white;
    display: none;
  }
  .soundcard-container {
    margin-top: 100px;
  }
  ${Title2} {
    color: white;
    padding: 15px 0;
    font-weight: 700;
    margin: 25px;
  }
  @media (max-width: 768px) {
    .back-icon {
      display: block;
    }
    .soundcard-container {
      margin-top: 0;
    }
    ${Title2} {
      text-align: center;
    }
  }
`;

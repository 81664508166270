import React, { Fragment, useEffect } from "react";

// Components
import Spinner from "../Utilities/Spinner";
import HeaderMobile from "../Header/HeaderMobile";
import SoundCard from "../Sounds/SoundCard";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

// Dependencies
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_SOUNDS = gql`
  query GET_SOUND($search: String!) {
    sounds(search: $search) {
      id
      title
      body
      url
    }
  }
`;

export default function Search(props) {
  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, []);

  const searchWord = props.searchWord;
  return (
    <Fragment>
      <HeaderMobile
        changeSearchWord={props.changeSearchWord}
        appView={"search/home"}
        {...props}
      />
      <SearchTopContainer>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <Query query={GET_SOUNDS} variables={{ search: searchWord }}>
          {({ loading, data }) => {
            if (loading) return <Spinner />;
            const sounds = data.sounds;
            if (sounds.length > 0) {
              return (
                <SoundCardContainer>
                  {sounds.map(sound => {
                    return (
                      <SoundCard
                        key={sound.url}
                        url={sound.url}
                        soundTitle={sound.title}
                      />
                    );
                  })}
                </SoundCardContainer>
              );
            } else {
              return (
                <SearchContainer>
                  <p>
                    No sounds match your search for{" "}
                    <strong>{searchWord}</strong>
                  </p>
                </SearchContainer>
              );
            }
          }}
        </Query>
      </SearchTopContainer>
    </Fragment>
  );
}
const SearchTopContainer = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  @media (max-width: 768px) {
    display: block;
  }
`;
const SoundCardContainer = styled.div`
  padding: 50px 0;
  color: ${grayscale.white};
  font-family: ${fontFamily.sansSerif};
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
const SearchContainer = styled.div`
  margin-top: 50px;
  padding: 50px;
  color: ${grayscale.white};
  font-family: ${fontFamily.sansSerif};
`;
const StyledLink = styled(Link)`
  color: ${grayscale.white};
  text-decoration: none;
`;
const SidebarContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

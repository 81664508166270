import React, { Fragment, useEffect } from "react";

// Dependencies
import { Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";

// components
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";

// Sounds
import NormalHeartSound from "./NormalHeartSound";
import AorticRegurgitation from "./AorticRegurgitation";
import AorticStenosis from "./AorticStenosis";
import ASD from "./ASD";
import Coarctation from "./Coarctation";
import Holosystolic from "./Holosystolic";
import HCM from "../Sounds/HCM";
import PDA from "../Sounds/PDA";
import PericardialFrictionRub from "./PericardialFrictionRub";
import MitralStenosis from "./MitralStenosis";
import MitralValveProlapse from "./MitralValveProlapse";
import PulmonicStenosis from "../Sounds/PulmonicStenosis";
import S3 from "./S3";
import S4 from "./S4";
import Innocent from "./Innocent";
import SplitS1 from "./SplitS1";
import SplitS2 from "./SplitS2";
import Systolic from "./Systolic";
import Diastolic from "./Diastolic";
import ExtraSounds from "./ExtraSounds";

import LungSounds from "./LungSounds";
import NormalLungSounds from "./BreathSounds/NormalLungSounds";
import PleuralRub from "./BreathSounds/PleuralRub";
import Stridor from "./BreathSounds/Stridor";
import Crackles from "./BreathSounds/Crackles";
import WheezingRhonchi from "./BreathSounds/WheezingRhonchi";
import TransmittedVoiceSounds from "./BreathSounds/TransmittedVoiceSounds";
import Egophony from "./BreathSounds/Egophony";
import Bronchophony from "./BreathSounds/Bronchophony";

import BowelSounds from "./BowelSounds/BowelSounds";
import NormalBowelSounds from "./BowelSounds/NormalBowelSounds";
import HypoactiveBowel from "./BowelSounds/HypoactiveBowel";
import HyperactiveBowel from "./BowelSounds/HyperactiveBowel";
import HighPitchBowel from "./BowelSounds/HighPitchBowel";


// Styles
import styled from "styled-components";

export default function Sounds(props) {
  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, [props.location.pathname]);

  const { user, changeSearchWord } = props;
  return (
    <Fragment>
      <SoundContainer>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <Route
          path="/sound/extra-sounds"
          render={props => <ExtraSounds {...props} user={user} />}
        />
        <Route
          path="/sound/systolic-murmurs"
          render={props => <Systolic {...props} user={user} />}
        />
        <Route
          path="/sound/diastolic-murmurs"
          render={props => <Diastolic {...props} user={user} />}
        />
        <Route
          path="/sound/normal-heart-sound"
          render={props => <NormalHeartSound {...props} user={user} />}
        />
        <Route
          path="/sound/aortic-regurgitation"
          render={props => <AorticRegurgitation {...props} user={user} />}
        />
        <Route
          path="/sound/aortic-stenosis"
          render={props => <AorticStenosis {...props} user={user} />}
        />
        <Route
          path="/sound/atrial-septal-defect"
          render={props => <ASD {...props} user={user} />}
        />
        <Route
          path="/sound/coarctation"
          render={props => <Coarctation {...props} user={user} />}
        />
        <Route
          path="/sound/holosystolic-murmur"
          render={props => <Holosystolic {...props} user={user} />}
        />
        <Route
          path="/sound/hypertrophic-cardiomyopathy"
          render={props => <HCM {...props} user={user} />}
        />
        <Route
          path="/sound/innocent-murmur"
          render={props => <Innocent {...props} user={user} />}
        />
        <Route
          path="/sound/mitral-stenosis"
          render={props => <MitralStenosis {...props} user={user} />}
        />
        <Route
          path="/sound/mitral-valve-prolapse"
          render={props => <MitralValveProlapse {...props} user={user} />}
        />
        <Route
          path="/sound/patent-ductus-arteriosus"
          render={props => <PDA {...props} user={user} />}
        />
        <Route
          path="/sound/pericardial-friction-rub"
          render={props => <PericardialFrictionRub {...props} user={user} />}
        />
        <Route
          path="/sound/pulmonic-stenosis"
          render={props => <PulmonicStenosis {...props} user={user} />}
        />
        <Route
          path="/sound/s3"
          render={props => <S3 {...props} user={user} />}
        />
        <Route
          path="/sound/s4"
          render={props => <S4 {...props} user={user} />}
        />
        <Route
          path="/sound/split-s1"
          render={props => <SplitS1 {...props} user={user} />}
        />
        <Route
          path="/sound/split-s2"
          render={props => <SplitS2 {...props} user={user} />}
        />


        <Route
          path="/sound/lung-sounds"
          render={props => <LungSounds {...props} user={user} />}
        />
        <Route
          path="/sound/normal-breath-sounds"
          render={props => <NormalLungSounds {...props} user={user} />}
        />
        <Route
          path="/sound/stridor"
          render={props => <Stridor {...props} user={user} />}
        />
        <Route
          path="/sound/crackles"
          render={props => <Crackles {...props} user={user} />}
        />
        <Route
          path="/sound/wheezing-rhonchi"
          render={props => <WheezingRhonchi {...props} user={user} />}
        />
        <Route
          path="/sound/pleural-rub"
          render={props => <PleuralRub {...props} user={user} />}
        />
        <Route
          path="/sound/transmitted-voice-sounds"
          render={props => <TransmittedVoiceSounds {...props} user={user} />}
        />
        <Route
          path="/sound/egophony"
          render={props => <Egophony {...props} user={user} />}
        />
        <Route
          path="/sound/bronchophony"
          render={props => <Bronchophony {...props} user={user} />}
        />

        <Route
          path="/sound/bowel-sounds"
          render={props => <BowelSounds {...props} user={user} />}
        />
        <Route
          path="/sound/normal-bowel-sounds"
          render={props => <NormalBowelSounds {...props} user={user} />}
        />
        <Route
          path="/sound/hypoactive-bowel-sounds"
          render={props => <HypoactiveBowel {...props} user={user} />}
        />
        <Route
          path="/sound/hyperactive-bowel-sounds"
          render={props => <HyperactiveBowel {...props} user={user} />}
        />
        <Route
          path="/sound/high-pitch-bowel-sounds"
          render={props => <HighPitchBowel {...props} user={user} />}
        />
      </SoundContainer>
    </Fragment>
  );
}
const SoundContainer = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  @media (max-width: 768px) {
    display: block;
  }
`;
const SidebarContainer = styled.div`
  background: #131313;
  @media (max-width: 768px) {
    display: none;
  }
`;

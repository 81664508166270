import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer,
  SmallText
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";
import CaseCard from "./CaseCard";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

// Data
import { soundData } from "../Static/SoundData";

export default function AorticStenosis(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Aortic");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Aortic Stenosis</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>crescendo-decrescendo systolic murmur</li>
          <li>best heard at the base</li>
          <li>radiation to bilateral carotids</li>
        </ul>
        <ImgContainer>
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-graphic.png"
            alt="Aortic Stenosis"
            className="first"
          />
        </ImgContainer>
        <p>
          The murmur of aortic stenosis (AS) occurs when there is blood flow
          across a narrowed aortic valve or narrowed left ventricular outflow.
          It is a harsh, crescendo-decrescendo systolic ejection murmur.
        </p>

        <p>
          It is best heard at the base, along the right sternal border at the
          2nd intercostal space, with radiation to bilateral carotids.
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/aortic-stenosis-location.jpg"
              alt="aortic stenosis"
              className="first thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
      </Intro>
      <P1>
        <div>
          <h2>Causes</h2>
          <p>
            The most common causes of aortic stenosis are due to age-related
            calcification of the aortic valve. However, this can be seen earlier
            in life in younger patients who have bicuspid aortic valves.
          </p>
        </div>
      </P1>
      <P1>
        <div>
          <h2>Clinical Manifestations</h2>
          <p>
            Patients with aortic stenosis will have palpable pulses that are
            weak and delayed in their upstroke. This delay in the palpable pulse
            is referred to as "pulsus parvus et tardus."
          </p>
          <p>
            Later in the disease, or in the cases of severe stenosis, patients
            may report decreased exercise tolerance, shortness of breath, chest
            pain, or even syncope or lightheadedness.
          </p>
        </div>
      </P1>
      <P1>
        <h2>
          Clinical Correlation
          <SmallText>Medical Multiple choices questions.</SmallText>
        </h2>

        <CaseCard
          caseId="YQdJY9bO"
          caseTitle="Progressively Worsening shortness of breath"
        />
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(
      sound => sound.id == "aortic-stenosis"
    );
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Aortic Stenosis"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis.mp3"
        visual="https://vimeo.com/336204967/fba3af79c5"
        video="https://vimeo.com/336204620/3d0bdd3bc8"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  .first {
    max-width: 600px;
    width: 100%;
  }
  .thoraxis-container {
    position: relative;
    width: max-content;
    margin: auto;
    .thoraxis {
      cursor: pointer;
      box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
    .expand-icon {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 15px;
      color: white;
      opacity: 0.5;
      .icon {
        vertical-align: middle;
      }
      span {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .thoraxis-container {
      width: 100%;
    }
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

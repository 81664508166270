import React from "react";

// Dependencies
import { Link } from "react-router-dom";

// Styles
import styled from "styled-components";
import { fontFamily } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export default function StethosHeader(props) {
  const { sessionObject } = props;
  const soundArrayLength = sessionObject.heartSounds.length;
  const { correct, incorrect } = sessionObject;
  return (
    <HeaderContainer>
      <GridContainer>
        <div className="exit">
          <Link to="/quiz/home">
            <FontAwesomeIcon icon={faChevronLeft} size="lg" className="exit-icon"/>
            <span>LEAVE QUIZ</span>
          </Link>
        </div>
        {sessionObject.isSubmitted ? (
          <div className="sound-position">
            Score: {Math.round((correct.length / soundArrayLength) * 100)}%
          </div>
        ) : (
          <div className="sound-position">
            Sound {props.soundPosition + 1} of {props.total}
          </div>
        )}
      </GridContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  color: white;
  background: #111111;
  font-family: ${fontFamily.sansSerif};
  z-index: 1;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;
  max-width: 1000px;
  margin: auto;
  padding: 15px 25px;
  .exit {
    a {
      color: white;
      text-decoration: none;
    }
    .exit-icon {
      margin-right: 8px;
    }
  }
  .sound-position {
    justify-self: end;
  }
  @media (max-width: 1024px) {
    padding: 15px;
  }
  @media (max-width: 768px) {
    .exit {
      margin-right: 8px;
    }
  }
`;

import React from "react";

// Dependencies
import { Link } from "react-router-dom";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeartbeat,
  faChevronRight,
  faLungs,
  faStomach,
} from "@fortawesome/pro-light-svg-icons";

// Styles
import styled from "styled-components";

export default function SoundCard(props) {
  const {
    url,
    soundTitle,
    compare,
    noIcon,
    subTitle,
    isBreath,
    isBowel,
  } = props;
  console.log(noIcon);
  if (noIcon) {
    return (
      <StyledLink to={"/sound/" + url}>
        <SoundCardContainer className="no-icon">
          <div className="label-container">
            <div className="label">{soundTitle}</div>
            {subTitle && <div className="sub-label">{subTitle}</div>}
          </div>
          <div className="icon-block">
            <div className="icon chevron">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        </SoundCardContainer>
      </StyledLink>
    );
  }

  if (!compare) {
    return (
      <StyledLink to={"/sound/" + url}>
        <SoundCardContainer>
          <div className="icon-block black">
            {isBreath ? (
              <div className="icon lung">
                <FontAwesomeIcon icon={faLungs} />
              </div>
            ) : isBowel ? (
              <div className="icon bowel">
                <FontAwesomeIcon icon={faStomach} />
              </div>
            ) : (
              <div className="icon heart">
                <FontAwesomeIcon icon={faHeartbeat} />
              </div>
            )}
          </div>
          <div className="label-container">
            <div className="label">{soundTitle}</div>
          </div>
          <div className="icon-block">
            <div className="icon chevron">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        </SoundCardContainer>
      </StyledLink>
    );
  }

  return (
    <CompareSoundCardContainer>
      <div className="icon-block black">
        <div className="icon heart">
          <FontAwesomeIcon icon={faHeartbeat} />
        </div>
      </div>
      <div className="label">{soundTitle}</div>
    </CompareSoundCardContainer>
  );
}
const SoundCardContainer = styled.div`
  display: grid;
  grid-template-columns: 100px auto auto;
  grid-gap: 25px;
  border-bottom: 1px solid gray;
  &.no-icon {
    grid-template-columns: auto auto;
    .icon-block {
      padding: 30px 0;
      height: inherit;
      .icon {
        &.chevron {
          margin-right: 10px;
        }
      }
    }
  }
  .label-container {
    align-self: center;
  }
  .label {
    align-self: center;
    font-weight: 700;
    font-family: futura-pt, sans-serif;
  }
  .sub-label {
    align-self: center;
    font-weight: 300;
    font-family: futura-pt, sans-serif;
    font-size: 13px;
  }
  .icon-block {
    height: 100px;
    display: grid;
    &.black {
      background: black;
    }
    .icon {
      align-self: center;

      &.heart {
        justify-self: center;
        font-size: 30px;
        color: #d9386b;
      }
      &.lung {
        justify-self: center;
        font-size: 30px;
        color: #295aec;
      }
      &.bowel {
        justify-self: center;
        font-size: 30px;
        color: green;
      }
      &.chevron {
        color: #d9386b;
        justify-self: end;
        margin-right: 25px;
      }
    }
  }
  @media (max-width: 425px) {
    grid-template-columns: 75px auto auto;
    .icon-block {
      height: 75px;
    }
    .label {
      font-size: 13px;
    }
  }
`;
const CompareSoundCardContainer = styled.div`
  display: grid;
  grid-template-columns: 50px auto auto;
  grid-gap: 8px;
  border-bottom: 1px solid gray;
  padding-right: 50px;
  cursor: pointer;
  .label {
    align-self: center;
    font-weight: 300;
    font-family: futura-pt, sans-serif;
  }
  .icon-block {
    height: 60px;
    display: grid;
    &.black {
      background: black;
    }
    .icon {
      align-self: center;
      color: #d9386b;
      &.heart {
        justify-self: center;
        font-size: 20px;
      }
      &.chevron {
        justify-self: end;
        margin-right: 25px;
      }
    }
  }
  @media (max-width: 425px) {
    grid-template-columns: 75px auto auto;
    .icon-block {
      height: 75px;
    }
    .label {
      font-size: 13px;
    }
  }
`;
const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

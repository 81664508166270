import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";

// Data
import { soundData } from "../Static/SoundData";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-regular-svg-icons";

export default function S4(props) {
  function thoraxis() {
    props.history.push("/auscultation-points/Mitral");
  }
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>S4 Heart Sound</h1>
      </TitleBlock>
      <Intro>
        <ul>
          <li>late diastolic heart sound, prior to S1</li>
          <li>low-frequency heart sound</li>
          <li>best heard at cardiac apex</li>
        </ul>
        <ImgContainer>
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/S4-graphic.png"
            alt="s4 heart sound"
            className="first"
          />
        </ImgContainer>
        <p>
          The S4 heart sound (aka an "atrial gallop") is heard late in diastole,
          occurring during the atrial filling phase and coincides with the
          p-wave on an electrocardiogram (atrial systole). It is produced by
          turbulence as blood enters a stiff ventricle.
        </p>
        <p>
          It is heard best over the cardiac apex, with the bell of the
          stethoscope. Additionally, lying the patient in the left lateral
          decubitus position may help accentuate the S4 heart sound. It has the
          cadence of the word Tennessee (ten-ne-ssee).
        </p>
        <ImgContainer>
          <div className="thoraxis-container">
            <img
              src="https://medzcool.s3-us-west-2.amazonaws.com/auscultate/mitral-stenosis-location.jpg"
              alt="cardiac apex"
              className="first thoraxis"
              onClick={thoraxis}
            />
            <div className="expand-icon" onClick={thoraxis}>
              <span>Expand</span>
              <FontAwesomeIcon icon={faExpand} className="icon" />
            </div>
          </div>
        </ImgContainer>
        <p>The S4 heart sound can also be heard along side an S3 heart sound, especially in cases of heart failure. As a results, this finding of an S3 and S4 gallop is typically abnormal.</p>
      </Intro>
      <P1>
        <h2>Causes</h2>
        <p>
          The S4 heart sound is rarely a normal finding, unlike the S3 heart
          sound. It is heard in patients who have stiff, noncompliant
          ventricles. For example, you can hear the S4 heart sound in patients
          who have severe left ventricular hypertrophy, this includes:
        </p>
        <ul>
          <li>long-standing uncontrolled hypertension</li>
          <li>hypertrophic cardiomyopathy</li>
          <li>tachycardia-induced cardiomyopathy, etc.</li>
        </ul>
      </P1>
    </SoundDetailsContainer>
  );
  if (props.stethos) {
    return soundBody;
  }
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(sound => sound.id == "s4");
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="S4 Heart Sound"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/s4-heart-sound.mp3"
        visual="https://vimeo.com/336240228/675cf2d3d4"
        video="https://vimeo.com/336239911/0ddfc6c415"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .first {
    max-width: 600px;
    width: 100%;
    margin-top: 15px;
  }
  .thoraxis-container {
    position: relative;
    width: max-content;
    margin: auto;
    .thoraxis {
      cursor: pointer;
      box-shadow: 2.70948px 2.70948px 10.1605px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
    .expand-icon {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 15px;
      color: white;
      opacity: 0.5;
      .icon {
        vertical-align: middle;
      }
      span {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .thoraxis-container {
      width: 100%;
    }
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

import React, { Fragment, lazy, Suspense, useState, useEffect } from "react";

// Dependencies
import ReactGA from 'react-ga';

// Compnoents
import Spinner from "../Utilities/Spinner";
import Sidebar from "../Sidebar/Sidebar";
import SoundCard from "../Sounds/SoundCard";

// Styles
import styled from "styled-components";
import { Title, MediumText, fontFamily } from "../Utilities/Typography";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

const RibCage = React.lazy(() => import("./RibCage"));

export default function AuscultationPoints(props) {
  useEffect(() => {
    ReactGA.pageview(props.location.pathname);
  }, []);

  useEffect(() => {
    props.handleAuscultationPoints(true);
    return () => {
      props.handleAuscultationPoints(false);
    };
  }, []);

  function handleBack() {
    props.history.goBack();
  }

  const [activePoint, setActivePoint] = useState();
  const [activePointLocation, setActivePointLocation] = useState();
  useEffect(() => {
    const location = props.match.params.location;
    if (location) {
      handleClickPoint(location);
    }

    return () => {
      props.handleAuscultationPoints(false);
    };
  }, []);

  function handleBack() {
    props.history.goBack();
  }

  function handleClickPoint(pointClicked) {
    setActivePoint(pointClicked);
    switch (pointClicked) {
      case "Pulmonic":
        setActivePointLocation("Left Sternal Border, 2nd Intercostal Space");
        break;
      case "Aortic":
        setActivePointLocation("Right Sternal Border, 2nd Intercostal Space");
        break;
      case "Erb":
        setActivePointLocation("Left Sternal Border, 3nd Intercostal Space");
        break;
      case "Mitral":
        setActivePointLocation(
          "Left Mid-Clavicuular line, 5th Intercostal Space"
        );
        break;
      case "Tricuspid":
        setActivePointLocation("Left Sternal Border, 4th Intercostal Space");
        break;
    }
  }

  function renderSoundList() {
    if (activePoint == "Aortic") {
      return (
        <Fragment>
          <SoundCard
            key="aortic-stenosis"
            url="aortic-stenosis"
            soundTitle="Aortic Stenosis"
            noIcon
          />
        </Fragment>
      );
    } else if (activePoint == "Pulmonic") {
      return (
        <Fragment>
          <SoundCard
            key="innocent-murmur"
            url="innocent-murmur"
            soundTitle="Innocent/Physiologic Murmur"
            noIcon
          />
          <SoundCard
            key="pulmonic-stenosis"
            url="pulmonic-stenosis"
            soundTitle="Pulmonic Stenosis"
            noIcon
          />
          <SoundCard
            key="atrial-septal-defect"
            url="atrial-septal-defect"
            soundTitle="Atrial Septal Defect"
            noIcon
          />
          <SoundCard
            key="coarctation"
            url="coarctation"
            soundTitle="Coarctation of the Aorta"
            noIcon
          />
        </Fragment>
      );
    } else if (activePoint == "Erb") {
      return (
        <Fragment>
          <SoundCard
            key="aortic-regurgitation"
            url="aortic-regurgitation"
            soundTitle="Aortic Regurgitation"
            noIcon
          />
          <SoundCard
            key="hypertrophic-cardiomyopathy"
            url="hypertrophic-cardiomyopathy"
            soundTitle="Hypertrophic Cardiomyopathy"
            noIcon
          />
          <SoundCard
            key="innocent-murmur"
            url="innocent-murmur"
            soundTitle="Innocent/Physiologic Murmur"
            noIcon
          />
        </Fragment>
      );
    } else if (activePoint == "Mitral") {
      return (
        <Fragment>
          <SoundCard
            key="mitral-stenosis"
            url="mitral-stenosis"
            soundTitle="Mitral Stenosis"
            noIcon
          />
          <SoundCard
            key="mitral-valve-prolapse"
            url="mitral-valve-prolapse"
            soundTitle="Mitral Valve Prolapse"
            noIcon
          />
          <SoundCard
            key="holosystolic-murmur"
            url="holosystolic-murmur"
            soundTitle="Holosystolic Murmur"
            subTitle="Mitral Regurgitation"
            noIcon
          />
          <SoundCard
            key="hypertrophic-cardiomyopathy"
            url="hypertrophic-cardiomyopathy"
            soundTitle="Hypertrophic Cardiomyopathy"
            noIcon
          />
          <SoundCard
            key="innocent-murmur"
            url="innocent-murmur"
            soundTitle="Innocent/Physiologic Murmur"
            noIcon
          />
          <SoundCard key="s3" url="s3" soundTitle="S3 Heart Sound" noIcon />
          <SoundCard key="s4" url="s4" soundTitle="S4 Heart Sound" noIcon />
        </Fragment>
      );
    } else if (activePoint == "Tricuspid") {
      return (
        <Fragment>
          <SoundCard
            key="holosystolic-murmur"
            url="holosystolic-murmur"
            soundTitle="Holosystolic Murmur"
            subTitle="Tricuspid Regurgitation & Ventricular Septal Defects"
            noIcon
          />
          <SoundCard
            key="hypertrophic-cardiomyopathy"
            url="hypertrophic-cardiomyopathy"
            soundTitle="Hypertrophic Cardiomyopathy"
            noIcon
          />
          <SoundCard
            key="atrial-septal-defect"
            url="atrial-septal-defect"
            soundTitle="Atrial Septal Defect"
            subTitle="wtih large left-to-right shunts"
            noIcon
          />
          <SoundCard
            key="innocent-murmur"
            url="innocent-murmur"
            soundTitle="Innocent/Physiologic Murmur"
            noIcon
          />
          <SoundCard
            key="pericardial-friction-rub"
            url="pericardial-friction-rub"
            soundTitle="Pericardial Friction Rub"
            noIcon
          />
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <AuscultationContainer>
        <div className="back-container" onClick={handleBack}>
          <FontAwesomeIcon icon={faChevronLeft} className="back-icon" />
          <span className="back-text">Back</span>
        </div>
        <div className="ribs mobile">
          <Suspense fallback={<Spinner />}>
            <RibCage
              handleClickPoint={handleClickPoint}
              activePoint={activePoint}
            />
          </Suspense>
        </div>
        <div className="text-display">
          <Title>
            {activePointLocation || "Select an Auscultation Point"}
            <MediumText>
              {activePoint ? "(" + activePoint + " Auscultation Point)" : null}
            </MediumText>
          </Title>

          {!activePoint ? (
            <Fragment>
              <MediumText>
                Click on any of the auscultation points in diagram to see what
                heart sounds you might hear at these points.
              </MediumText>
            </Fragment>
          ) : (
            <div>{renderSoundList()}</div>
          )}
        </div>
        <div className="ribs desktop">
          <Suspense fallback={<Spinner />}>
            <RibCage
              handleClickPoint={handleClickPoint}
              activePoint={activePoint}
            />
          </Suspense>
        </div>
      </AuscultationContainer>
    </Fragment>
  );
}

const AuscultationContainer = styled.div`
  margin: auto;
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  max-width: 1200px;
  padding: 0 15px 75px 15px;
  .text-display {
    color: white;
    font-family: ${fontFamily.sansSerif};
    padding: 15px;
  }
  .back-container {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 15px;
    color: white;
    .back-icon {
      margin-right: 10px;
    }
  }
  .ribs {
    &.mobile {
      display: none;
    }
  }
  @media (max-width: 768px) {
    display: block;
    margin-top: 0;
    margin-bottom: 125px;
    padding: 0;
    .back-container {
      z-index: 3;
    }
    .text-display {
      h2 {
        margin-top: 525px;
      }
    }
    .ribs {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
  }
  @media (max-width: 700px) {
    .text-display {
      h2 {
        margin-top: 475px;
      }
    }
  }
  @media (max-width: 600px) {
    .text-display {
      h2 {
        margin-top: 425px;
      }
    }
  }
  @media (max-width: 500px) {
    .text-display {
      h2 {
        margin-top: 350px;
      }
    }
  }
  @media (max-width: 425px) {
    .back-container {
      .back-text {
        display: none;
      }
    }
    .text-display {
      h2 {
        margin-top: 300px;
      }
    }
  }
  @media (max-width: 375px) {
    .text-display {
      h2 {
        margin-top: 250px;
      }
    }
  }
  @media (max-width: 320px) {
    .text-display {
      h2 {
        margin-top: 225px;
      }
    }
  }
`;

export const development = false;

export let homeUrl;
export let url;

// MIXPANEL
export let mixpanelToken;

if (development === true) {
  url = "http://127.0.0.1:8000";
  homeUrl = "localhost:3000";
  mixpanelToken = "9b04a0c3b7311d2eb446df05641f7b88";
} else {
  url = "https://api.medzcool.com";
  homeUrl = "https://medzcool.com";
  mixpanelToken = "53419f0f6fae3ecd6e4f27a3f34e1d95";
}

import React from "react";

// Styles
import styled from "styled-components";
import { fontFamily } from "./";

export default function Button(props) {
  const { active, width, padding } = props;
  return (
    <StyledButton padding={padding} width={width} active={active}>
      {props.buttonLabel}
    </StyledButton>
  );
}

export const StyledButton = styled.button`
  font-family: "futura-pt, sans-serif",;
  border: none;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  ${props =>
    props.active
      ? `background: #edcd18; color: black;`
      : `background: lightgray; color: white;`}
  ${props => props.background && `background: ${props.background}`}
  ${props => props.color && `color: ${props.color}`}
  ${props => props.width && `width: ${props.width}`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius}`}
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow}`}
  ${props => props.fontWeight && `font-weight: ${props.fontWeight}`}
  ${props => props.fontSize && `font-size: ${props.fontSize}`}
  ${props => props.padding && `padding: ${props.padding}`}
  .text-right {
      margin-left: 8px;
  }
  .text-left {
      margin-right: 8px;
  }
`;

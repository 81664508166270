import React, { useState, useEffect, Fragment } from "react";

// Components
import styled from "styled-components";
import {
  grayscale,
  fontFamily,
  Button,
  SoundDetailsContainer,
  SoundDetailsBlock,
  SoundContainer
} from "../Utilities";
import Spinner from "../Utilities/Spinner";
import MediaPlayerStatic from "../MediaPlayer/MediaPlayerStatic";
import MedzcoolEditor from "../Editor/MedzcoolEditor";
import MedzcoolEditorReadOnly from "../Editor/MedzcoolEditorReadOnly";
import HeaderMobile from "../Header/HeaderMobile";

// Data
import { soundData } from "../Static/SoundData";

export default function Holosystolic(props) {
  const soundBody = (
    <SoundDetailsContainer>
      <TitleBlock>
        <h1>Split S1</h1>
      </TitleBlock>
      <Intro>
        <p>
          The S1 heart sound is heart caused by the simultaneous closure of the
          mitral and tricuspid valves. A split S1 occurs when these valves close
          at slightly different times. Usually, the mitral valve closes first
          (M1) followed by the tricuspid valve (T1).
        </p>
        <ImgContainer>
          <img
            src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-S1-sound.png"
            alt="split s1"
            className="first"
          />
        </ImgContainer>
      </Intro>
      <P1>
        <h2>Causes</h2>
        <p>
          A split S1 occurs in 40-70% of the population. However, it is also
          heard in patients with right bundle branch blocks (RBBB). Patient's
          with RBBBs have a delay in the conduction to the right ventricle
          leading to a delay in the closure of the tricuspid valve. Because of
          this delay, you will hear a "splitting" of the S1 heart sound because
          the tricuspid and mitral valves will close at different times.
        </p>
      </P1>
    </SoundDetailsContainer>
  );

  if (props.stethos) {
    return soundBody;
  }
  const soundId = props.match.params.soundId;
  const user = props.user;

  const [audioArray, setAudioArray] = useState();
  useEffect(() => {
    const jsonSoundData = soundData.data.heartSounds;
    const currentSound = jsonSoundData.filter(sound => sound.id == "split-s1");
    setAudioArray(currentSound[0].audio);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!audioArray) return null;

  return (
    <Fragment>
      <HeaderMobile
        headerTitle="Split S1"
        appView={"soundDetails"}
        {...props}
      />
      <SoundContainer>{soundBody}</SoundContainer>
      <MediaPlayerStatic
        audioArray={audioArray}
        audio="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s1.mp3"
        visual="https://vimeo.com/336284401/cf009f0cfe"
        video="https://vimeo.com/336284283/5b4229f82a"
      />
    </Fragment>
  );
}

const TitleBlock = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Intro = styled(SoundDetailsBlock)`
  max-width: 900px;
`;
const ImgContainer = styled.div`
  text-align: center;
  .first {
    max-width: 600px;
    width: 100%;
    margin-top: 15px;
  }
`;
const P1 = styled(SoundDetailsBlock)`
  max-width: 900px;
  margin: 50px 0px 75px 0;
  img {
    max-width: 300px;
    width: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .img-container {
      text-align: center;
    }
  }
`;
const Bolded = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

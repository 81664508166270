import styled, { css } from "styled-components";

export const fontFamily = {
  sansSerif: "futura-pt, sans-serif",
  serif: "adobe-garamond-pro, serif"
};

export const fontType = {
  pageTitle: css`
    font-size: 28px;
    @media (max-width: 768px) {
      font-size: 22px;
    }
    @media (max-width: 425px) {
      font-size: 18px;
    }
  `,
  small: css`
    font-size: 14px;
    font-weight: 300;
    @media (max-width: 425px) {
      font-size: 12px;
    }
  `
};
export const fontWeight = {
  bold: "700",
  regular: "400",
  light: "300"
};
export const Title = styled.h2`
  font-size: 28px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.bold};
  margin: 0;
  margin-bottom: 25px;
  @media (max-width: 425px) {
    font-size: 22px;
  }
`;
export const Title1 = styled.h1`
  font-family: ${fontFamily.sansSerif};
  font-size: 35px;
  @media (max-width: 1024px) {
    font-size: 26px;
  }
`;
export const Title2 = styled.h2`
  font-family: ${fontFamily.sansSerif};
  font-size: 26px;
  @media (max-width: 1024px) {
    font-size: 20x;
  }
`;
export const AnchorTag = styled.a`
  text-decoration: none;
  ${props => (props.color ? `color: ${props.color}` : `color: #062bba`)}
`;
export const Logo = styled.div`
  font-family: ${fontFamily.sansSerif};
  color: white;
  font-weight: 700
  font-size: 22px;
`;
export const SmallText = styled.div`
  font-size: 12px;
  font-family: ${fontFamily.sansSerif};
  font-weight: 500;
  @media (max-width: 425px) {
    font-size: 10px;
  }
`;
export const MediumText = styled.div`
  font-size: 16px;
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

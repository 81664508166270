import React from "react";

// Components
import SoundCard from "./SoundCard";

// Styles
import styled from "styled-components";
import { Title2 } from "../Utilities";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export default function LungSounds(props) {
  function handleBack() {
    props.history.goBack();
  }
  return (
    <SoundIndexContainer>
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="back-icon"
        onClick={handleBack}
      />
      <div className="soundcard-container">
        <Title2>Lung Sounds</Title2>
        <SoundCard
          key="normal-breath-sounds"
          url="normal-breath-sounds"
          soundTitle="Normal Breath Sounds"
          isBreath
        />
        <SoundCard
          key="transmitted-voice-sounds"
          url="transmitted-voice-sounds"
          soundTitle="Transmitted Voice Sounds"
          isBreath
        />
        <SoundCard key="stridor" url="stridor" soundTitle="Stridor" isBreath />
        <SoundCard
          key="crackles"
          url="crackles"
          soundTitle="Crackles (Rales)"
          isBreath
        />
        <SoundCard
          key="wheezing-rhonchi"
          url="wheezing-rhonchi"
          soundTitle="Wheezing & Rhonchi"
          isBreath
        />
        <SoundCard
          key="pleural-rub"
          url="pleural-rub"
          soundTitle="Pleural Rub"
          isBreath
        />
        <SoundCard
          key="egophony"
          url="egophony"
          soundTitle="Egophony"
          isBreath
        />
        <SoundCard
          key="bronchophony"
          url="bronchophony"
          soundTitle="Bronchophony"
          isBreath
        />
      </div>
    </SoundIndexContainer>
  );
}
const SoundIndexContainer = styled.div`
  padding-bottom: 75px;
  .back-icon {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 15px;
    color: white;
    display: none;
  }
  .soundcard-container {
    margin-top: 100px;
  }
  ${Title2} {
    color: white;
    padding: 15px 0;
    font-weight: 700;
    margin: 25px;
  }
  @media (max-width: 768px) {
    .soundcard-container {
      margin-top: 0;
    }
    .back-icon {
      display: block;
    }
    ${Title2} {
      text-align: center;
    }
  }
`;

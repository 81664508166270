import styled from "styled-components";

export const Button = styled.button`
  background: white;
  border-radius: 3px;
  padding: 15px 35px;
  cursor: pointer;
  ${props => props.margin && `margin:${props.margin}`}
  ${props => props.background && `background:${props.background}`}
`;

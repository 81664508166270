export const jsonResponse = {
  data: {
    isSubmitted: false,
    arrayPosition: 0,
    heartSounds: [
      {
        id: "aortic-regurgitation",
        title: "Aortic Regurgitation",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/aortic-regurg-early-dias.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-regurg-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "left sternal border, at the 3rd intercostal space",
            correctAnswer: true
          },
          {
            id: "right sternal border, at the 2nd right intercostal space"
          },
          {
            id: "left sternal border, at the 2nd intercostal space"
          },
          {
            id: "left sternal border, at the 4th intercostal space"
          },
          {
            id: "left mid-clavicular line, at the 5th intercostal space"
          }
        ]
      },
      {
        id: "aortic-stenosis",
        title: "Aortic Stenosis",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-s2.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "left sternal border, at the third intercostal space"
          },
          {
            id: "right sternal border, at the 2nd right intercostal space",
            correctAnswer: true
          },
          {
            id: "left sternal border, at the 2nd intercostal space"
          },
          {
            id: "left sternal border, at the 4th intercostal space"
          },
          {
            id: "left mid-clavicular line, at the 5th intercostal space"
          }
        ]
      },
      {
        id: "atrial-septal-defect",
        title: "Atrial Septal Defect",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-sys-mur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "left sternal border, at the third intercostal space"
          },
          {
            id: "right sternal border, at the 2nd right intercostal space"
          },
          {
            id: "left sternal border, at the 2nd intercostal space",
            correctAnswer: true
          },
          {
            id: "left sternal border, at the 4th intercostal space"
          },
          {
            id: "left mid-clavicular line, at the 5th intercostal space"
          }
        ]
      },
      {
        id: "innocent-murmur",
        title: "Innocent Murmur",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-short.mp3",
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-murmur-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "left sternal border, at the third intercostal space"
          },
          {
            id: "right sternal border, at the 2nd right intercostal space"
          },
          {
            id: "left sternal border, at the 2nd intercostal space",
            correctAnswer: true
          },
          {
            id: "left mid-clavicular line, at the 5th intercostal space"
          }
        ]
      },
      {
        id: "mitral-stenosis",
        title: "Mitral Stenosis",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/os-diastolic-mumur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mitral-stenosis-murmur-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "left sternal border, at the third intercostal space"
          },
          {
            id: "right sternal border, at the 2nd right intercostal space"
          },
          {
            id: "left sternal border, at the 2nd intercostal space"
          },
          {
            id: "left sternal border, at the 4th intercostal space"
          },
          {
            id: "left mid-clavicular line, at the 5th intercostal space",
            correctAnswer: true
          }
        ]
      },
      {
        id: "mitral-valve-prolapse",
        title: "Mitral Valve Prolapse",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/systolic-click-late-murmur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mvp-murmur-graphic-1.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "left sternal border, at the third intercostal space"
          },
          {
            id: "right sternal border, at the 2nd right intercostal space"
          },
          {
            id: "left sternal border, at the 2nd intercostal space"
          },
          {
            id: "left sternal border, at the 4th intercostal space"
          },
          {
            id: "left mid-clavicular line, at the 5th intercostal space",
            correctAnswer: true
          }
        ]
      },
      {
        id: "pericardial-friction-rub",
        title: "Pericardial Friction Rub",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Pericardial+Friction+Rub+(Leaning+Forward).mp3",
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pericardial-rub-graphic.jpg",
        selectedChoice: null,
        answerChoices: [
          {
            id: "left sternal border, at the third intercostal space"
          },
          {
            id: "right sternal border, at the 2nd right intercostal space"
          },
          {
            id: "left sternal border, at the 2nd intercostal space"
          },
          {
            id: "left sternal border, at the 4th intercostal space",
            correctAnswer: true
          },
          {
            id: "left mid-clavicular line, at the 5th intercostal space"
          }
        ]
      }
    ],
    correct: [],
    incorrect: []
  }
};

export const jsonResponse = {
  data: {
    isSubmitted: false,
    arrayPosition: 0,
    heartSounds: [
      {
        id: "aortic-regurgitation",
        title: "Aortic Regurgitation",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/aortic-regurg-early-dias.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-regurg-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation",
            correctAnswer: true
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "aortic-stenosis",
        title: "Aortic Stenosis",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-s2.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis",
            correctAnswer: true
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub",
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          },
          {
            id: "Pericardial Friction Rub"
          }
        ]
      },
      {
        id: "atrial-septal-defect",
        title: "Atrial Septal Defect",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-sys-mur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect",
            correctAnswer: true
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub",
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "holosystolic-murmur",
        title: "Holosystolic Murmur",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/holosys.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/holosystolic-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur",
            correctAnswer: true
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub",
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "innocent-murmur",
        title: "Innocent Murmur",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-short.mp3",
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-murmur-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur",
            correctAnswer: true
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub",
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "mitral-stenosis",
        title: "Mitral Stenosis",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/os-diastolic-mumur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mitral-stenosis-murmur-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis",
            correctAnswer: true
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub",
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "mitral-valve-prolapse",
        title: "Mitral Valve Prolapse",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/systolic-click-late-murmur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mvp-murmur-graphic-1.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse",
            correctAnswer: true
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "pericardial-friction-rub",
        title: "Pericardial Friction Rub",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Pericardial+Friction+Rub+(Leaning+Forward).mp3",
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pericardial-rub-graphic.jpg",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub",
            correctAnswer: true
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "split-s1",
        title: "Split S1",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s1.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-S1-sound.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1",
            correctAnswer: true
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "split-s2",
        title: "Split S2",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s2-persistent.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s2.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub"
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2",
            correctAnswer: true
          }
        ]
      },
      {
        id: "s3",
        title: "S3 Heart Sound",
        audio: "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/s3.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/S3-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub"
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3",
            correctAnswer: true
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "s4",
        title: "S4 Heart Sound",
        audio: "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/s4.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/S4-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub"
          },
          {
            id: "Normal Heart Sound"
          },
          {
            id: "S3"
          },
          {
            id: "S4",
            correctAnswer: true
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "normal-heart-sound",
        title: "Normal Heart Sound",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-s1-s2-apex.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-sound-graphic-1.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "Aortic Regurgitation"
          },
          {
            id: "Aortic Stenosis"
          },
          {
            id: "Atrial Septal Defect"
          },
          {
            id: "Holosystolic Murmur"
          },
          {
            id: "Innocent Murmur"
          },
          {
            id: "Mitral Stenosis"
          },
          {
            id: "Mitral Valve Prolapse"
          },
          {
            id: "Pericardial Friction Rub"
          },
          {
            id: "Normal Heart Sound",
            correctAnswer: true
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      }
    ],
    correct: [],
    incorrect: []
  }
};
export const quizDescribeResponse = {
  data: {
    isSubmitted: false,
    arrayPosition: 0,
    heartSounds: [
      {
        id: "aortic-regurgitation",
        title: "Aortic Regurgitation",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/aortic-regurg-early-dias.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-regurg-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur",
            correctAnswer: true
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "aortic-stenosis",
        title: "Aortic Stenosis",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-s2.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/aortic-stenosis-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur",
            correctAnswer: true
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "atrial-septal-defect",
        title: "Atrial Septal Defect",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-sys-mur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/asd-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2",
            correctAnswer: true
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "holosystolic-murmur",
        title: "Holosystolic Murmur",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/holosys.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/holosystolic-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur",
            correctAnswer: true
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "innocent-murmur",
        title: "Innocent Murmur",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-short.mp3",
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/innocent-murmur-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur",
            correctAnswer: true
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "mitral-stenosis",
        title: "Mitral Stenosis",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/os-diastolic-mumur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mitral-stenosis-murmur-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap",
            correctAnswer: true
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "mitral-valve-prolapse",
        title: "Mitral Valve Prolapse",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/systolic-click-late-murmur.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/mvp-murmur-graphic-1.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur.",
            correctAnswer: true
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      },
      {
        id: "split-s1",
        title: "Split S1",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s1.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-S1-sound.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1",
            correctAnswer: true
          },
          {
            id: "Split S2"
          },
          {
            id: "Rubbing, Scratchy"
          }
        ]
      },
      {
        id: "split-s2",
        title: "Split S2",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s2-persistent.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/split-s2.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2",
            correctAnswer: true
          }
        ]
      },
      {
        id: "s3",
        title: "S3 Heart Sound",
        audio: "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/s3.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/S3-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3",
            correctAnswer: true
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          },
          {
            id: "Rubbing, Scratchy"
          }
        ]
      },
      {
        id: "s4",
        title: "S4 Heart Sound",
        audio: "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/s4.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/S4-graphic.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "S3"
          },
          {
            id: "S4",
            correctAnswer: true
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          },
          {
            id: "Rubbing, Scratchy"
          }
        ]
      },
      {
        id: "normal-heart-sound",
        title: "Normal Heart Sound",
        audio:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-s1-s2-apex.mp3",
        graphic:
          "https://s3-us-west-2.amazonaws.com/medzcool/auscultate/normal-sound-graphic-1.png",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2",
            correctAnswer: true
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          },
          {
            id: "Rubbing, Scratchy"
          }
        ]
      },
      {
        id: "pericardial-friction-rub",
        title: "Pericardial Friction Rub",
        audio:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/Pericardial+Friction+Rub+(Leaning+Forward).mp3",
        graphic:
          "https://medzcool.s3-us-west-2.amazonaws.com/auscultate/pericardial-rub-graphic.jpg",
        selectedChoice: null,
        answerChoices: [
          {
            id: "early diastolic, decrescendo murmur"
          },
          {
            id: "harsh crescendo-decrescendo, systolic murmur"
          },
          {
            id: "systolic murmur, fixed split S2"
          },
          {
            id: "blowing, pan-systolic murmur"
          },
          {
            id: "soft, rumbling, systolic murmur"
          },
          {
            id: "decrescendo-crescendo, preceded by an opening snap"
          },
          {
            id: "mid-systolic click & late systolic murmur."
          },
          {
            id: "Normal S1 & S2"
          },
          {
            id: "Rubbing, Scratchy",
            correctAnswer: true
          },
          {
            id: "S3"
          },
          {
            id: "S4"
          },
          {
            id: "Split S1"
          },
          {
            id: "Split S2"
          }
        ]
      }
    ],
    correct: [],
    incorrect: []
  }
};
